@import '../../styles/helpers/variables.scss';
@import '../../styles/common.scss';

header.gf-header {
  background: $white;
  height: 50px;
  box-shadow: none;

  > div {
    min-height: 32px;
  }

  &.builder-header {
    position: fixed;
    box-shadow: none;
    z-index: 6;
  }

  .headerBackBtn {
    color: #000;
    padding-top: 2px;
  }

  a,
  #toggleMainMenuBtn {
    &:focus {
      outline: #222 dashed 1px;
      outline-offset: 1px;
      text-decoration: none;
    }
  }

  .cardMobileTitle {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .greenfieldPageTitleHeader {
    font-weight: 400;
    font-size: 24px;
    line-height: 2.75;
    display: flex;
  }
  .greenfield-logo {
    width: 35px;
    margin-right: 20px;
    margin-top: 8px;
    &:focus {
      outline: #222 dashed 1px;
      outline-offset: 1px;
      text-decoration: none;
    }
  }

  .divider-logo {
    margin-right: 15px;
    background-color: white;
  }

  .power-by-greenfield {
    width: 90px;
    padding-left: 10px;
  }

  .site-link {
    &:link,
    &:visited {
      color: $darkGreen;
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
