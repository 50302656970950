.history-popover {
  background-color: rgba(0, 0, 0, 0.5);

  .gf-MuiPaper-elevation8 {
    min-width: 90%;
    min-height: 500px;
  }

  .history-title {
    padding: 10px 0px;
    text-transform: capitalize;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }

  .error {
    text-align: center;
    margin-top: 40px;
    padding-bottom: 400px;
  }
}
