@import '../../../styles/common.scss';

.lockandcompare-popover-form .gf-MuiPaper-root {
  width: 720px;
  max-width: 720px;
}
.lockandcompare-popover-form .ctl-container,
.lockandcompare-popover-form.mobileLockAndCompare {
  width: 700px;
  min-height: 300px;
  max-height: 750px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  .display-flex {
    display: flex;
  }
  .display-flex-space {
    display: flex;
    justify-content: space-between;
    margin: 5px 30px 0 30px;
  }
  .padding-left {
    padding-left: 20px;
  }
  .margin-45 {
    margin-left: 45px;
  }
  .header-container {
    font-size: 20px;
    border-bottom: 1px solid $lightGrayThree;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    position: relative;
    min-height: 30px;
    .title {
      height: 48px;
      font-size: 20px;
      margin-top: 18px;
    }
    .mobileTitle {
      height: 30px;
      font-size: 18px;
      margin-top: 15px;
    }
  }
  .headerTitle {
    margin-top: 6px;
  }
  .icon-size {
    font-size: 16px;
    margin: 6px 0px 0px 2px;
  }
  .lock-radio {
    margin-left: 18px;
  }
  .compare-radio {
    margin-left: 40px;
  }
  .column-list {
    width: 100% !important;
    position: relative;
  }
  .selected-columns-container {
    box-sizing: border-box;
    direction: ltr;
    max-height: 100px;
    position: relative;
    width: 100% !important;
    will-change: transform;
    overflow: auto;
    padding: 10px;
    margin: 10px;
  }
  .columns-header-container {
    border: 1px solid grey;
    padding: 10px;
    margin: 10px 30px 0 30px;
  }
  .columns-list-container {
    border: 1px solid grey;
    border-top: 0px;
    margin: 0 30px 10px 30px;

    .gf-MuiGrid-root.gf-MuiGrid-container.gf-MuiGrid-spacing-xs-3 {
      margin: -12px -12px;

      > div {
        padding: 12px;
      }
    }
  }
  .Column-header-grid {
    height: 43px;
    margin: -12px -12px;

    > div {
      padding: 12px;
    }
  }
  .filter-dimension-label {
    padding: 14px 0px 0px 10px;
  }
  .footer {
    border-top: 1px solid $lightGrayThree;
    padding: 1px 0 0 30px;
    height: 60px;

    .btn-margin {
      margin-bottom: 20px;
    }
  }
  .column-chip {
    margin-top: 8px;
  }
  .default-cursor {
    cursor: default !important;
  }

  // Mobile Styling for this feature
  .onMobile {
    margin: 5px;
    border-top: 1px solid grey;
  }
  .mobile-reset-btn {
    margin-left: 30px;
  }
  .column-option {
    margin: 8px 0 0 8px;
  }
  .mobile-icon {
    font-size: 16px;
  }
  .lock {
    margin-left: 32px;
  }
  .compare {
    margin-left: 54px;
  }
  .block {
    margin-left: 58px;
  }
  .mobile-lock-title {
    margin-left: 22px;
  }
  .mobile-compare-title {
    margin-left: 30px;
  }
}

.lockandcompare-popover-form.mobileLockAndCompare {
  width: auto;
  height: 87%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  .mobileFilterBtnRight {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 50%;
    padding: 28px 0 !important;
    text-align: center;
  }

  .mobileFilterBtnLeft {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 50%;
    border-right: 1px solid $lightGrayFour;
    padding: 28px 0 !important;
    text-align: center;
  }
}
