@import '../../../../styles/helpers/variables.scss';
@import '../../../../styles/common.scss';
@import '../../../../styles/global.scss';

.isPhone .no-data-message {
  margin-top: 50px !important;
}

.mobile-swipeable-views {
  div {
    height: 100%;
  }

  overflow: hidden;
  height: 100%;
}

.time-period-section {
  margin: 8px 20px 0 0;
  display: inline-block;
  cursor: pointer;

  &:focus {
    outline: #222 dashed 1px;
    outline-offset: 1px;
    text-decoration: underline;
  }

  span > svg {
    color: $darkGreen;
    margin-right: 5px;
    vertical-align: middle;
  }

  .time-period-label {
    font-size: 14px;
    text-transform: capitalize;
  }
}

.cardGridHeight,
.bigNumberCardGridHeight {
  flex-grow: 1;
  margin-bottom: 30px;
}
.displayNone {
  display: none;
}

.smartExportLoadingOverlay {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.smartExportActionItems {
  display: block !important;
  width: 100%;
  text-align: center;
}

.smartExportDialogTitle {
  display: flex;
  justify-content: flex-end;
}

.smartExportDebugSection {
  min-height: 100px;
  border-top: 1px dashed;
  text-align: center;
}
.smartExportDebugItem {
  margin: 10px;
}

.smartExportDownloadAnywayBtn {
  font-size: x-small !important;
  margin: 0 !important;
  padding: 0 !important;
  min-height: 20px !important;
  &:hover {
    background-color: transparent !important;
  }
}

.requestLargeExportBtn {
  margin-left: 20px !important;
}

.display-linebreak {
  // allow for \n in strings; react rendering
  white-space: pre-line;
}

.smartExportAlignItems {
  justify-content: flex-start;
}

%mobileFilterBtn {
  position: fixed;
  bottom: 0px;
  height: 9%;
  color: $mediumGray;
}

.support-text-popover {
  padding: 10px;
  border: 1px solid grey;

  div[role='document'] {
    top: 11%;
    left: 70%;
    max-width: 300px;
    max-height: 500px;
  }
}

#cacheErrorExportPromptContainer {
  .greenfieldThemeBtn {
    color: #fff;
    background-color: #81c784;
    margin-left: 15px;
    &:hover {
      color: #81c784;
      background-color: #fff;
      border: 1px solid #81c784;
    }
  }
}

.exportBtn {
  width: auto;
  overflow: hidden;
  font-size: 1rem;
  box-sizing: border-box;
  min-height: 48px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 6px;
  white-space: nowrap;
  letter-spacing: 0.00938em;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
  align-items: center;
  display: flex;
  position: relative;
  justify-content: flex-start;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.08);
  }
}

#gf-root .page-cards {
  .cardviewer-table-container {
    margin: 20px;
    position: relative;
    top: 0;
    left: 0;
    flex-grow: 1;

    &.isMobile {
      margin: 0;
    }

    .table-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      width: 100%;

      > .table-list-card {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        > .table-list-cardContent {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          overflow: hidden;

          > #gfTableId {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow: hidden;

            > .ReactTable {
              height: auto !important;
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              overflow: hidden;

              > .rt-table {
                overflow: hidden;
                > .rt-thead {
                  flex: none;
                  min-width: inherit !important;
                }
                > .rt-tbody {
                  min-width: inherit !important;
                  display: flex;
                  flex-direction: column;
                  flex-grow: 1;
                  overflow: auto !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .drillthrough-container {
    margin-top: 15px;
    margin-bottom: 5px;
    padding-left: 20px;

    > span {
      color: $darkGreen;
      font-weight: bold;
      margin-right: 10px;
      font-size: 16px;
    }

    nav {
      display: inline-block;
    }

    .drillthrough-crumb {
      color: #555;
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        color: #000;
      }

      &.last-crumb {
        color: #999;
        cursor: default;
        text-decoration: none;
      }
    }
  }

  .cardviewer {
    width: 100%;
  }
  .toolbar {
    padding: 2px;
    margin: 2px;
  }

  .mobileFilterBtnRight {
    @extend %mobileFilterBtn;
    right: 0px;
    width: 50%;
  }
  .mobileFilterBtnLeft {
    @extend %mobileFilterBtn;
    left: 0px;
    width: 50%;
    .mobileFilterBtnLeftBadge {
      display: flex;
      align-items: center;
      margin-right: 10px;
      svg {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  .icon-container {
    margin-top: 5px;
  }

  .cardMobileTitle {
    width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .click-more {
    text-transform: lowercase;
  }

  // Card viewer toolbar
  .toolbar-align {
    padding: 5px;

    .lastupdated-container {
      display: inline-block;

      > p {
        font-size: 10px;
      }
    }
  }

  .card-views {
    font-size: x-small;
    margin-top: 16px;
  }

  .business-use {
    margin: 10px;
  }

  .card-dates {
    margin-right: 30px;
  }

  .card-details {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  .business-use-chip {
    margin: 5px;
  }

  .table-container {
    margin: 20px;

    &.printable-table-container {
      display: none;

      &.printable-aggrid-container {
        height: 100% !important;
      }
    }
  }

  .tableVizType,
  .pivotVizType {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
  }

  .tableVizType .table-container,
  .pivotVizType .table-container,
  .tree_tableVizType .table-container {
    flex-grow: 1;
  }

  .thumbnail-table-container {
    margin: 14px;
  }

  .vertical-bar {
    font-size: x-large;
    vertical-align: middle;
    color: $lightGrayFive;
    font-weight: 100;
  }

  .filter-icon {
    margin-right: 5px;
  }

  .hint-badge {
    > span {
      top: 7px;
      right: 10px;
      background-color: #696969;
      color: white;
    }
  }

  .margin-refresh-card {
    margin: 23px 10px 0 0;
  }

  .gran-refresh-margin {
    margin: 44px 10px 0 0;
  }

  .float-right {
    float: right;
  }

  // card styles
  .dashboardExpandCard {
    margin: 10px;
    width: 90px;
    height: 127px;
    text-align: center;
  }

  .dashBoardRowExpand {
    padding: 5px;
    background-color: rgba(204, 204, 204, 0.156862745098039);
  }

  .expandDescription {
    margin-left: 60px;
    margin-right: 20px;
    overflow-wrap: break-word;
  }

  .error-message {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 15px;
  }

  .edit-dashboard-icon {
    font-size: 16px;
  }
  .expandLabel {
    color: $black;
    padding-bottom: 3px;
    padding-top: 20px;
    font-weight: 400;
  }

  .expandValue {
    color: $darkGray;
  }

  .align-action-items {
    position: absolute;
    left: 80%;
  }

  .align-icon {
    vertical-align: middle;
  }

  .red-eye-icon {
    font-size: 16px;
    vertical-align: middle;
    margin-left: 12px;
    margin-top: 16px;
    margin-right: 5px;
  }

  .align-topbar {
    display: flex;
  }

  .card-title {
    margin-top: 10px;
    // font-size: 20px;
  }

  .card-error {
    text-align: center;
  }

  .cardFailMessage {
    text-align: center;

    .notFoundIcon {
      color: $deepRed;
      margin-top: 5%;
      margin-bottom: 5%;
      font-size: 50px;
    }
  }

  .no-data-message {
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
  }

  .card-display-indicator {
    display: inline-block;
  }

  .time-period-request {
    display: inline-block;
  }

  .time-period-section {
    margin: 8px 20px 0 0;
    display: inline-block;
    cursor: pointer;

    &:focus {
      outline: #222 dashed 1px;
      outline-offset: 1px;
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }

    span > svg {
      color: $darkGreen;
      margin-right: 5px;
      vertical-align: middle;
    }

    .time-period-label {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
}

#gf-root.landscape .page-cards .table-container.printable-table-container {
  display: block;
}

.downloadLinkButton:focus {
  outline: none;
}

.dialog-text {
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.5em;
}
.card-spinner {
  z-index: 1200;
  position: absolute;
  left: 50%;
}
.card-viewer-note {
  font-family: 'Roboto';
  font-size: 14px;
  text-align: right;
  margin-right: 18px;
}

.moredetails-popover {
  .details-dashboard-count {
    width: 90px;
    border-radius: 2px;
    box-shadow:
      0px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    padding: 0;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(204, 204, 204, 1);
    margin: 20px auto 10px !important;
    display: flex;
    flex-direction: column;

    > span {
      font-size: 28px;
      text-align: center;
      margin: 5px 0;
    }

    > div {
      font-size: 13px;
      background-color: $lightGreen;
      text-align: center;
      padding: 30px 0;
    }
  }

  footer {
    padding: 5px;
    text-align: right;
  }
}

.lock-compare-icon {
  background-image: url('../../../../images/lock_compare.svg');
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  margin: 5px !important;
}

.lock-compare-icon-mobile {
  background-image: url('../../../../images/lock_compare_mobile.svg');
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
  overflow: hidden;
  font-size: 24px;
  user-select: none;
  flex-shrink: 0;
  margin-left: 4px;
}

.chart-container.hasdrillthrough,
.chart-container-heatmap.hasdrillthrough {
  .amcharts-XYSeries,
  .amcharts-AxisLabel-group,
  .amcharts-Sprite-group.amcharts-Container-group .amcharts-Circle,
  .amcharts-Sprite-group.amcharts-RoundedRectangle-group {
    cursor: pointer;
  }
}

.header-container {
  font-size: 20px;
  height: 48px;
  border-bottom: 1px solid $lightGrayThree;
  padding-left: 20px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 20px;
  }
}

.mobile-text-transform {
  text-transform: none !important;
}

.filters-popover-form {
  .time-period-content {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    vertical-align: unset;
    align-items: center;
    border-bottom: 1px solid $lightGrayThree;
    margin-bottom: 10px;
  }
}

.viewer-time-period-container {
  height: 85%;
  max-height: 85%;
  overflow: auto;
}

.apply-timeperiod-button {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  vertical-align: unset;
  align-items: center;
  margin-top: 5px;
}

.info-icon {
  vertical-align: bottom;
}

.time-period-warning {
  color: #cc0000;
  padding-right: 20px;
  padding-top: 10px;
}

.popover-time-period {
  max-height: 90%;
  height: 90%;
}

.rls-applied {
  display: flex;
  align-items: center;
  font-style: italic;
  font-size: 12px;
  > svg {
    margin: 5px;
  }
}

.okButton {
  justify-content: center !important;
}

#gf-root {
  .chart-container {
    margin: 10px auto 0;
    height: 100% !important;
  }

  .cardviewer-chart-viz-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .cardviewer-chart-viz-container.textVizType {
    height: auto;
    background: #fff;
  }

  .cardviewer-chart-viz-container,
  .cardviewer-container {
    .fr-view {
      text-align: inherit !important;
    }
  }

  .chart-div {
    position: relative;
    float: left;
    width: 80% !important;
  }

  .chart-wrapper {
    width: 100%;
    height: 100%;
  }

  .auto-disabled-view {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .pinned-map-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    .pinned-map-legend-colorby-height {
      height: 300px;
    }
    .pinned-map-legend-container {
      padding-bottom: 20px;
      width: 180px;
    }
  }

  .cardbuilder,
  .cardPreview {
    .chart-container {
      height: 100% !important;
    }
  }
}
.printTitle {
  text-align: center;
}
.chart-height {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.breadcrumb {
  color: #1565c0;
  cursor: pointer;
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;

  &.drillthrough-crumb {
    color: #c75000;
  }
}

.breadcrumb-divider {
  color: #ccc;
}

.aboutyToPrintWidth {
  width: 250px;
}

.normalPageWidth {
  width: 383px;
}

.big-number-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 5px;

  .big-number-column-name {
    margin-top: 25%;
    text-align: center;
  }
  .big-number-value {
    text-align: center;
  }
}

.tree-table-subtitle {
  font-size: 12px;
}

.tree-table-not-found {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #eb0000;
  font-size: 50px;
}

@media print {
  .treeTablePrint {
    height: 100% !important;
  }
}

.pinned-map-has-drillthrough {
  circle {
    cursor: pointer;
  }
}

.filled-map-has-drillthrough {
  g[focusable='true'] {
    cursor: pointer;
  }
}
