@import '../../../styles/helpers/variables';
.margin-container {
  margin-top: 12px;
  margin-right: 5%;
  margin-left: 5%;
  padding: 5%;
  .flex-display {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
  }
  .width-margin {
    width: 40%;
  }
  .owner-css {
    div:first-child > div:first-child > div.chip-container-textfield {
      width: 100%;
    }
  }
}

.margin-datastore {
  margin-top: 12px;
  margin-right: 12px;
  margin-left: 12px;
  margin-bottom: 24px;
  .title-space {
    display: flex;
    justify-content: space-between;
  }
  .position-30 {
    margin-top: 30px;
  }
}

.search-datastore {
  width: 400px;
  background: $white;
}

.dialog-container {
  align-items: center;
  margin: 12px;
  justify-content: space-between;
  .svg-align {
    margin-right: 12px;
  }
  .color-margin {
    align-items: center;
    color: #e30000;
    margin-top: 12px;
  }
}
.display-flex {
  display: flex;
}

.button-color {
  background-color: #f7f7f7;
}

.error-message-datastore {
  display: flex;
  align-items: center;
  margin-top: 12px;
  color: #e30000;
}

.warning-icon {
  font-size: 32px;
  color: $deepRed;
  margin-right: 10px;
}

.datastore-info {
  display: flex;
  flex-wrap: nowrap;
  margin: 12px 6px;
}
