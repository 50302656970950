@import '../../styles/helpers/variables.scss';
@import '../../styles/common.scss';

header.ie-message {
  background-color: $white;
  height: 5%;

  .message-header {
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    color: $darkGray;
  }

  .close-icon {
    color: $darkGray;
    font-weight: bold;
  }
}
