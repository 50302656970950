@import '../styles/helpers/variables';
@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';

*,
*:before,
*:after {
  box-sizing: border-box;
}
#root {
  height: 100%;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
}

@media print {
  #root {
    height: auto;

    .rt-table {
      overflow: hidden;
    }

    .ag-root-wrapper-body.ag-layout-normal {
      height: auto;
    }
  }

  #need_help_btn {
    visibility: hidden;
  }
}

html {
  font-family: 'Roboto', sans-serif;
}

.hide {
  display: none;
}

#gf-root {
  height: 100%;
  flex-direction: column;
  display: flex;
  flex-grow: 1;

  &.embed {
    margin-top: inherit;
  }
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
}
.homePageFavTab {
  padding: 0px 25px;
}

html,
body {
  height: 100%;
}

.gf-body {
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ui-toplevel-error-boundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  text-align: center;
  background-color: #f7f7f7;
  h2 {
    font-size: 1.2rem !important;
  }
  h1 {
    font-size: 2rem !important;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  details {
    color: $mediumGray;
    width: auto;
    cursor: pointer;
    margin-top: 20px;
    overflow: auto;

    p {
      white-space: break-spaces;
    }
    summary {
      text-align: center;
    }
    text-align: left;
  }

  .greenfield-logo {
    width: 150px;
  }

  @media (width <= 600px) {
    h2 {
      font-size: 1rem !important;
    }
    h1 {
      font-size: 1.7rem !important;
    }

    details {
      width: 100%;
    }

    .greenfield-logo {
      width: 100px;
    }
  }
}

.cardviewer-container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.step-connector {
  &.alternative-label {
    top: 22px;
  }
  &.line {
    height: 3px;
    background-color: #c4c4c4;
    border: 0px;
    border-radius: 1px;
  }
  &.active {
    background: #006649;
  }
  &.completed {
    background: #006649;
  }
}

.stepper-button-icon {
  background-color: #c4c4c4;
  z-index: 1;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  &.active {
    background-image: linear-gradient(90deg, #b7f4ae 0%, #2b6f47 100%);
    box-shadow: '0 4px 10px 0 rgba(0,0,0,.25)';
  }
}

.layoutContainer {
  min-height: 200px;
  flex-grow: 1;
  overflow-x: hidden;

  &.builder {
    height: 100%;
    overflow: initial;
  }

  > .mobileDirectChild {
    // override below styles on mobile
    height: auto !important;
    min-height: 95% !important;
  }

  > div {
    flex-grow: 1;

    .cardbuilder-main {
      flex-grow: 1;

      > div {
        height: 100%;
        flex-wrap: nowrap;
      }
    }
  }
}

.downloadLinkButton {
  text-decoration: none;
  color: inherit;
}

.menu-icon-list {
  margin-right: 5px;
}

#gf-root .page-circular-progress {
  display: block;
  margin: 20px auto 0;
}

.dataset-builder-modal-index-0 div[role='dialog'] {
  max-width: 600px !important;
}

// rc table specific

.rc-table-is-dragging {
  background: #eee;
}

.rc-table-is-hovered {
  background: #bbb;
}

.gf-MuiTabs-indicator {
  background: $primaryGreen !important;
  height: 3px !important;
}

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      // Height of grid rows
      row-height: ag-derived(grid-size, $times: 10, $plus: 1),
      // Height of header rows
      header-height: ag-derived(row-height),
      // Fonts
      font-family: ('Roboto', sans-serif),
      font-size: 15px
    )
  );
  .ag-header {
    font-size: 16px;
  }
}
