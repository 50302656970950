@import './commonElements/index';
@import './fireFoxOnly';
@import './helpers/variables';
@import './responsive';
@import './flex';
@import './phone';

.flex-1 {
  display: flex;
  display: -webkit-flex;
  display: -webkit-box;
  box-sizing: border-box;

  -webkit-flex: 1;
  -webkit-box-flex: 1;
  flex: 1;
}

.focusOutline:focus {
  outline: #222 dashed 1px;
  outline-offset: 1px;
  text-decoration: underline;
}

.mobileMessage {
  text-align: center;
  margin-top: 60px;
}

#gf-root {
  .grid-nopadding {
    padding: 0;
  }

  .display-flex-space {
    display: flex;
    justify-content: space-between;
  }
}

.accent-color {
  background: $lightGrayFour;
}

.standard-modal {
  position: absolute;
  top: 40%;
  left: 50%;
  background: $white;
  padding: 30px 30px 10px 30px;
}

.cursorPointer {
  cursor: pointer;
}

// svgicon

.cls-1 {
  fill-rule: evenodd;
}

.cls-2 {
  stroke: $black;
  stroke-width: 1px;
}

.cls-3 {
  fill: #757575;
}

.cls-4 {
  fill: #333;
}

.cls-5 {
  stroke: $black;
  stroke-width: 2px;
  fill-rule: evenodd;
}

.cls-6 {
  fill: #464646;
}

::-webkit-scrollbar-button {
  display: none;
  border-radius: 0px;
  background-color: $primaryGreen;
}
::-webkit-scrollbar-button:hover {
  background-color: $darkGreen;
}
::-webkit-scrollbar-thumb {
  background-color: $primaryGreen;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: $darkGreen;
}
::-webkit-scrollbar-track {
  background-color: $lightGrayOne;
}
::-webkit-scrollbar-track:hover {
  background-color: $lightGrayThree;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.pol-message {
  width: 50%;
  text-align: center;
}

.site-error {
  align-items: center;
  color: $deepRed;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.login-btn {
  &:focus {
    outline: $lightGrayOne dashed 1px !important;
    outline-offset: 2px !important;
  }
}
