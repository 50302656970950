.columns-section {
  &.columns-section-list {
    max-height: 490px;
    overflow-y: scroll;
  }

  &.select-value-height {
    padding: 0;
  }

  &.select-menu-list {
    margin: 0 8px;
  }

  .column-filter-list,
  li {
    margin: 2px 0;
    padding: 0;

    &.select-value-width {
      width: 100%;
    }

    > div,
    .format-chip {
      color: $darkGray;
      font-size: 14px;
      font-family: Roboto;
      border-radius: 1px;
      justify-content: left;
      width: 100%;

      .icon-avatar {
        border-right: 2px solid $white;
        border-radius: 0;
        img {
          width: 80%;
          object-fit: contain;
        }

        &.metric,
        &.aggregation,
        &.postagg {
          background: $mediumPink;
        }

        &.dimension,
        &.dynamic_dimension {
          background: $mediumBlue;
        }

        &.timeseries {
          background: $mediumYellow;
        }
        &.geojson {
          background: $lightOrange;
        }
        &.auto-detected {
          background: $lightOrange;
        }
      }

      > div > svg,
      > span > svg,
      > svg {
        color: $mediumGray;
        font-size: 16px;
        height: 16px;
        width: 16px;
        vertical-align: middle;
      }
    }

    .metric,
    .aggregation,
    .postagg {
      background: $lightPink;
    }

    .dimension,
    .dynamic_dimension {
      background: $lightBlue;
    }

    .timeseries {
      background: $lightYellow;
    }
    .geojson {
      background: $mediumOrange;
    }
    .auto-detected {
      background: $mediumOrange;
    }

    &:hover {
      .metric,
      .aggregation,
      .postagg {
        cursor: pointer;
        background: $mediumPink;
      }

      .dimension,
      .dynamic_dimension {
        cursor: pointer;
        background: $mediumBlue;
      }

      .timeseries {
        cursor: pointer;
        background: $mediumYellow;
      }
      .geojson {
        cursor: pointer;
        background: $mediumOrange;
      }
      .auto-detected {
        cursor: pointer;
        background: $lightOrange;
      }
    }
  }
}
