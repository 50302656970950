@import '../../../styles/helpers/variables';

// Table OR Listing module specific

.ReactTable .rt-td {
  padding: 0px 5px;
}
.search-input {
  margin-bottom: 20px;
  margin-top: 0px;
  width: 300px;
}
.total-records {
  margin-top: 10px;
}
.favorite-icon {
  font-size: 20px;
  color: $favorite;
}
.table-container {
  margin-left: 10px;
}
.table-list-card {
  margin-top: 20px;
}
.table-list-cardContent {
  padding: 0;
}
.table-col-checkbox {
  width: 24px;
  height: 24px;
  margin-top: 4px;
}
.pagination-bottom {
  border-top: 1px solid $lightGrayTwo;
}
.custom-pagination {
  display: flex;
  width: 350px;
  float: right;
}
.custom-pagination-pageSizeOptions {
  margin-top: 16px;
}
.custom-pagination-center {
  margin-top: 16px;
}
