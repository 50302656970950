@import '../../../styles/helpers/variables.scss';

.site-tab {
  .site-info-label {
    margin-left: 20px;
  }
}

.search-site {
  width: 400px;
  background: $white;
}

.chip-business {
  background: $darkBlue;
  color: $white;
  margin-top: 0.2rem;
}

.chip-owners {
  background: $darkPink;
  color: $white;
  margin-top: 0.2rem;
}

.view-details-button {
  text-decoration: none;
  word-break: keep-all;
  white-space: pre-wrap;
  font-size: 1rem;
}
