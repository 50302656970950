@import '../../helpers/variables';

input[type='button'],
button {
  &.icon-label-btn-props {
    .icon-right {
      margin-left: 8px;
    }

    .icon-left {
      margin-right: 8px;
    }

    > svg {
      color: $mediumGray;
      font-size: 16px;
    }
  }

  // Icon button
  &.icon-button {
    .link-icon {
      color: $mediumGray;
    }

    &:hover {
      .link-icon {
        color: $darkGray;
      }
    }

    &:active {
      .link-icon {
        color: $darkGray;
      }
    }
  }
}
