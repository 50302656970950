@import '../../../styles/helpers/variables';

#calculated-fields {
  .section-layout {
    padding: 0 24px 24px 0;
    flex-grow: 1;
    position: relative;
    overflow-x: hidden;
  }

  .box-layout {
    border: 1px solid grey;
    padding: 10px;
    min-height: 80vh;
  }

  .column-list {
    height: 45vh;
    overflow: auto;
  }

  .padding-5 {
    padding: 5px;
  }

  .margin-top-16 {
    margin-top: 16px;
  }

  .error-label {
    color: #b85300;
  }

  .success-label {
    color: #00a200;
  }

  .text-area-label {
    line-height: 0;
    padding-top: 15px;
  }
}

.search-box {
  border-style: solid;
  border-color: #e5e5e5;
  border-width: thin;
  display: flex;
  flex-wrap: nowrap;
}

.calc-field-actual {
  margin-top: 0px;
}

.search-icon {
  background-color: #e5e5e5;
}

.function-list {
  > .select-option {
    > .column-list {
      padding: 0;
      background: $lightGrayOne;

      li {
        div[role='button'] {
          background: $white;
          cursor: pointer;
        }
      }
    }
  }
}

.function-list .min-margin {
  margin-left: 1%;
}

.comp-min-margin-right {
  margin-right: 5%;
  width: 27%;
}

.select-width {
  width: 20%;
}

.padding-top-nil {
  padding-top: 0% !important;
  padding-bottom: 0% !important;
}

.height-param {
  height: 45vh;
}

.rta__autocomplete {
  width: 200px;
}

.rta__list {
  max-height: 30vh;
  width: 30vw;
  height: auto;
  overflow: auto;
  position: absolute;
  z-index: 1;

  .rta__item {
    border-bottom: none;
  }

  .rta__entity--selected {
    background: white;
    color: #222222;
    outline-offset: 3px;
    outline: rgb(136, 136, 136) dashed 1px;
  }

  .column-filter-list {
    margin: 5px 0;
  }
}

.rta {
  width: 100% !important;
}

.rta__textarea {
  min-height: 95px !important;
  width: 100% !important;
}

.drawer-class {
  margin-left: 10px;
}

.width-margin {
  width: 45%;
  margin: 10px 0;
}

.margin-top-right {
  margin-top: 16px !important;
  margin-right: 32px !important;
}

.margin-top {
  margin-top: 0px !important;
}

.tab-padding-top {
  padding-top: 5px;
}

.function-margin-top {
  margin-top: 10px;
  .documentation {
    margin-top: 10px;
    border: 1px solid #222222;
    min-height: 80px;
  }
}

.calculated-field-name-css {
  margin-bottom: 10px !important;
}
