@import '../../../../styles/common.scss';
@import '../../../../styles/helpers/variables.scss';

#gf-root {
  #dashboards {
    .table-list-card {
      padding-bottom: 0;

      > div {
        padding: 0;
      }
    }
    .asset-search {
      display: flex;
    }

    .search-dashboard {
      width: 400px;
      background: $white;
    }
    .edit-dashboard-link {
      text-decoration: none;
      color: $textBlue;
    }
    .edit-dashboard-link:focus {
      outline: #222222 dashed 1px;
      outline-offset: 1px;
    }
    .dashboardExpandCard {
      margin: 10px;
      width: 90px;
      height: 127px;
      text-align: center;
    }
    .dashBoardRowExpand {
      padding: 5px;
      background-color: rgba(204, 204, 204, 0.156862745098039);
    }
    .dashboardExpandCardContents {
      padding: 0;
      background: inherit;
      background-color: rgba(255, 255, 255, 1);
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(204, 204, 204, 1);
      border-radius: 0px;
      font-family: 'Roboto-Regular', 'Roboto';
      color: rgba(33, 33, 33, 0.866666666666667);
    }
    .expandDescription {
      margin-left: 60px;
      margin-right: 20px;
      overflow-wrap: break-word;
    }
    .expandCardData {
      font-size: 28px;
      font-weight: 400;
    }
    .expandCardLabel {
      font-size: 13px;
      font-weight: 400;
      height: 86px;
      padding-top: 20px;
      background-color: $lightGreen;
      color: $black;
    }
    .edit-dashboard-icon {
      // color: rgb(102, 102, 102);
      font-size: 16px;
    }
    .expandLabel {
      padding-bottom: 3px;
      padding-top: 20px;
      font-weight: 400;
      color: $mauve;
      > span {
        color: $black;
      }
    }
    .expandValue {
      color: $darkGray;
    }
    .expandCardBackgroundColor {
      background-color: $lightGrayFive;
    }
    .dashboard-delete-button {
      margin: 20px;
    }
    .expandDivBtn {
      padding-top: 20px;
    }
    .view-details-button {
      text-decoration: none;
      word-break: keep-all;
      white-space: pre-wrap;
      font-size: 1rem;
    }
  }
}
