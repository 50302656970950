@import '../../../../styles/helpers/variables';

.isMobile {
  .homePageDashboard {
    overflow-y: hidden !important;
  }
}

.homePagePhoneBtn {
  bottom: 50px !important;
}

.viewDashboard {
  background-color: $lightGrayOne;
  height: 100%;
  margin-bottom: 40px;

  .viewDashboardMainContainer > header {
    box-shadow: none;
  }

  .hint-badge {
    > span {
      top: 10px;
      right: 10px;
      background-color: #696969;
      color: white;
    }
  }

  .dashboardPreviewCard {
    &.none > div {
      box-shadow: none;
      border: none;
    }

    &.solid > div {
      box-shadow: none;
      border: 1px solid #333;
    }
  }

  .cardPreview {
    > div {
      height: 100%;
      display: flex;
      flex-direction: column;

      .cardviewer-container {
        flex-grow: 1;

        .cardGridHeight {
          height: 100%;
        }

        .react-table-container {
          max-height: initial !important;
        }
      }
    }
  }

  .mobileHeaderWidth,
  .mobileHeader {
    width: 100%;
    overflow: hidden;
  }
  .mobileDashTitle,
  .mobileDashDescrip {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .dashboardMobileTitle {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .topItem {
    margin-top: 19px;
  }

  .viewDashboardTabs > .swipeableViewsContent {
    overflow: initial !important;

    .react-resizable-handle {
      display: none;
    }
  }

  .support-text-popover {
    padding: 10px;
    border: 1px solid grey;

    div[role='document'] {
      top: 13%;
      left: 65%;
      max-width: 300px;
      max-height: 500px;
    }
  }

  .dashboardFailMessage {
    text-align: center;
  }

  .default-card {
    border: 1px solid $lightGrayThree;
  }

  .displayInlineBlock {
    display: block;
  }
  .cursorPointer {
    cursor: pointer;
  }
  .cardPreviewLink {
    text-decoration: none;
    color: $black;
  }
  .hover-button-margin {
    margin-top: 12px;
  }
  .hasCard {
    width: 100%;
  }
  .card {
    margin: 0px !important;
    padding: 5px !important;
  }
  .favIcon,
  .favoriteStatus {
    &:focus {
      outline: none;
    }
  }

  #dashboardMetadataContainer {
    min-height: 50px !important;
  }

  .dashboard-metadata {
    // Dashboard viewer toolbar
    .toolbar-align {
      padding: 0 !important;
    }

    .dashboard-title-fav-view-group {
      width: inherit;
      > div > button#favBtnId {
        padding: 10px 10px 7px;
      }
    }

    .card-views {
      font-size: x-small;
      margin-top: 16px;
    }

    .business-use {
      margin: 10px;
    }

    .card-dates {
      margin-right: 30px;
    }

    .card-details {
      margin-bottom: 10px;
      margin-left: 20px;
    }

    .business-use-chip {
      margin: 5px;
    }

    .table-container {
      margin: 20px;
    }

    .vertical-bar {
      font-size: x-large;
      vertical-align: middle;
      color: $lightGrayFive;
      font-weight: 100;
    }

    .filter-icon {
      margin-right: 5px;
    }

    .align-action-items {
      position: absolute;
      left: 80%;
    }

    .align-icon {
      vertical-align: middle;
    }

    .red-eye-icon {
      font-size: 16px;
      vertical-align: middle;
      margin-left: 12px;
      margin-top: 16px;
      margin-right: 5px;
    }

    .align-topbar {
      display: flex;
    }

    .card-title {
      margin-top: 10px;
      // font-size: 20px;
    }

    .dashBoardRowExpand {
      padding: 5px;
      background-color: rgba(204, 204, 204, 0.156862745098039);
    }

    .expandDescription {
      margin-left: 60px;
      margin-right: 20px;
      overflow-wrap: break-word;
    }

    .expandCardData {
      font-size: 28px;
      font-weight: 400;
    }

    .expandCardLabel {
      font-size: 13px;
      font-weight: 400;
      height: 86px;
      padding-top: 20px;
      background-color: $lightGreen;
      color: $black;
    }
    .edit-dashboard-icon {
      font-size: 16px;
    }
    .disabled-notification {
      pointer-events: none;
    }
    .expandLabel {
      color: $black;
      padding-bottom: 3px;
      padding-top: 20px;
      font-weight: 400;
    }

    .click-more {
      text-transform: lowercase;
    }
  }

  .printable-dashboard,
  .printable-table-container {
    display: none;
  }

  .slide-props {
    border: 1px solid $lightGrayThree;
    box-shadow: none;
    border-radius: 0;
    height: 100%;
    background: #f7f7f7;

    .time-period-content {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      vertical-align: unset;
      align-items: center;
      border-bottom: 1px solid $lightGrayThree;
      margin-bottom: 10px;
    }
  }

  .card-display-indicator {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    .vertical-divider {
      font-size: 35px;
      vertical-align: middle;
      color: $lightGrayFive;
      font-weight: 300;
      margin-right: 10px;
    }
  }

  .time-period-section {
    display: inline-block;
    margin: 8px 20px 0 0;
    cursor: pointer;

    &:focus {
      outline: #222 dashed 1px;
      outline-offset: 1px;
      text-decoration: underline;
    }

    span > svg {
      color: $darkGreen;
      margin-right: 5px;
      vertical-align: middle;
    }

    .time-period-label {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
}

body .moredetails-popover {
  background-color: rgba(0, 0, 0, 0.5);
  .gf-MuiPaper-elevation8 {
    width: 450px;
  }

  .gf-MuiGrid-justify-xs-flex-end {
    justify-content: center;
  }

  .gf-MuiGrid-grid-xs-6 {
    max-width: 100%;
  }
  .more-details-title {
    display: flex;
    flex-direction: row;
    padding: 24px;
  }

  .min-padding-title {
    padding-top: 10px;
  }

  .min-margin-top-comp {
    margin-top: 10px;
  }

  .topItem {
    padding-left: 24px;
  }

  .card-dates {
    padding-left: 24px;
  }
  .business-area {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }

  // card styles
  .dashboardExpandCard {
    margin: 10px;
    width: 90px;
    text-align: center;
  }

  .expandCardBackgroundColor {
    background-color: grey;
  }

  .dashboardExpandCardContents {
    padding: 0;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(204, 204, 204, 1);
    border-radius: 0px;
    font-family: 'Roboto-Regular', 'Roboto';
    color: rgba(33, 33, 33, 0.866666666666667);
  }

  .expandCardData {
    font-size: 28px;
    font-weight: 400px;
  }

  .expandCardLabel {
    font-size: 13px;
    font-weight: 400;
    height: 65px;
    padding-top: 20px;
    background-color: $lightGreen;
    color: $black;
  }
}

.okButton {
  justify-content: center !important;
}

.inline-css {
  display: inline;
}
