// Toolbar custom style

.toolbar {
  background-color: #eee;
  min-height: 48px;
}
.toolbar-title {
  color: $gfGreen;
  font-weight: bold;
  font-size: 18px;
}
.toolbar-btn {
  width: 150px;

  &:hover {
    color: rgba(204, 0, 0, 1);

    .toolbar-btn-text {
      color: rgba(204, 0, 0, 1);
    }
  }
}
.toolbar-btn-text {
  margin: 10px;
}
