@import '../../../styles/helpers/variables';
.filters-popover-form > div[data-test='sentinelStart'] + div,
.filters-popover-form.mobileFilters,
.largeExportInitDialogFilters .largeExportFilterWrapper {
  width: 600px;
  height: 665px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  .mobile-filter-list-container {
    svg {
      font-size: 36px;
    }
    .filter-dimension-label {
      width: calc(100% - 60px);
    }
  }

  .close-icon {
    cursor: pointer;
    padding: 0px;
    padding-right: 5px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
  }

  .header-container {
    font-size: 20px;
    height: 48px;
    border-bottom: 1px solid $lightGrayThree;
    padding-left: 0px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 20px;
      margin-left: 20px;
    }
    .button-padding {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .filter-details {
    margin: 0;
    display: flex;
    height: 545px;
    flex-grow: 1;

    .filter-details-list {
      padding: 0;
      background-color: rgba(204, 204, 204, 0.16);
      border-right: 1px solid $lightGrayThree;
      overflow-y: auto;
      overflow-x: hidden;
      min-width: 220px;
      ul {
        margin: 0;
        padding: 0;
        li {
          padding: 0px 0px 0px 0px;

          button {
            justify-content: space-between;
            min-height: 20px;
            margin: 0;
            text-align: left;
            width: 100%;
            background: inherit;
            border: none;
            color: $mediumGray;

            &:hover {
              background-color: rgba(174, 219, 175, 0.32);
            }

            &.selected-tab {
              border-bottom: 3px solid $primaryGreen;
              color: $black;
              width: fit-content;
            }
          }
        }
      }
    }

    .card-filter {
      height: 400px;
    }

    .filter-details-list-card {
      padding: 0;
      background-color: rgba(204, 204, 204, 0.16);
      border-right: 1px solid $lightGrayThree;
      overflow: auto;
      ul {
        margin: 0;
        padding: 0;
        height: 248px;
        overflow: auto;
        li {
          padding: 0px 0px 0px 0px;

          button {
            justify-content: space-between;
            min-height: 20px;
            margin: 0;
            text-align: left;
            width: 100%;
            background: inherit;
            border: none;
            color: $mediumGray;

            &:hover {
              background-color: rgba(174, 219, 175, 0.32);
            }

            &.selected-tab {
              border-bottom: 3px solid $primaryGreen;
              color: $black;
              width: fit-content;
            }
          }
        }
      }
    }

    .filter-rls {
      display: flex;
      align-items: baseline;
      .text-margin {
        margin-left: 5px;
        margin-right: 5px;
      }
      .show-button {
        height: fit-content;
      }
    }

    .hint-badge {
      > span {
        top: 10px;
        right: -10px;
        background-color: #696969;
        color: white;
      }
    }

    .filter-items-container {
      padding: 10px;
      font-size: 13px;
      width: 70%;
      overflow: auto;

      .section-helper-text {
        font-size: 12px;
        font-style: italic;
        padding-top: 4px;
        padding-right: 10px;
      }

      .field-margin-right {
        width: 45%;
        margin-right: 10px;
      }

      .field-margin-left {
        margin-left: 10px;
      }

      .filter-items {
        margin-top: 2px;
        border: 1px solid $lightGrayFour;
        overflow: auto;
        overflow-y: hidden;
      }

      .filter-condition-select {
        width: 45%;
        font-size: 14px;
      }

      .system-filter-chip {
        max-width: 100%;
        overflow-wrap: break-word;
        height: fit-content;
        > span {
          width: 100%;
          white-space: initial;
          padding-top: 9px;
          padding-bottom: 9px;
          > div {
            overflow: hidden;
          }
        }
      }

      #filter-chip-dimensions-container {
        padding: 10px;
        max-height: 75px;
        min-height: 40px;
        overflow-y: auto;
      }

      #filter-spinner-container {
        height: 165px;
      }

      #filter-save-as-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        line-height: 1;
        padding-right: 10px;
      }

      .mobile {
        height: 200px;
      }
    }

    .add-card-filters {
      margin-right: auto;
      display: flex;
      &.bottom-align {
        position: absolute;
        bottom: 0px;
      }
    }

    .add-card-filters-mobile {
      margin-left: 1px;
      width: 120px;
      &.bottom-align {
        position: absolute;
        bottom: 75px;
      }
    }

    .icon-avatar {
      width: 30px;
      height: 30px;
    }

    .selected-column-width {
      width: fit-content;
    }
  }

  .field-margin {
    margin: 10px 0 0;
    padding-right: 10px;
  }

  .footer {
    border-top: 1px solid $lightGrayThree;
    padding: 1px 0 0 10px;
    height: 40px;
  }
}

.filters-popover-form.mobileFilters {
  width: auto;
  height: calc(100vh - 48px);
  padding: 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .mobileFilterBtnRight {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 50%;
  }

  .mobileMoreCloseBtn {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 50%;
    width: 100%;
  }

  .mobileNoFilterMessage {
    display: content;
    .mobileNoFilter {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90%;
    }
  }

  .mobileFilterBtnLeft {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 50%;
    border-right: 1px solid $lightGrayFour;
  }

  .filter-details-list {
    overflow-x: hidden !important;
  }

  .filter-items-container {
    overflow-y: scroll;
  }
}

.filter-chip-container {
  padding: 0 10px 5px 20px;

  .uneditable-filter-chip {
    background-color: $mediumPink;
  }
}

.filter-dimension-label {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: calc(100% - 48px);
  vertical-align: middle;

  &:focus {
    outline: none;
  }
}

.ReactVirtualized__Grid.ReactVirtualized__List.mobile-filter-list-container {
  height: 100% !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
  word-break: break-word;
  max-width: 310px;
}

.filter-list-container {
  width: 100% !important;
  padding-left: 10px;
}

.snackbar-note {
  background-color: $primaryGreen !important;
  > div:first-child {
    width: 75%;
  }
}
