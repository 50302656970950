@import '../../../styles/helpers/variables.scss';
@import '../../../styles/common.scss';

#gf-root .page-profile {
  .mobile {
    padding: 12px;
    display: inherit;
  }

  .profile-main-pane {
    width: 100%;
    max-width: inherit;
    display: inline-block;
    padding: 2%;
    justify-content: space-between;

    .align-icon {
      vertical-align: middle;
    }

    .name-details-container {
      display: inline-flex;
      float: left;
      padding-right: 1%;

      .name-container {
        display: inline-flex;
        padding: 5%;
        padding-top: 20px;
      }

      .mobile {
        padding: 12px;
        display: inherit;
      }

      .profile-avatar-body {
        background: $darkGray;
        color: $white;
        width: 40px;
        height: 40px;
      }

      #previous-btn {
        padding: 0;
      }

      .profile-person-name {
        padding-left: 10%;
      }
    }

    .profile-person-dates {
      margin-top: 4px;
      display: inline-flex;
      float: right;
      text-align: right;
    }
  }

  .subscription-list-item {
    word-break: break-word;
    white-space: pre-wrap;
  }

  .chip-pane {
    padding-top: 2%;
    align-content: space-evenly;
    vertical-align: middle;
    align-items: center;

    .owned-card {
      display: inline-flex;
      width: 100%;
      margin: 4px;
      justify-content: space-between;
      box-shadow: none;
    }

    .table {
      margin-left: 4px;
      background-color: white;
    }

    .text-typo {
      display: flex;
      flex-wrap: wrap;

      > svg {
        margin-right: 5px;
      }
    }
  }

  .groups-pane {
    padding-top: 2%;
  }
}

@media screen and (max-width: 600px) {
  #gf-root .page-profile .chip-pane {
    .owned-card {
      display: block;
      height: fit-content;
      width: auto;
    }

    .favorite-card {
      display: block;
      height: auto;
      width: auto;
    }
  }
}
