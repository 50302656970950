@import '../../../styles/helpers/variables.scss';
@import '../../../styles/common.scss';

.mobileQuickLinkNextBtn {
  font-size: 50px !important;
  > span {
    font-size: 20px !important;
  }
}

.quicklinks-modal-container {
  width: 600px;

  .disable-on-request {
    pointer-events: none;
    opacity: 0.5;
  }

  .close-icon {
    cursor: pointer;
    padding: 0px;
    color: rgba(0, 0, 10, 0.87);
    font-size: 14px;
  }

  .header-container {
    font-size: 20px;
    height: 48px;
    border-bottom: 1px solid $lightGrayFour;
    padding-left: 20px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 20px;
    }
  }

  .quicklinks-details {
    padding: 20px;
    margin: 0;
    height: 500px;

    .quicklink-items {
      padding: 10px;
      font-size: 12px;
      width: 100%;

      .quicklink-list-item {
        margin: 2px;

        .ql-checkbox-btn {
          padding: 4px;
        }
      }
    }

    #quicklinks-chip-container {
      padding: 10px;
      max-height: 80px;
      overflow-y: auto;
    }

    .no-records {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .quicklink-chip-container {
    padding: 10px 10px 5px 20px;
    .filter-chip {
      background-color: #b2dcff; // todo: what is this?
    }
  }

  .info {
    padding-top: 10px;
    font-size: 15px;
    font-weight: 500;
  }

  .footer {
    padding: 2px 10px;
  }
}

.ql-mobile {
  margin: 10px !important;
}

.mobileFilterBtnLeft,
.mobileFilterBtnRight {
  padding: 0px !important;
  button {
    margin: 0 !important;
    border: 0 !important;
    border-radius: inherit !important;
    background-color: inherit !important;
    width: 100%;
    height: 100%;
  }
}

.mobileQuicklinksChipContainer {
  height: 100px;
  overflow-y: auto;
}
