@import '../../../styles/helpers/variables.scss';
@import '../../../styles/common.scss';

// #gf-root {}
.notificationDialog {
  min-height: 40vh;
  max-height: 90vh;

  .title-notification {
    font-size: 20px;
    height: 48px;
    border-bottom: 1px solid $lightGrayFour;
    padding-left: 20px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 18px;
    }
  }
  .recentEarlierNotificationText {
    font-size: 15px;
  }

  .tabHeaderText {
    font-size: 16px;
    margin: 0 5px 0 0;
  }
}
.tabStyle {
  margin: 0 10px 0 10px;
}
.divStyle {
  margin: 0 10px 0 10px;
  padding: 0 10px 10px 10px;
  .linkStyle {
    color: #5a88d1;
  }
}
.notificationReadtrue {
  background: #f5f5f5;
  border: 1px solid #d6d6d6;
  margin: 0 0 10px 0;
}
.notificationReadfalse {
  background: #e2f2e3;
  border: 1px solid #88c78a;
  margin: 0 0 10px 0;
  cursor: pointer;
}
.popUpModal {
  display: flex;
  flex-direction: row-reverse;
}
