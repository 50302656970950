@import './functions';
/*
*     Colors
*/

$inkBlue: #1565c0;
$paleGreenPrimary: #f4fdf5;
$paleGreenSecondary: #daefdd;
$lightGreen: #aedbaf;
$secondaryGreen: #4dc07d;
$primaryGreen: #006649; // from UX
$darkGreen: #006649; // from UX
$veryDarkGreen: #234b25;
$white: #ffffff;
$whiteGray: #fafafa;
$lightGrayOne: #f7f7f7;
$lightGrayTwo: #f0efef;
$lightGrayThree: #e2e2e2;
$lightGrayFour: #cccccc;
$lightGrayFive: #999999;
$mediumGray: #616161;
$darkGray: #222222;
$black: #000000;
$palePink: #fef9f9;
$lightPink: #fff3f3;
$mediumPink: #fde2e2;
$paleBlue: #f5fafe;
$lightBlue: #dcf0fd;
$mediumBlue: #cae8fb;
$paleYellow: #fdfdf0;
$lightYellow: #fefedd;
$mediumYellow: #fafac3;
$paleViolet: #f2ecfa;
$lightViolet: #e9dcfd;
$deepRed: #eb0000;
$textBlue: #2278cf;
$deepYellow: #c75000;
$mauve: #c32d1b;
$favorite: #f97000;
$lightOrange: #fdddbe;
$mediumOrange: #fcefe1;
$darkPink: #a824a5;
$darkBlue: #383883;
$gfGreen: #006649; // from UX

/*
*   Typography
*/
$greenfieldHeadlineFontSize: 24px;
$greenfieldTitleFontSize: 20px;
$greenfieldSubHeaderFontSize: 16px;
$greenfieldCaptionText: 12px;

/*
*     Placeholder classes
*     http://thesassway.com/intermediate/understanding-placeholder-selectors
*/

%greenfieldHeadline {
  font-size: $greenfieldHeadlineFontSize;
}

%greenfieldTitle {
  font-size: $greenfieldTitleFontSize;
}

%greenfieldSubHeader {
  font-size: $greenfieldSubHeaderFontSize;
}

%greenfieldCaptionText {
  font-size: $greenfieldCaptionText;
}

%link {
  color: opacityConvert($inkBlue, 0.5);
  text-decoration: none;
}

// for use in sx prop in Mui components
:export {
  gfBlackColor: $black;
  gfSecondaryGreenColor: $secondaryGreen;
  gfLightGrayFourColor: $lightGrayFour;
}
