.save-as {
  .api-requested {
    pointer-events: none;
    opacity: 0.5;
  }

  .spinner-center {
    line-height: 35;
    z-index: 1;
  }

  .comp-margin {
    margin: 15px 0;
  }
}
