@import '../../../styles/helpers/variables';

.warning-dialog-content {
  display: flex;
  margin-bottom: 20px;

  h2 {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }
}
.warning-dialog-icon {
  font-size: 30px;
  color: $deepRed !important;
  margin: 2px 20px 0 0;
}
.warning-dialog-button {
  float: left;
  padding-top: 20px;
  margin: 20px;
  color: rgba(0, 102, 255, 0.866666666666667) !important;
}
.greenfieldDialogButton {
  color: #cc0000;
  background-color: $white;
  &:hover {
    color: #cc0000;
    background-color: $lightGrayFour;
  }
  &:active {
    color: #aa060f;
  }
}

.greenfieldDialogButton--disabled {
  background-color: $lightGrayFour !important;
  color: $white !important;
}

.admin-group-details {
  margin-left: 10%;
  margin-bottom: 5%;
}
