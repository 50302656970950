.headerStyle {
  font-size: 12px;
  font-family: 'Roboto';
  vertical-align: center;
  text-align: left;
  border: 0;
}

.greenfieldTableLoader {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  &.-loading {
    opacity: 1 !important;
    z-index: 2 !important;
    pointer-events: all !important;
  }
  > .loadingText {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    -webkit-transform: translateY(-52%);
    transform: translateY(-52%);
    -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}

.headerStyle:focus {
  outline: #222222 dashed 1px;
  outline-offset: 1px;
}
.textCellStyle {
  font-family: 'Roboto';
  font-size: 14px;
  padding-top: 13px;
  padding-bottom: 12px;
  border: 0;
  margin-top: 4px;
}

.textCellStyle,
[role='gridcell'] {
  display: flex;
  align-items: center;
}
.numberCellStyle {
  font-family: 'Roboto';
  font-size: 14px;
  padding-top: 13px;
  padding-bottom: 12px;
  border: 0;
  margin-top: 4px;
  text-align: center;
}
.colCheckoboxStyle {
  width: 24px;
  height: 24px;
}
.marginStyle {
  margin-top: 10px;
}

.ReactTable.header-wrap .rt-thead .rt-th > div {
  white-space: initial;
  text-overflow: initial;
}

.ReactTable.body-wrap .rt-tbody .rt-td {
  white-space: initial;
  text-overflow: initial;
}

.ReactTable.colHead-wrap .rt-thead .rt-th > div {
  white-space: initial;
  text-overflow: initial;
}

#gf-root .ReactTable .rt-tbody {
  overflow-x: hidden;

  .rt-tr-group {
    min-height: 38px;
  }
}
.-btn:focus {
  outline: #222 dashed 1px !important;
  outline-offset: 1px !important;
  text-decoration: 1px !important;
}

.pivot-has-drillthrough .rt-table {
  cursor: pointer;
}
