@import '../../../styles/helpers/variables.scss';
@import '../../../styles/common.scss';

.createDivContainer {
  width: 60%;
  border: 1px $black dashed;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  background: $white 0% 0% no-repeat padding-box;
  border: 1px solid $whiteGray;
  border-radius: 4px;
  opacity: 1;

  h3 {
    margin: 10px;
    text-align: center;
  }
}
.previewDivContainer {
  width: 40%;
  border: 1px solid $whiteGray;
  background: $white 0% 0% no-repeat padding-box;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 0px;
}
.createNotificationContainer {
  display: flex;
  flex-direction: row;
}
.tableDetail {
  // display: flex;
  // justify-content: flex-end;
  align-items: baseline;
}
elementDiv {
  // width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 20px;

  &.froala-container > div {
    flex-grow: 1;
  }
}
.elementDivHidden {
  display: none;
}
.formBox {
  border: 1px solid $whiteGray;
}
.previewBox {
  border: 1px solid $whiteGray;
}
.formField {
  width: 20%;
}
.element-field {
  padding: 0 10px 8px;
}
.calendar-section {
  display: flex;

  p {
    padding-right: 20px;
    line-height: 3.5;
  }
}
.formTextField {
  width: 60%;
}
.defaultDiv {
  margin: 0px 20px 0px 20px;
}
.tableclass {
  .publishtrue {
    color: $primaryGreen;
    // opacity: 1;
  }
  .publishfalse {
    background-color: $mediumBlue;
    // opacity: 1;
  }
}
.notificationExpandedtrue {
  background: $lightGrayOne;
  border: 1px solid $lightGrayThree;
  margin: 0 0 10px 0;
  .linkStyles {
    color: $textBlue;
  }
}
.notificationExpandedfalse {
  background: $paleGreenSecondary;
  border: 1px solid $primaryGreen;
  margin: 0 0 10px 0;
  cursor: pointer;
  .linkStyles {
    color: $textBlue;
  }
}
.notificationItem {
  width: 90%;
  margin: 30px 10px 10px 50px;
}
.returnToNotificationManager {
  color: $inkBlue;
}
