@import '../../../styles/helpers/variables.scss';
.availability-css {
  margin-left: 200px;
}
.component-row {
  padding-left: 20px;
}

.width-150 {
  width: 130px;
}

.topo-padding {
  padding-left: 20px;
  padding-top: 5px;

  &.granularity-picker > div {
    background: #fff;
    padding-left: 5px;
  }
}

.font-bold {
  font-weight: 500 !important;
}

.isDesktop .mobileTimePeriodContainerHook {
  padding-left: 20px;
}

.light-divider {
  background-color: $lightGrayFour !important;
  height: 1px !important;

  &.field-margin {
    margin: 10px 0;
  }
}

.viewer-time-period-label {
  padding-left: 20px;
}

.viewer-time-period {
  .align-selection {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
  }

  .switch-base {
    height: 20px;
  }

  .full-width {
    width: 50%;
  }

  .relative-item {
    width: 30%;
    > div {
      width: 75%;
    }
  }

  .relative-item-shift {
    width: 25%;
    > div {
      width: 65%;
    }
  }

  .compare-row {
    padding-right: 10px;
    padding-top: 10px;

    > div {
      display: flex;
    }
  }

  .compare-error {
    line-height: 3;
    margin-left: 30px;
    color: $deepRed;
  }

  .number-period {
    top: 16px;
  }

  .relative-operation {
    .relative-add-icon {
      top: 20px;
    }
  }

  .remove-add-icon {
    padding: 4px;
    height: 30px;
    width: 30px;
    top: 8px;

    > span svg {
      font-size: 20px;
    }
  }
  .display-flex {
    display: flex;
  }
}
