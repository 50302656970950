@import '../../../styles/helpers/variables';
@import '../../../styles/common';

.dataset-delete-popover-form {
  .warning-card {
    padding: 0;
  }

  .warning-icon {
    font-size: 32px;
    color: $deepRed;
    margin: 12px 6px;
  }
  .warning-content {
    margin: 12px 6px;
  }

  .close-icon {
    cursor: pointer;
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
  }

  .header-container {
    font-size: 18px;
    height: 48px;
    border-bottom: 1px solid $lightGrayFour;
    padding-left: 20px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 20px;
    }
  }
  .footer {
    text-align: center;
    border-top: $lightGrayThree 1px solid;
    display: flex;
    justify-content: flex-end;
  }

  .footer > * {
    padding: 10px;
  }

  .dataset-delete-warning {
    margin: 12px 6px;
  }

  .content-container {
    margin: 20px;
  }
  .dataset-info-container {
    margin: 12px 6px;
    font-size: 14px;
  }
  .dataset-info {
    display: flex;
    flex-wrap: nowrap;
    margin: 12px 6px;
  }
  .dataset-info-label {
    margin-right: 4px;
  }
  .dataset-info-value {
    margin-right: 20px;
  }
  .calc-field-del {
    height: 68%;
  }
}
#gf-root {
  .fullwidthEdit {
    width: 100%;
  }
  .halfWidthEdit {
    width: 50%;
  }

  .fullwidthEdit {
    width: 100%;
  }

  .failMessage {
    text-align: center;
  }
  .notFoundIcon {
    color: $deepRed;
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 50px;
  }

  div#datasets {
    margin: 20px;
    padding: 0;

    #datasetlist-container {
      align-items: center;
    }

    .search-dataset {
      width: 400px;
      background: $white;
    }
    .datasetExpandCard {
      margin: 10px;
      width: 120px;
      height: 127px;
      text-align: center;
    }
    .datasetRowExpand {
      padding: 5px;
      background-color: rgba(204, 204, 204, 0.156862745098039);
    }
    .datasetExpandCardContents {
      padding: 0;
      background: inherit;
      background-color: rgba(255, 255, 255, 1);
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(204, 204, 204, 1);
      border-radius: 0px;
      box-shadow: none;
      font-family: 'Roboto-Regular', 'Roboto';
      color: rgba(33, 33, 33, 0.866666666666667);
    }
    .expandDescription {
      margin-left: 60px;
      margin-right: 20px;
      overflow-wrap: break-word;
    }
    .expandCardData {
      font-size: 28px;
      font-weight: 400;
    }
    .expandCardLabel {
      font-size: 13px;
      font-weight: 400;
      height: 86px;
      padding-top: 20px;
      background-color: $secondaryGreen;
      color: $black;
    }
    .edit-dataset-link {
      text-decoration: none;
      color: $textBlue;
    }
    .disabled-notification {
      pointer-events: none;
    }
    .edit-dataset-link:focus {
      outline: #222222 dashed 1px;
      outline-offset: 1px;
    }
    .edit-dataset-icon {
      font-size: 16px;
    }
    .dataset-security-icon {
      font-size: 16px;
      color: $darkGray;
      margin-right: 0.2rem;
    }
    .expandLabel {
      padding-bottom: 3px;
      padding-top: 20px;
      font-weight: 400;
      color: $mauve;
      > span {
        color: $black;
      }
    }
    .expandValue {
      color: $darkGray;
    }
    .expandCardBackgroundColor {
      background-color: $lightGrayFive;
    }

    .expandDivBtn {
      padding-top: 20px;
    }
  }

  .dataset-certified-icon {
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }

  .certified-margin-bottom {
    margin-bottom: 5px;
  }

  // Preview Datasets
  .padding-0 {
    padding: 0;
  }

  .preview-title {
    text-decoration: none !important;
    color: rgba(0, 102, 255, 0.866666666666667);
    font-weight: bold;
    font-size: 16px;
  }
  .info-container {
    padding: 12px;
  }
  .label-text {
    font-weight: 500;
    padding: 8px 0 8px 0;
    flex: none;
    word-break: break-all;
  }

  .value-text {
    padding: 8px 24px 8px 0;
    word-break: break-word;
  }

  .dataset-preview-box {
    margin: 1rem;
  }
  .tab-container {
    padding-top: 8px;
  }

  .dataset-columns {
    height: 100%;
    // border: 1px solid lightgray;
    overflow: hidden;
  }
  .tab-card {
    margin: 24px 40px;
    height: 350px;
    width: 90%;
  }
  .tab-dashboard-card {
    margin: 24px 40px;
    height: 350px;
    width: 90%;
  }
  .cards-list-div {
    max-height: 300px;
    padding: 10px 2px;
  }
  .cards-list {
    max-height: 275px;
    position: relative;
    overflow: auto;
  }
  .cards-listItem {
    padding: 2px 0 6px 0;
  }
  .listItem {
    flex: 1 1 auto;
    padding: 0;
    min-width: 0;
  }
  .listItem-primary-text {
    margin: 0;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.5em;
  }
  .listItem-secondary-text {
    margin: 0;
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.46429em;
    color: rgba(0, 0, 0, 0.54);
  }
  .link-text {
    text-decoration: none;
    color: rgba(0, 102, 255, 0.866666666666667);
  }
  .search-calculated-field {
    width: 350px;
  }
  .total-records {
    padding-bottom: 8px;
  }
  .button-align {
    text-align: right;
  }

  .calc-field-tab {
    height: 100%;
    overflow: hidden;

    > div {
      width: 100%;
      margin: 0;
    }
  }
  .cfTable {
    height: 400px;

    &.upload-table {
      .upload-actions {
        display: flex;
        justify-content: center;

        > svg {
          cursor: pointer;
        }
      }
    }
  }
  .del-cf-icon {
    font-size: 16px;
  }
  .table-list-card {
    padding-bottom: 10px;
  }

  .api-requested {
    pointer-events: none;
    opacity: 0.5;
  }

  .spinner-center {
    line-height: 30;
    z-index: 1;
  }

  .add-refresh-icon {
    background-image: url('../../../images/add_refresh.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .businessarea-list-container {
    position: relative;

    .businessarea-list {
      top: 5px;
      left: 0px;
      background: $white;
      width: 100%;
      box-shadow: 0px 2px 2px 1px #ddd;
      border: 1px solid #ddd;
      max-height: 220px;
      overflow-y: scroll;
      z-index: 1;

      h3 {
        font-size: 14px;
      }

      &.viewers {
        max-height: 190px;
      }
    }

    .owner-list-item {
      width: 100%;
      display: inline-flex;
      align-items: center;
    }
  }

  .create-tag {
    font-weight: 500;
  }
}
.support-text-popover {
  padding: 10px;
  border: 1px solid grey;

  div[role='document'] {
    max-width: 300px;
    max-height: 500px;
  }
}
.formatColumns {
  display: flex;
  margin-right: 20px;
  padding-top: 6px;
  justify-content: flex-end;
}

.header-container {
  font-size: 20px;
  height: 48px;
  border-bottom: 1px solid $lightGrayFour;
  padding-left: 20px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 20px;
  }
}

.format-col-buttons {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 1%;
  padding-top: 1%;
}

button.fmt-save-btn,
.search-field {
  margin-left: 4%;
}

.dir-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 20px;
  margin-left: 20px;
}

.component-min-margin {
  margin-right: 10%;
}

.format-columns-popover {
  height: 200px;
}

.col-format-margin {
  margin-top: 5px !important;

  .icon-label-btn-props {
    margin-left: 40px;
    margin-bottom: 10px;
  }
}

.icon-label-btn-props {
  background: $lightGrayOne;
}

.text-field {
  width: 400px;
}

.formatColsTable.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px #b2b2b2;
}

.margin-above {
  margin-top: 6px;
}

.refresh-dataset {
  .gf-MuiDialog-container {
    > div {
      width: 60%;
      max-width: 60%;
      height: 52vh;
    }
  }

  .dialog-title {
    flex: auto;
    padding-left: 0;
  }

  .refresh-dataset-content {
    padding: 24px;

    .field-label {
      padding: 10px 0;
    }

    .field-dataset-label {
      margin-top: 16px;
    }

    .field-dataset-element {
      margin-top: 5px;
    }
  }

  .action-buttons {
    padding: 24px;
    position: relative;
    top: 45px;

    .button-spacing {
      margin-right: 30px;
    }
  }

  .spinner-layout {
    position: absolute;
    margin-left: 50%;
  }

  .dataset-loader {
    position: absolute;
    margin-left: 30%;
  }

  .error-label {
    color: #b85300;
  }
}

.hide-btn-size {
  font-size: small !important;
  margin-right: 0.5em;
}

.icon-text-dir {
  display: flex;
  flex-direction: row;
}

.format-column-popover-form [role='document'] {
  width: 550px;
  height: 570px;

  .react-swipeable-view-container {
    height: 460px;
  }
}

.security-ctl-btn {
  margin-top: 6px !important;
}

.security-ctl-popover .ctl-container {
  width: 550px;
  max-height: 90vh;
  height: auto;
  margin-top: 30px;

  .flex-cols {
    display: flex;
    flex-direction: column;
  }

  .flex-rows,
  .map-column-label {
    display: flex;
    flex-direction: row;
  }

  .adv-options-content {
    justify-content: space-evenly;
    margin: 5px 5px 25px 5px;
  }

  .align-controls {
    margin: 40px;
  }

  .sec-cntrl-label {
    margin-bottom: 10px;
  }

  .add-new-container {
    display: block;
    margin: 20px;
    padding-top: 10px;
  }

  .add-new-ctrl-btn {
    margin-top: 15px;
  }

  .bottom-options-container {
    padding: 10px;
    border-top: 1px solid #cccccc;
  }

  .adv-options {
    float: left;
  }

  .save-can-buttons {
    float: right;
  }

  .collapse-section {
    margin: 20px 10px 0px 15px !important;
  }

  .sec-cntrl-text {
    padding-left: 20px;
  }

  .security-ctl-apply {
    margin: 15px 0px 0px 15px;
    display: flex;
    justify-content: flex-start;
  }

  .chip-style {
    background-color: #f2f2f2;
    border-bottom: 1px solid #b2b2b2;
    padding: 0px;
  }

  .advanced-opt-btn {
    margin-top: 10px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: center !important;
  }

  .advanced-text {
    display: flex;
    justify-content: center !important;
  }

  .exemption-outline {
    font-size: 14px;
  }

  .usertypes-exemptions-select {
    width: 175px;
  }

  .map-col-select {
    display: flex;
    margin-top: 10px;
    justify-content: flex-start;
  }

  .map-column-label {
    width: 80px;
    margin-left: 20px;
    margin-top: 10px;
  }

  .col-name-form {
    margin-left: 60px;
    width: 175px;
  }
}

.dataset-add-business-area input[type='text']::-ms-clear,
.owner-autocomplete input[type='text']::-ms-clear,
.viewer-autocomplete input[type='text']::-ms-clear {
  display: none;
}

.add-column-level-security {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.width-40 {
  width: 40%;
}

.margin-50 {
  margin-bottom: 50px;
}

.error-message-column {
  color: #e30000;
  margin-left: 10px !important;
}

.column-suggestion {
  overflow-x: hidden;
  overflow-y: auto;
  height: 250px;
}
