@import '../../../styles/helpers/variables.scss';
@import '../../../styles/common.scss';

#gf-root .page-admin {
  button {
    text-transform: inherit;
  }

  .accessibleOutline:focus {
    outline: #222 dashed 1px;
    outline-offset: 1px;
    text-decoration: underline;
  }
  .quicklinkDeleteIcon {
    background-color: transparent;
  }

  .dashboards-container {
    padding: 10px;
    min-height: 500px;
    margin-bottom: 40px;
  }

  .detail-btn-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .member-label-message {
    width: 60%;
  }

  .admin-detail-table {
    td:first-child {
      max-width: 170px;
    }
  }

  .align-title {
    display: flex;
  }

  .admin-member-link {
    text-decoration: none;
    color: $textBlue;
  }

  .external-group-checkbox {
    font-size: small;
    margin-left: 10px;
  }

  .admin-left-pane {
    max-width: 25%;

    .sidebartext-container {
      display: flex;
      justify-content: space-between;
      padding-right: 10px;

      .material-icons {
        cursor: pointer;
      }
    }

    .search-people,
    .showing-people {
      margin-left: 20px;
    }

    .search-people {
      margin-top: 5px;
      width: 90%;

      + div {
        .admin-list-results {
          margin-bottom: 20px;
        }

        .pagination {
          position: fixed;
          bottom: -20px;
          background: $lightGrayThree;
          width: 100%;
          padding: 10px 0;
        }
      }
    }

    .showing-people {
      margin-bottom: 10px;
    }

    .selected-user {
      background: #bfdad8; // todo: what is this?
    }

    ul {
      overflow-y: auto;
    }
  }

  .admin-avatar-list {
    background: $mediumGray;
    margin-right: 15px;
    color: $white;
    width: 40px;
    height: 40px;
  }

  .isaddinggroup-container {
    padding: 25px;

    .isadding-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .isadding-header-textfield {
      margin-top: 16px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 650px;
    }

    .addground-button-container {
      line-height: 5;
    }
  }

  .grp-owner {
    flex-direction: column;
    max-width: 550px;
  }

  .admin-main-pane {
    flex-grow: 1;
    max-width: 75%;

    .name-details-container {
      display: flex;
      justify-content: space-between;
      padding: 30px;

      .name-container {
        display: flex;

        .admin-selected {
          margin-left: 10px;
        }
      }
    }
    .mobile {
      padding: 12px;
      display: inherit;
    }
    .padding {
      padding-bottom: 12px;
    }

    .selected-add-chip-container {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
    }

    .groups-container {
      .groups-header-container > span {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
      }
    }

    .admin-avatar-body {
      background: $darkGray;
      color: $white;
      width: 40px;
      height: 40px;
    }

    .members-container {
      padding: 10px;
      table {
        table-layout: auto;
      }
    }

    .grouplist-container {
      position: relative;

      .grouplist {
        position: absolute;
        top: -55px;
        left: 180px;
        background: $white;
        width: 210px;
        box-shadow: 0px 2px 2px 1px #ddd;
        border: 1px solid #ddd;
        max-height: 220px;
        overflow-y: scroll;

        h3 {
          font-size: 14px;
        }
      }
    }
  }
}

#gf-root .isMobile .page-admin {
  .admin-left-pane {
    width: 100%;
    max-width: inherit;
    flex-basis: inherit;

    > ul {
      max-height: 240px;
    }
  }

  .admin-main-pane {
    width: 100%;
    max-width: inherit;

    .admin-detail-swipe {
      overflow: visible;
    }

    .mbl-list-div {
      padding: 10px 2px;
      height: 500px;
    }
    .mbl-list {
      position: relative;
      overflow: auto;
    }
    .mbl-listItem {
      padding: 4px 0 12px 0;
    }
    .item-name-text {
      margin: 0;
      display: block;
      font-size: 1rem;
      font-weight: 400;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      line-height: 1.5em;
      padding-left: 4px;
    }
    .item-owners-text {
      margin: 0;
      display: block;
      font-size: 0.875rem;
      font-weight: 400;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      line-height: 1.46429em;
      color: rgba(0, 0, 0, 0.54);
      padding-left: 4px;
    }
    .link-text {
      text-decoration: none;
      color: rgba(0, 102, 255, 0.866666666666667);
    }
    .flex {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    #previous-btn {
      padding: 0;
    }
    .mbl-edit-grp-btns {
      padding-bottom: 10px;
      display: flex;
      justify-content: flex-end;
    }
    .admin-person-dates {
      margin-top: 4px;
    }
  }
  .align-icon {
    vertical-align: middle;
  }
  .admin-mbl-btn-div {
    height: 38px;
    position: relative;
    bottom: 0% !important;
  }
  .admin-mbl-btn-left {
    @extend %mobileFilterBtn;
    left: 0px;
    width: 50%;
  }
  .admin-mbl-btn-right {
    @extend %mobileFilterBtn;
    right: 0px;
    width: 50%;
  }
  .label-text {
    margin: 4px 10px 0 0;
    width: fit-content;
  }
  .grp-owner-mbl {
    display: flex;
  }
  .admin-mobile-view {
    display: none;
  }
  .mobileBtnCenter {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    left: 0;
    @extend %mobileFilterBtn;
  }
  .mobileFilterBtnLeft,
  .mobileFilterBtnRight,
  .mobileBtnCenter {
    padding: 0px;
    button {
      margin: 0 !important;
      border: 0 !important;
      border-radius: inherit !important;
      background-color: inherit !important;
      width: 100%;
      height: 100%;
    }
  }
}

.button-end {
  display: flex;
  justify-content: flex-end;
}

.field-margin {
  margin: 10px 0;
}

.pagination {
  list-style: none;
  text-align: end;
}

.pagination li {
  display: inline-block;
  margin: 0 20px 0 0;
}

.pagination li.active {
  font-weight: bold;
}

.pagination li a {
  cursor: pointer;
}

.edit-group-icon {
  padding-left: 5px;
  cursor: pointer;
}

.chip-container-textfield {
  div {
    margin-bottom: 4px;
    flex-wrap: wrap;
  }
}

.disable-on-request {
  pointer-events: none;
  opacity: 0.5;
}
