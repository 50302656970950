@import '../../../styles/helpers/variables.scss';
@import '../../../styles/common.scss';

.timePeriod {
  .mobilePickerHeight {
    max-height: 350px;
  }

  .textFieldContainer {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
  }

  .tab-minwidth {
    min-width: 50px;
  }

  .indicator-color {
    background-color: $white !important;
    height: 0 !important;
  }

  .popContainer {
    display: flex;
    justify-content: left;
    align-items: center;
    .innerPopContainer {
      margin-left: 20px;
    }
  }

  .calendar-fit {
    min-height: 40vh;
    max-height: 50vh;
    overflow: auto;
  }

  .disable-date-section {
    pointer-events: none;
    opacity: 0.5;
  }

  .close-calender-btn {
    margin-top: -15px;
    margin-left: 20px;
  }

  .flex-row {
    display: flex;
    padding: 10px;
    justify-content: space-around;
  }

  .calendar-button {
    margin: 10px;
    bottom: 0;
  }
  .enter-time-period-other-time-period-options {
    margin: 15px;
    width: 100%;
  }

  .form-group-other-time-period-options {
    flex-direction: row;
    display: flex;
    margin-right: auto;
  }

  .form-control-other-time-period-options {
    display: flex;
    margin-right: auto;
  }
  .all-time-tooltip-other-time-period-options {
    margin-top: 10px;
    color: $favorite;
  }
  .time-period-btn {
    background: $white;
    border: 1px solid $lightGrayOne;
    color: $darkGray;
    font-size: 14px;
    padding: 0;

    .calender-icon {
      color: $darkGreen;
      height: 36px;
      line-height: 3.5;
      margin-right: 8px;
      width: 32px;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }

    &:hover {
      background: rgba(174, 219, 175, 0.32);
      border: 1px solid $lightGrayFive;
      color: $black;
    }

    &:active {
      background: rgba(174, 219, 175, 0.32);
      border: 1px solid $darkGreen;
      color: $black;
    }
  }

  .time-period-selected {
    background: $lightGreen;
    border: 1px solid $lightGreen;
  }

  .date-scss {
    display: flex;
    font-family: 'Roboto';
    font-size: 14px;
    .text-scss {
      width: 120px;
    }
  }

  .list-item-padding {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .date-spinner {
    position: absolute;
    left: 45%;
  }
  .relative-section {
    padding: 10px 20px;

    .anchor-picker-section {
      display: flex;
      padding: 5px 0;

      .anchor-picker-label {
        margin-right: 30px;
        line-height: 2.6;
      }
    }

    .relative-dates {
      margin: 5px;
      padding: 5px;
      width: 300px;
      display: flex;
      justify-content: space-between;
    }
    .year-dates {
      margin: 5px;
      padding: 5px 10px;
    }
    .not-selected-dates {
      border: 1px solid $lightGrayFour;
    }

    .selected-dates {
      border: 1px solid $darkGreen;
    }
    .year-group {
      padding-left: 10px;
      display: block;
    }
    .period-group {
      padding-left: 10px;
    }

    .relative-last-next-section {
      display: flex;
      margin-left: 2px;

      .last-next-label {
        line-height: 3.5;
        margin-right: 40px;
        margin-top: 5px;
      }
      .last-next-container {
        margin-right: 30px;
      }
      .last-next-text {
        width: 100px;
      }
    }
    .selected-relative-type {
      color: $darkGreen;
    }

    .label-lowercase {
      text-transform: lowercase;
    }

    .selected-chip-section {
      max-height: 100px;
      overflow: auto;
    }
  }
  .relative-button {
    display: flex;
    justify-content: flex-end;
    margin: 5px 30px 5px 0;
  }
  .anchor-icon {
    margin-right: 8px;
    height: 20px;
  }

  .selected-chips {
    cursor: pointer;
    height: 28px;
    font-size: 12px;

    > svg {
      width: 16px;
    }
  }

  .delete-selection-chip {
    width: 16px;
    line-height: 0.5;

    > svg {
      width: 16px;
      height: 16px;
    }
  }
  .align-selection {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
  }
}
