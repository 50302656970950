@import '../../helpers/variables';

.greenfieldHeadline {
  font-size: $greenfieldHeadlineFontSize;
}

.greenfieldTitle {
  font-size: $greenfieldTitleFontSize;
}

.greenfieldSubHeader {
  font-size: $greenfieldSubHeaderFontSize;
}

.greenfieldCaptionText {
  font-size: $greenfieldCaptionText;
}

.greenfieldLink {
  color: $inkBlue;
  text-decoration: none;
}

.error-success-message {
  > div {
    background-color: #4caf50;
  }
}

.error-snackbar-message {
  > div {
    background-color: $deepRed;
  }
}
