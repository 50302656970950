@import '../../../styles/helpers/variables.scss';

.isMobile {
  .homePage {
    .react-swipeable-view-container > div {
      overflow-y: hidden !important;
    }
  }
}

.homePage {
  background-color: $lightGrayOne;

  .fullHeight {
    height: 100%;
  }

  .noFavoriteDashMessage,
  .noFavoriteDatasetMessage,
  .noFavoriteCardsMessage {
    justify-content: center;
    align-items: center;

    .noMessageFirst,
    .noMessageSecond {
      display: inline-block;
      margin-top: 50px;
    }
    .noMessageIcon {
      padding-top: 5px;
    }
  }

  .homeDashboardMsgContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;
    .homeDashboardMsg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      .noMessageIcon {
        padding-top: 2px;
      }
    }
  }

  .fav-cards > .homePagePreviewWrapper,
  .fav-dashboards > .homePagePreviewWrapper {
    padding: 4px;
  }

  .fav-cards > .homePagePreviewWrapper {
    min-height: 253px;
  }

  .fav-datasets {
    .dataset-favorite-header {
      display: flex;
      justify-content: space-between;

      button {
        margin: 10px;
      }

      aside {
        padding-top: 12px;
      }
    }

    .name-desc {
      line-height: 4;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .certified-icon {
      line-height: 2.8;

      .dataset-certified-icon {
        margin-left: 10px;
        width: 20px;
        height: 20px;
      }
    }

    .footer-container {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;

      > div {
        display: flex;
      }
    }

    .homePagePreviewWrapper > div {
      height: 370px;

      > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;

        .description-container {
          flex-grow: 1;
          word-break: break-word;
          padding: 0 20px;
        }
      }
    }
  }

  .react-swipeable-view-container {
    height: 100%;
  }

  .react-swipeable-view-container > div {
    height: 100%;
    overflow: hidden !important;

    .fav-datasets {
      margin: 0;
    }
  }

  .homePageTabLabel {
    font-weight: 500;
  }
  .homePageTabRoot {
    color: $white;
  }
  .homePageTabActive {
    color: $black;
  }
  .homePageTabBar {
    background-color: $lightGrayFour;
    margin-bottom: 10px;
  }

  .homePageLeftSide {
    border-right: 2px solid $lightGrayFour;
  }

  .homePageRightSideHeader,
  .homePageLeftSideHeader {
    font-size: 14px;
    font-weight: 300;
  }
  .homePageLeftSideHeader {
    margin-left: 10px;
  }
  .briefRightSide,
  .briefLeftSide {
    padding: 20px !important;
    min-height: 450px;
  }
  .briefCard {
    height: 100%;
    padding: 25px;
  }

  .dashPreview {
    > div {
      padding: 0;
    }
    .dashHeaderText {
      margin: 10px 0 0 10px;

      h3 {
        padding: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .dashPreviewChip {
      margin: auto 5px;
      height: 25px;
    }
    .dashHeaderStarIcon {
      color: $favorite;
    }

    .fav-card-description {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    a {
      color: $black;
      text-decoration: none;
    }
  }

  .fav-padding-comp {
    padding: 10px 20px;
  }

  .count-container {
    display: flex;
    justify-content: space-around;
    padding: 5px 20px;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid black;
      width: 40%;

      > div {
        font-size: 28px;
        padding: 10px 0;
      }

      > p {
        width: 100%;
        text-align: center;
        padding: 10px 0;
        background: $lightGreen;
        color: $darkGray;
      }
    }
  }
}
