$break-small: 412px;
$break-large: 1024px;

@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-small) {
      @content;
    }
  } @else if $media == medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) {
      @content;
    }
  } @else if $media == wide-screens {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  } @else if $media == medium-wide-screen {
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      @content;
    }
  }
}

/*
.greenFieldNoPrint {
  @include respond-to(handhelds) { display: none; }
  @include respond-to(medium-screens) { display: none; }
}*/

/* Home Style */
.homePage {
  .homePageLeftSide,
  .homePageRightSide,
  .homePagePreviewWrapper {
    @include respond-to(handhelds) {
      max-width: 100%;
      flex-basis: 100%;
    }
    @include respond-to(medium-screens) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .homePageLeftSide {
    @include respond-to(handhelds) {
      border-right: none;
      border-bottom: 2px solid #d3d3d3;
    }
    @include respond-to(medium-screens) {
      border-right: none;
      border-bottom: 2px solid #d3d3d3;
    }
  }

  .dashPreview {
    @include respond-to(handhelds) {
      height: auto;
    }
    @include respond-to(medium-screens) {
      height: auto;
    }
  }

  .dashFoorterLeftSide {
    @include respond-to(handhelds) {
      width: 100%;
    }
    @include respond-to(medium-screens) {
      width: 100%;
    }
    @include respond-to(wide-screens) {
      font-size: 14px;
      max-height: 63px;
      overflow: hidden;
      width: 200px;
    }
  }
}

#gf-root .cardPreview .chart-container {
  @include respond-to(medium-wide-screen) {
    margin-left: -40px !important;
  }
}

/* Common Style */

.Flex__flex-50 {
  .cardPreview {
    .page-cards {
      @include respond-to(medium-wide-screen) {
        max-width: 450px;
      }
    }
  }
}

.component-row {
  @include respond-to(handhelds) {
    width: 100%;
  }
  @include respond-to(medium-screens) {
    width: 100%;
  }
}
