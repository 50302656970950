/*!
 * Angular Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.1
 */
/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*
*/
/*
* Since Layout API uses ng-cloak to hide the dom elements while layouts are adjusted
*
*/
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.Flex__ng-cloak,
.Flex__x-ng-cloak {
  display: none !important;
}

/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*
*/
@-moz-document url-prefix() {
  .Flex__layout-fill {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
  }
}

/*
 *  Apply Mixins to create Layout/Flexbox styles
 *
 */
.Flex__flex-order {
  -ms-flex-order: 0;
  order: 0;
}

.Flex__flex-order--20 {
  -ms-flex-order: -20;
  order: -20;
}

.Flex__flex-order--19 {
  -ms-flex-order: -19;
  order: -19;
}

.Flex__flex-order--18 {
  -ms-flex-order: -18;
  order: -18;
}

.Flex__flex-order--17 {
  -ms-flex-order: -17;
  order: -17;
}

.Flex__flex-order--16 {
  -ms-flex-order: -16;
  order: -16;
}

.Flex__flex-order--15 {
  -ms-flex-order: -15;
  order: -15;
}

.Flex__flex-order--14 {
  -ms-flex-order: -14;
  order: -14;
}

.Flex__flex-order--13 {
  -ms-flex-order: -13;
  order: -13;
}

.Flex__flex-order--12 {
  -ms-flex-order: -12;
  order: -12;
}

.Flex__flex-order--11 {
  -ms-flex-order: -11;
  order: -11;
}

.Flex__flex-order--10 {
  -ms-flex-order: -10;
  order: -10;
}

.Flex__flex-order--9 {
  -ms-flex-order: -9;
  order: -9;
}

.Flex__flex-order--8 {
  -ms-flex-order: -8;
  order: -8;
}

.Flex__flex-order--7 {
  -ms-flex-order: -7;
  order: -7;
}

.Flex__flex-order--6 {
  -ms-flex-order: -6;
  order: -6;
}

.Flex__flex-order--5 {
  -ms-flex-order: -5;
  order: -5;
}

.Flex__flex-order--4 {
  -ms-flex-order: -4;
  order: -4;
}

.Flex__flex-order--3 {
  -ms-flex-order: -3;
  order: -3;
}

.Flex__flex-order--2 {
  -ms-flex-order: -2;
  order: -2;
}

.Flex__flex-order--1 {
  -ms-flex-order: -1;
  order: -1;
}

.Flex__flex-order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.Flex__flex-order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.Flex__flex-order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.Flex__flex-order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.Flex__flex-order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.Flex__flex-order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.Flex__flex-order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.Flex__flex-order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.Flex__flex-order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.Flex__flex-order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.Flex__flex-order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.Flex__flex-order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.Flex__flex-order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.Flex__flex-order-13 {
  -ms-flex-order: 13;
  order: 13;
}

.Flex__flex-order-14 {
  -ms-flex-order: 14;
  order: 14;
}

.Flex__flex-order-15 {
  -ms-flex-order: 15;
  order: 15;
}

.Flex__flex-order-16 {
  -ms-flex-order: 16;
  order: 16;
}

.Flex__flex-order-17 {
  -ms-flex-order: 17;
  order: 17;
}

.Flex__flex-order-18 {
  -ms-flex-order: 18;
  order: 18;
}

.Flex__flex-order-19 {
  -ms-flex-order: 19;
  order: 19;
}

.Flex__flex-order-20 {
  -ms-flex-order: 20;
  order: 20;
}

.Flex__offset-0,
.Flex__flex-offset-0 {
  margin-left: 0;
}
[dir='rtl'] .Flex__offset-0,
[dir='rtl'] .Flex__flex-offset-0 {
  margin-left: auto;
  margin-right: 0;
}

.Flex__offset-5,
.Flex__flex-offset-5 {
  margin-left: 5%;
}
[dir='rtl'] .Flex__offset-5,
[dir='rtl'] .Flex__flex-offset-5 {
  margin-left: auto;
  margin-right: 5%;
}

.Flex__offset-10,
.Flex__flex-offset-10 {
  margin-left: 10%;
}
[dir='rtl'] .Flex__offset-10,
[dir='rtl'] .Flex__flex-offset-10 {
  margin-left: auto;
  margin-right: 10%;
}

.Flex__offset-15,
.Flex__flex-offset-15 {
  margin-left: 15%;
}
[dir='rtl'] .Flex__offset-15,
[dir='rtl'] .Flex__flex-offset-15 {
  margin-left: auto;
  margin-right: 15%;
}

.Flex__offset-20,
.Flex__flex-offset-20 {
  margin-left: 20%;
}
[dir='rtl'] .Flex__offset-20,
[dir='rtl'] .Flex__flex-offset-20 {
  margin-left: auto;
  margin-right: 20%;
}

.Flex__offset-25,
.Flex__flex-offset-25 {
  margin-left: 25%;
}
[dir='rtl'] .Flex__offset-25,
[dir='rtl'] .Flex__flex-offset-25 {
  margin-left: auto;
  margin-right: 25%;
}

.Flex__offset-30,
.Flex__flex-offset-30 {
  margin-left: 30%;
}
[dir='rtl'] .Flex__offset-30,
[dir='rtl'] .Flex__flex-offset-30 {
  margin-left: auto;
  margin-right: 30%;
}

.Flex__offset-35,
.Flex__flex-offset-35 {
  margin-left: 35%;
}
[dir='rtl'] .Flex__offset-35,
[dir='rtl'] .Flex__flex-offset-35 {
  margin-left: auto;
  margin-right: 35%;
}

.Flex__offset-40,
.Flex__flex-offset-40 {
  margin-left: 40%;
}
[dir='rtl'] .Flex__offset-40,
[dir='rtl'] .Flex__flex-offset-40 {
  margin-left: auto;
  margin-right: 40%;
}

.Flex__offset-45,
.Flex__flex-offset-45 {
  margin-left: 45%;
}
[dir='rtl'] .Flex__offset-45,
[dir='rtl'] .Flex__flex-offset-45 {
  margin-left: auto;
  margin-right: 45%;
}

.Flex__offset-50,
.Flex__flex-offset-50 {
  margin-left: 50%;
}
[dir='rtl'] .Flex__offset-50,
[dir='rtl'] .Flex__flex-offset-50 {
  margin-left: auto;
  margin-right: 50%;
}

.Flex__offset-55,
.Flex__flex-offset-55 {
  margin-left: 55%;
}
[dir='rtl'] .Flex__offset-55,
[dir='rtl'] .Flex__flex-offset-55 {
  margin-left: auto;
  margin-right: 55%;
}

.Flex__offset-60,
.Flex__flex-offset-60 {
  margin-left: 60%;
}
[dir='rtl'] .Flex__offset-60,
[dir='rtl'] .Flex__flex-offset-60 {
  margin-left: auto;
  margin-right: 60%;
}

.Flex__offset-65,
.Flex__flex-offset-65 {
  margin-left: 65%;
}
[dir='rtl'] .Flex__offset-65,
[dir='rtl'] .Flex__flex-offset-65 {
  margin-left: auto;
  margin-right: 65%;
}

.Flex__offset-70,
.Flex__flex-offset-70 {
  margin-left: 70%;
}
[dir='rtl'] .Flex__offset-70,
[dir='rtl'] .Flex__flex-offset-70 {
  margin-left: auto;
  margin-right: 70%;
}

.Flex__offset-75,
.Flex__flex-offset-75 {
  margin-left: 75%;
}
[dir='rtl'] .Flex__offset-75,
[dir='rtl'] .Flex__flex-offset-75 {
  margin-left: auto;
  margin-right: 75%;
}

.Flex__offset-80,
.Flex__flex-offset-80 {
  margin-left: 80%;
}
[dir='rtl'] .Flex__offset-80,
[dir='rtl'] .Flex__flex-offset-80 {
  margin-left: auto;
  margin-right: 80%;
}

.Flex__offset-85,
.Flex__flex-offset-85 {
  margin-left: 85%;
}
[dir='rtl'] .Flex__offset-85,
[dir='rtl'] .Flex__flex-offset-85 {
  margin-left: auto;
  margin-right: 85%;
}

.Flex__offset-90,
.Flex__flex-offset-90 {
  margin-left: 90%;
}
[dir='rtl'] .Flex__offset-90,
[dir='rtl'] .Flex__flex-offset-90 {
  margin-left: auto;
  margin-right: 90%;
}

.Flex__offset-95,
.Flex__flex-offset-95 {
  margin-left: 95%;
}
[dir='rtl'] .Flex__offset-95,
[dir='rtl'] .Flex__flex-offset-95 {
  margin-left: auto;
  margin-right: 95%;
}

.Flex__offset-33,
.Flex__flex-offset-33 {
  margin-left: calc(100% / 3);
}

.Flex__offset-66,
.Flex__flex-offset-66 {
  margin-left: calc(200% / 3);
}
[dir='rtl'] .Flex__offset-66,
[dir='rtl'] .Flex__flex-offset-66 {
  margin-left: auto;
  margin-right: calc(200% / 3);
}

.Flex__layout-align,
.Flex__layout-align-start-stretch {
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.Flex__layout-align-start,
.Flex__layout-align-start-start,
.Flex__layout-align-start-center,
.Flex__layout-align-start-end,
.Flex__layout-align-start-stretch {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.Flex__layout-align-center,
.Flex__layout-align-center-start,
.Flex__layout-align-center-center,
.Flex__layout-align-center-end,
.Flex__layout-align-center-stretch {
  -ms-flex-pack: center;
  justify-content: center;
}

.Flex__layout-align-end,
.Flex__layout-align-end-start,
.Flex__layout-align-end-center,
.Flex__layout-align-end-end,
.Flex__layout-align-end-stretch {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.Flex__layout-align-space-around,
.Flex__layout-align-space-around-center,
.Flex__layout-align-space-around-start,
.Flex__layout-align-space-around-end,
.Flex__layout-align-space-around-stretch {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.Flex__layout-align-space-between,
.Flex__layout-align-space-between-center,
.Flex__layout-align-space-between-start,
.Flex__layout-align-space-between-end,
.Flex__layout-align-space-between-stretch {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.Flex__layout-align-start-start,
.Flex__layout-align-center-start,
.Flex__layout-align-end-start,
.Flex__layout-align-space-between-start,
.Flex__layout-align-space-around-start {
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.Flex__layout-align-start-center,
.Flex__layout-align-center-center,
.Flex__layout-align-end-center,
.Flex__layout-align-space-between-center,
.Flex__layout-align-space-around-center {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  max-width: 100%;
}

.Flex__layout-align-start-center > *,
.Flex__layout-align-center-center > *,
.Flex__layout-align-end-center > *,
.Flex__layout-align-space-between-center > *,
.Flex__layout-align-space-around-center > * {
  max-width: 100%;
  box-sizing: border-box;
}

.Flex__layout-align-start-end,
.Flex__layout-align-center-end,
.Flex__layout-align-end-end,
.Flex__layout-align-space-between-end,
.Flex__layout-align-space-around-end {
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.Flex__layout-align-start-stretch,
.Flex__layout-align-center-stretch,
.Flex__layout-align-end-stretch,
.Flex__layout-align-space-between-stretch,
.Flex__layout-align-space-around-stretch {
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.Flex__flex {
  -ms-flex: 1;
  flex: 1;
  box-sizing: border-box;
}

.Flex__flex-grow {
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  box-sizing: border-box;
}

.Flex__flex-initial {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  box-sizing: border-box;
}

.Flex__flex-auto {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  box-sizing: border-box;
}

.Flex__flex-none {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  box-sizing: border-box;
}

.Flex__flex-noshrink {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  box-sizing: border-box;
}

.Flex__flex-nogrow {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  box-sizing: border-box;
}

.Flex__flex-0 {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-0 {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
  min-width: 0;
}

.Flex__layout-column > .Flex__flex-0 {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-0 {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
  min-width: 0;
}

.Flex__layout-column > .Flex__flex-0 {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box;
  min-height: 0;
}

.Flex__flex-5 {
  -ms-flex: 1 1 5%;
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-5 {
  -ms-flex: 1 1 5%;
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-5 {
  -ms-flex: 1 1 5%;
  flex: 1 1 5%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-5 {
  -ms-flex: 1 1 5%;
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-5 {
  -ms-flex: 1 1 5%;
  flex: 1 1 5%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box;
}

.Flex__flex-10 {
  -ms-flex: 1 1 10%;
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-10 {
  -ms-flex: 1 1 10%;
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-10 {
  -ms-flex: 1 1 10%;
  flex: 1 1 10%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-10 {
  -ms-flex: 1 1 10%;
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-10 {
  -ms-flex: 1 1 10%;
  flex: 1 1 10%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box;
}

.Flex__flex-15 {
  -ms-flex: 1 1 15%;
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-15 {
  -ms-flex: 1 1 15%;
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-15 {
  -ms-flex: 1 1 15%;
  flex: 1 1 15%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-15 {
  -ms-flex: 1 1 15%;
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-15 {
  -ms-flex: 1 1 15%;
  flex: 1 1 15%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box;
}

.Flex__flex-20 {
  -ms-flex: 1 1 20%;
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-20 {
  -ms-flex: 1 1 20%;
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-20 {
  -ms-flex: 1 1 20%;
  flex: 1 1 20%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-20 {
  -ms-flex: 1 1 20%;
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-20 {
  -ms-flex: 1 1 20%;
  flex: 1 1 20%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box;
}

.Flex__flex-25 {
  -ms-flex: 1 1 25%;
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-25 {
  -ms-flex: 1 1 25%;
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-25 {
  -ms-flex: 1 1 25%;
  flex: 1 1 25%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-25 {
  -ms-flex: 1 1 25%;
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-25 {
  -ms-flex: 1 1 25%;
  flex: 1 1 25%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box;
}

.Flex__flex-30 {
  -ms-flex: 1 1 30%;
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-30 {
  -ms-flex: 1 1 30%;
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-30 {
  -ms-flex: 1 1 30%;
  flex: 1 1 30%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-30 {
  -ms-flex: 1 1 30%;
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-30 {
  -ms-flex: 1 1 30%;
  flex: 1 1 30%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box;
}

.Flex__flex-35 {
  -ms-flex: 1 1 35%;
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-35 {
  -ms-flex: 1 1 35%;
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-35 {
  -ms-flex: 1 1 35%;
  flex: 1 1 35%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-35 {
  -ms-flex: 1 1 35%;
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-35 {
  -ms-flex: 1 1 35%;
  flex: 1 1 35%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box;
}

.Flex__flex-40 {
  -ms-flex: 1 1 40%;
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-40 {
  -ms-flex: 1 1 40%;
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-40 {
  -ms-flex: 1 1 40%;
  flex: 1 1 40%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-40 {
  -ms-flex: 1 1 40%;
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-40 {
  -ms-flex: 1 1 40%;
  flex: 1 1 40%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box;
}

.Flex__flex-45 {
  -ms-flex: 1 1 45%;
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-45 {
  -ms-flex: 1 1 45%;
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-45 {
  -ms-flex: 1 1 45%;
  flex: 1 1 45%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-45 {
  -ms-flex: 1 1 45%;
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-45 {
  -ms-flex: 1 1 45%;
  flex: 1 1 45%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box;
}

.Flex__flex-50 {
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-50 {
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-50 {
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-50 {
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-50 {
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box;
}

.Flex__flex-55 {
  -ms-flex: 1 1 55%;
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-55 {
  -ms-flex: 1 1 55%;
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-55 {
  -ms-flex: 1 1 55%;
  flex: 1 1 55%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-55 {
  -ms-flex: 1 1 55%;
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-55 {
  -ms-flex: 1 1 55%;
  flex: 1 1 55%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box;
}

.Flex__flex-60 {
  -ms-flex: 1 1 60%;
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-60 {
  -ms-flex: 1 1 60%;
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-60 {
  -ms-flex: 1 1 60%;
  flex: 1 1 60%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-60 {
  -ms-flex: 1 1 60%;
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-60 {
  -ms-flex: 1 1 60%;
  flex: 1 1 60%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box;
}

.Flex__flex-65 {
  -ms-flex: 1 1 65%;
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-65 {
  -ms-flex: 1 1 65%;
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-65 {
  -ms-flex: 1 1 65%;
  flex: 1 1 65%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-65 {
  -ms-flex: 1 1 65%;
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-65 {
  -ms-flex: 1 1 65%;
  flex: 1 1 65%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box;
}

.Flex__flex-70 {
  -ms-flex: 1 1 70%;
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-70 {
  -ms-flex: 1 1 70%;
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-70 {
  -ms-flex: 1 1 70%;
  flex: 1 1 70%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-70 {
  -ms-flex: 1 1 70%;
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-70 {
  -ms-flex: 1 1 70%;
  flex: 1 1 70%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box;
}

.Flex__flex-75 {
  -ms-flex: 1 1 75%;
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-75 {
  -ms-flex: 1 1 75%;
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-75 {
  -ms-flex: 1 1 75%;
  flex: 1 1 75%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-75 {
  -ms-flex: 1 1 75%;
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-75 {
  -ms-flex: 1 1 75%;
  flex: 1 1 75%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box;
}

.Flex__flex-80 {
  -ms-flex: 1 1 80%;
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-80 {
  -ms-flex: 1 1 80%;
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-80 {
  -ms-flex: 1 1 80%;
  flex: 1 1 80%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-80 {
  -ms-flex: 1 1 80%;
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-80 {
  -ms-flex: 1 1 80%;
  flex: 1 1 80%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box;
}

.Flex__flex-85 {
  -ms-flex: 1 1 85%;
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-85 {
  -ms-flex: 1 1 85%;
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-85 {
  -ms-flex: 1 1 85%;
  flex: 1 1 85%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-85 {
  -ms-flex: 1 1 85%;
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-85 {
  -ms-flex: 1 1 85%;
  flex: 1 1 85%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box;
}

.Flex__flex-90 {
  -ms-flex: 1 1 90%;
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-90 {
  -ms-flex: 1 1 90%;
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-90 {
  -ms-flex: 1 1 90%;
  flex: 1 1 90%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-90 {
  -ms-flex: 1 1 90%;
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-90 {
  -ms-flex: 1 1 90%;
  flex: 1 1 90%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box;
}

.Flex__flex-95 {
  -ms-flex: 1 1 95%;
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-95 {
  -ms-flex: 1 1 95%;
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-95 {
  -ms-flex: 1 1 95%;
  flex: 1 1 95%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-95 {
  -ms-flex: 1 1 95%;
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-95 {
  -ms-flex: 1 1 95%;
  flex: 1 1 95%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box;
}

.Flex__flex-100 {
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-100 {
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-100 {
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-100 {
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-100 {
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-33,
.Flex__layout-row > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex-66,
.Flex__layout-row > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box;
}

.Flex__layout-row > .Flex__flex {
  min-width: 0;
}

.Flex__layout-column > .Flex__flex-33,
.Flex__layout-column > .Flex__flex-33 {
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex-66,
.Flex__layout-column > .Flex__flex-66 {
  -ms-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box;
}

.Flex__layout-column > .Flex__flex {
  min-height: 0;
}

.Flex__layout,
.Flex__layout-column,
.Flex__layout-row {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
}

.Flex__layout-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.Flex__layout-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.Flex__layout-padding-sm > *,
.Flex__layout-padding > .Flex__flex-sm {
  padding: 0.18438rem;
}

.Flex__layout-padding,
.Flex__layout-padding-gt-sm,
.Flex__layout-padding-md,
.Flex__layout-padding > *,
.Flex__layout-padding-gt-sm > *,
.Flex__layout-padding-md > *,
.Flex__layout-padding > .Flex__flex,
.Flex__layout-padding > .Flex__flex-gt-sm,
.Flex__layout-padding > .Flex__flex-md {
  padding: 0.36875rem;
}

.Flex__layout-padding-gt-md > *,
.Flex__layout-padding-lg > *,
.Flex__layout-padding-gt-lg > *,
.Flex__layout-padding > .Flex__flex-gt-md,
.Flex__layout-padding > .Flex__flex-lg,
.Flex__layout-padding > .Flex__flex-lg,
.Flex__layout-padding > .Flex__flex-gt-lg {
  padding: 0.7375rem;
}

.Flex__layout-margin-sm > *,
.Flex__layout-margin > .Flex__flex-sm {
  margin: 0.18438rem;
}

.Flex__layout-margin,
.Flex__layout-margin-gt-sm,
.Flex__layout-margin-md,
.Flex__layout-margin > *,
.Flex__layout-margin-gt-sm > *,
.Flex__layout-margin-md > *,
.Flex__layout-margin > .Flex__flex,
.Flex__layout-margin > .Flex__flex-gt-sm,
.Flex__layout-margin > .Flex__flex-md {
  margin: 0.36875rem;
}

.Flex__layout-margin-gt-md > *,
.Flex__layout-margin-lg > *,
.Flex__layout-margin-gt-lg > *,
.Flex__layout-margin > .Flex__flex-gt-md,
.Flex__layout-margin > .Flex__flex-lg,
.Flex__layout-margin > .Flex__flex-gt-lg {
  margin: 0.7375rem;
}

.Flex__layout-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.Flex__layout-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.Flex__layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */
@media (max-width: 599px) {
  .Flex__hide-xs:not(.Flex__show-xs):not(.Flex__show),
  .Flex__hide:not(.Flex__show-xs):not(.Flex__show) {
    display: none;
  }
  .Flex__flex-order-xs--20 {
    -ms-flex-order: -20;
    order: -20;
  }
  .Flex__flex-order-xs--19 {
    -ms-flex-order: -19;
    order: -19;
  }
  .Flex__flex-order-xs--18 {
    -ms-flex-order: -18;
    order: -18;
  }
  .Flex__flex-order-xs--17 {
    -ms-flex-order: -17;
    order: -17;
  }
  .Flex__flex-order-xs--16 {
    -ms-flex-order: -16;
    order: -16;
  }
  .Flex__flex-order-xs--15 {
    -ms-flex-order: -15;
    order: -15;
  }
  .Flex__flex-order-xs--14 {
    -ms-flex-order: -14;
    order: -14;
  }
  .Flex__flex-order-xs--13 {
    -ms-flex-order: -13;
    order: -13;
  }
  .Flex__flex-order-xs--12 {
    -ms-flex-order: -12;
    order: -12;
  }
  .Flex__flex-order-xs--11 {
    -ms-flex-order: -11;
    order: -11;
  }
  .Flex__flex-order-xs--10 {
    -ms-flex-order: -10;
    order: -10;
  }
  .Flex__flex-order-xs--9 {
    -ms-flex-order: -9;
    order: -9;
  }
  .Flex__flex-order-xs--8 {
    -ms-flex-order: -8;
    order: -8;
  }
  .Flex__flex-order-xs--7 {
    -ms-flex-order: -7;
    order: -7;
  }
  .Flex__flex-order-xs--6 {
    -ms-flex-order: -6;
    order: -6;
  }
  .Flex__flex-order-xs--5 {
    -ms-flex-order: -5;
    order: -5;
  }
  .Flex__flex-order-xs--4 {
    -ms-flex-order: -4;
    order: -4;
  }
  .Flex__flex-order-xs--3 {
    -ms-flex-order: -3;
    order: -3;
  }
  .Flex__flex-order-xs--2 {
    -ms-flex-order: -2;
    order: -2;
  }
  .Flex__flex-order-xs--1 {
    -ms-flex-order: -1;
    order: -1;
  }
  .Flex__flex-order-xs-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .Flex__flex-order-xs-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .Flex__flex-order-xs-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .Flex__flex-order-xs-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .Flex__flex-order-xs-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .Flex__flex-order-xs-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .Flex__flex-order-xs-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .Flex__flex-order-xs-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .Flex__flex-order-xs-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .Flex__flex-order-xs-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .Flex__flex-order-xs-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .Flex__flex-order-xs-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .Flex__flex-order-xs-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .Flex__flex-order-xs-13 {
    -ms-flex-order: 13;
    order: 13;
  }
  .Flex__flex-order-xs-14 {
    -ms-flex-order: 14;
    order: 14;
  }
  .Flex__flex-order-xs-15 {
    -ms-flex-order: 15;
    order: 15;
  }
  .Flex__flex-order-xs-16 {
    -ms-flex-order: 16;
    order: 16;
  }
  .Flex__flex-order-xs-17 {
    -ms-flex-order: 17;
    order: 17;
  }
  .Flex__flex-order-xs-18 {
    -ms-flex-order: 18;
    order: 18;
  }
  .Flex__flex-order-xs-19 {
    -ms-flex-order: 19;
    order: 19;
  }
  .Flex__flex-order-xs-20 {
    -ms-flex-order: 20;
    order: 20;
  }
  .Flex__offset-xs-0,
  .Flex__flex-offset-xs-0 {
    margin-left: 0;
  }
  [dir='rtl'] .Flex__offset-xs-0,
  [dir='rtl'] .Flex__flex-offset-xs-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .Flex__offset-xs-5,
  .Flex__flex-offset-xs-5 {
    margin-left: 5%;
  }
  [dir='rtl'] .Flex__offset-xs-5,
  [dir='rtl'] .Flex__flex-offset-xs-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .Flex__offset-xs-10,
  .Flex__flex-offset-xs-10 {
    margin-left: 10%;
  }
  [dir='rtl'] .Flex__offset-xs-10,
  [dir='rtl'] .Flex__flex-offset-xs-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .Flex__offset-xs-15,
  .Flex__flex-offset-xs-15 {
    margin-left: 15%;
  }
  [dir='rtl'] .Flex__offset-xs-15,
  [dir='rtl'] .Flex__flex-offset-xs-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .Flex__offset-xs-20,
  .Flex__flex-offset-xs-20 {
    margin-left: 20%;
  }
  [dir='rtl'] .Flex__offset-xs-20,
  [dir='rtl'] .Flex__flex-offset-xs-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .Flex__offset-xs-25,
  .Flex__flex-offset-xs-25 {
    margin-left: 25%;
  }
  [dir='rtl'] .Flex__offset-xs-25,
  [dir='rtl'] .Flex__flex-offset-xs-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .Flex__offset-xs-30,
  .Flex__flex-offset-xs-30 {
    margin-left: 30%;
  }
  [dir='rtl'] .Flex__offset-xs-30,
  [dir='rtl'] .Flex__flex-offset-xs-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .Flex__offset-xs-35,
  .Flex__flex-offset-xs-35 {
    margin-left: 35%;
  }
  [dir='rtl'] .Flex__offset-xs-35,
  [dir='rtl'] .Flex__flex-offset-xs-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .Flex__offset-xs-40,
  .Flex__flex-offset-xs-40 {
    margin-left: 40%;
  }
  [dir='rtl'] .Flex__offset-xs-40,
  [dir='rtl'] .Flex__flex-offset-xs-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .Flex__offset-xs-45,
  .Flex__flex-offset-xs-45 {
    margin-left: 45%;
  }
  [dir='rtl'] .Flex__offset-xs-45,
  [dir='rtl'] .Flex__flex-offset-xs-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .Flex__offset-xs-50,
  .Flex__flex-offset-xs-50 {
    margin-left: 50%;
  }
  [dir='rtl'] .Flex__offset-xs-50,
  [dir='rtl'] .Flex__flex-offset-xs-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .Flex__offset-xs-55,
  .Flex__flex-offset-xs-55 {
    margin-left: 55%;
  }
  [dir='rtl'] .Flex__offset-xs-55,
  [dir='rtl'] .Flex__flex-offset-xs-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .Flex__offset-xs-60,
  .Flex__flex-offset-xs-60 {
    margin-left: 60%;
  }
  [dir='rtl'] .Flex__offset-xs-60,
  [dir='rtl'] .Flex__flex-offset-xs-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .Flex__offset-xs-65,
  .Flex__flex-offset-xs-65 {
    margin-left: 65%;
  }
  [dir='rtl'] .Flex__offset-xs-65,
  [dir='rtl'] .Flex__flex-offset-xs-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .Flex__offset-xs-70,
  .Flex__flex-offset-xs-70 {
    margin-left: 70%;
  }
  [dir='rtl'] .Flex__offset-xs-70,
  [dir='rtl'] .Flex__flex-offset-xs-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .Flex__offset-xs-75,
  .Flex__flex-offset-xs-75 {
    margin-left: 75%;
  }
  [dir='rtl'] .Flex__offset-xs-75,
  [dir='rtl'] .Flex__flex-offset-xs-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .Flex__offset-xs-80,
  .Flex__flex-offset-xs-80 {
    margin-left: 80%;
  }
  [dir='rtl'] .Flex__offset-xs-80,
  [dir='rtl'] .Flex__flex-offset-xs-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .Flex__offset-xs-85,
  .Flex__flex-offset-xs-85 {
    margin-left: 85%;
  }
  [dir='rtl'] .Flex__offset-xs-85,
  [dir='rtl'] .Flex__flex-offset-xs-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .Flex__offset-xs-90,
  .Flex__flex-offset-xs-90 {
    margin-left: 90%;
  }
  [dir='rtl'] .Flex__offset-xs-90,
  [dir='rtl'] .Flex__flex-offset-xs-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .Flex__offset-xs-95,
  .Flex__flex-offset-xs-95 {
    margin-left: 95%;
  }
  [dir='rtl'] .Flex__offset-xs-95,
  [dir='rtl'] .Flex__flex-offset-xs-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .Flex__offset-xs-33,
  .Flex__flex-offset-xs-33 {
    margin-left: calc(100% / 3);
  }
  .Flex__offset-xs-66,
  .Flex__flex-offset-xs-66 {
    margin-left: calc(200% / 3);
  }
  [dir='rtl'] .Flex__offset-xs-66,
  [dir='rtl'] .Flex__flex-offset-xs-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }
  .Flex__layout-align-xs,
  .Flex__layout-align-xs-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .Flex__layout-align-xs-start,
  .Flex__layout-align-xs-start-start,
  .Flex__layout-align-xs-start-center,
  .Flex__layout-align-xs-start-end,
  .Flex__layout-align-xs-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .Flex__layout-align-xs-center,
  .Flex__layout-align-xs-center-start,
  .Flex__layout-align-xs-center-center,
  .Flex__layout-align-xs-center-end,
  .Flex__layout-align-xs-center-stretch {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .Flex__layout-align-xs-end,
  .Flex__layout-align-xs-end-start,
  .Flex__layout-align-xs-end-center,
  .Flex__layout-align-xs-end-end,
  .Flex__layout-align-xs-end-stretch {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .Flex__layout-align-xs-space-around,
  .Flex__layout-align-xs-space-around-center,
  .Flex__layout-align-xs-space-around-start,
  .Flex__layout-align-xs-space-around-end,
  .Flex__layout-align-xs-space-around-stretch {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .Flex__layout-align-xs-space-between,
  .Flex__layout-align-xs-space-between-center,
  .Flex__layout-align-xs-space-between-start,
  .Flex__layout-align-xs-space-between-end,
  .Flex__layout-align-xs-space-between-stretch {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .Flex__layout-align-xs-start-start,
  .Flex__layout-align-xs-center-start,
  .Flex__layout-align-xs-end-start,
  .Flex__layout-align-xs-space-between-start,
  .Flex__layout-align-xs-space-around-start {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .Flex__layout-align-xs-start-center,
  .Flex__layout-align-xs-center-center,
  .Flex__layout-align-xs-end-center,
  .Flex__layout-align-xs-space-between-center,
  .Flex__layout-align-xs-space-around-center {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    max-width: 100%;
  }
  .Flex__layout-align-xs-start-center > *,
  .Flex__layout-align-xs-center-center > *,
  .Flex__layout-align-xs-end-center > *,
  .Flex__layout-align-xs-space-between-center > *,
  .Flex__layout-align-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-align-xs-start-end,
  .Flex__layout-align-xs-center-end,
  .Flex__layout-align-xs-end-end,
  .Flex__layout-align-xs-space-between-end,
  .Flex__layout-align-xs-space-around-end {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .Flex__layout-align-xs-start-stretch,
  .Flex__layout-align-xs-center-stretch,
  .Flex__layout-align-xs-end-stretch,
  .Flex__layout-align-xs-space-between-stretch,
  .Flex__layout-align-xs-space-around-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .Flex__flex-xs {
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }
  .Flex__flex-xs-grow {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-initial {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-xs-auto {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-xs-none {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-xs-noshrink {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-xs-nogrow {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-xs-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-column > .Flex__flex-xs-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .Flex__flex-xs-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__flex-xs-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-33,
  .Flex__layout-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xs-66,
  .Flex__layout-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-33,
  .Flex__layout-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xs-66,
  .Flex__layout-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-33,
  .Flex__layout-xs-row > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex-xs-66,
  .Flex__layout-xs-row > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-row > .Flex__flex {
    min-width: 0;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-33,
  .Flex__layout-xs-column > .Flex__flex-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex-xs-66,
  .Flex__layout-xs-column > .Flex__flex-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xs-column > .Flex__flex {
    min-height: 0;
  }
  .Flex__layout-xs,
  .Flex__layout-xs-column,
  .Flex__layout-xs-row {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
  }
  .Flex__layout-xs-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .Flex__layout-xs-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 600px) {
  .Flex__flex-order-gt-xs--20 {
    -ms-flex-order: -20;
    order: -20;
  }
  .Flex__flex-order-gt-xs--19 {
    -ms-flex-order: -19;
    order: -19;
  }
  .Flex__flex-order-gt-xs--18 {
    -ms-flex-order: -18;
    order: -18;
  }
  .Flex__flex-order-gt-xs--17 {
    -ms-flex-order: -17;
    order: -17;
  }
  .Flex__flex-order-gt-xs--16 {
    -ms-flex-order: -16;
    order: -16;
  }
  .Flex__flex-order-gt-xs--15 {
    -ms-flex-order: -15;
    order: -15;
  }
  .Flex__flex-order-gt-xs--14 {
    -ms-flex-order: -14;
    order: -14;
  }
  .Flex__flex-order-gt-xs--13 {
    -ms-flex-order: -13;
    order: -13;
  }
  .Flex__flex-order-gt-xs--12 {
    -ms-flex-order: -12;
    order: -12;
  }
  .Flex__flex-order-gt-xs--11 {
    -ms-flex-order: -11;
    order: -11;
  }
  .Flex__flex-order-gt-xs--10 {
    -ms-flex-order: -10;
    order: -10;
  }
  .Flex__flex-order-gt-xs--9 {
    -ms-flex-order: -9;
    order: -9;
  }
  .Flex__flex-order-gt-xs--8 {
    -ms-flex-order: -8;
    order: -8;
  }
  .Flex__flex-order-gt-xs--7 {
    -ms-flex-order: -7;
    order: -7;
  }
  .Flex__flex-order-gt-xs--6 {
    -ms-flex-order: -6;
    order: -6;
  }
  .Flex__flex-order-gt-xs--5 {
    -ms-flex-order: -5;
    order: -5;
  }
  .Flex__flex-order-gt-xs--4 {
    -ms-flex-order: -4;
    order: -4;
  }
  .Flex__flex-order-gt-xs--3 {
    -ms-flex-order: -3;
    order: -3;
  }
  .Flex__flex-order-gt-xs--2 {
    -ms-flex-order: -2;
    order: -2;
  }
  .Flex__flex-order-gt-xs--1 {
    -ms-flex-order: -1;
    order: -1;
  }
  .Flex__flex-order-gt-xs-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .Flex__flex-order-gt-xs-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .Flex__flex-order-gt-xs-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .Flex__flex-order-gt-xs-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .Flex__flex-order-gt-xs-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .Flex__flex-order-gt-xs-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .Flex__flex-order-gt-xs-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .Flex__flex-order-gt-xs-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .Flex__flex-order-gt-xs-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .Flex__flex-order-gt-xs-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .Flex__flex-order-gt-xs-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .Flex__flex-order-gt-xs-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .Flex__flex-order-gt-xs-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .Flex__flex-order-gt-xs-13 {
    -ms-flex-order: 13;
    order: 13;
  }
  .Flex__flex-order-gt-xs-14 {
    -ms-flex-order: 14;
    order: 14;
  }
  .Flex__flex-order-gt-xs-15 {
    -ms-flex-order: 15;
    order: 15;
  }
  .Flex__flex-order-gt-xs-16 {
    -ms-flex-order: 16;
    order: 16;
  }
  .Flex__flex-order-gt-xs-17 {
    -ms-flex-order: 17;
    order: 17;
  }
  .Flex__flex-order-gt-xs-18 {
    -ms-flex-order: 18;
    order: 18;
  }
  .Flex__flex-order-gt-xs-19 {
    -ms-flex-order: 19;
    order: 19;
  }
  .Flex__flex-order-gt-xs-20 {
    -ms-flex-order: 20;
    order: 20;
  }
  .Flex__offset-gt-xs-0,
  .Flex__flex-offset-gt-xs-0 {
    margin-left: 0;
  }
  [dir='rtl'] .Flex__offset-gt-xs-0,
  [dir='rtl'] .Flex__flex-offset-gt-xs-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .Flex__offset-gt-xs-5,
  .Flex__flex-offset-gt-xs-5 {
    margin-left: 5%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-5,
  [dir='rtl'] .Flex__flex-offset-gt-xs-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .Flex__offset-gt-xs-10,
  .Flex__flex-offset-gt-xs-10 {
    margin-left: 10%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-10,
  [dir='rtl'] .Flex__flex-offset-gt-xs-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .Flex__offset-gt-xs-15,
  .Flex__flex-offset-gt-xs-15 {
    margin-left: 15%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-15,
  [dir='rtl'] .Flex__flex-offset-gt-xs-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .Flex__offset-gt-xs-20,
  .Flex__flex-offset-gt-xs-20 {
    margin-left: 20%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-20,
  [dir='rtl'] .Flex__flex-offset-gt-xs-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .Flex__offset-gt-xs-25,
  .Flex__flex-offset-gt-xs-25 {
    margin-left: 25%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-25,
  [dir='rtl'] .Flex__flex-offset-gt-xs-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .Flex__offset-gt-xs-30,
  .Flex__flex-offset-gt-xs-30 {
    margin-left: 30%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-30,
  [dir='rtl'] .Flex__flex-offset-gt-xs-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .Flex__offset-gt-xs-35,
  .Flex__flex-offset-gt-xs-35 {
    margin-left: 35%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-35,
  [dir='rtl'] .Flex__flex-offset-gt-xs-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .Flex__offset-gt-xs-40,
  .Flex__flex-offset-gt-xs-40 {
    margin-left: 40%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-40,
  [dir='rtl'] .Flex__flex-offset-gt-xs-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .Flex__offset-gt-xs-45,
  .Flex__flex-offset-gt-xs-45 {
    margin-left: 45%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-45,
  [dir='rtl'] .Flex__flex-offset-gt-xs-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .Flex__offset-gt-xs-50,
  .Flex__flex-offset-gt-xs-50 {
    margin-left: 50%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-50,
  [dir='rtl'] .Flex__flex-offset-gt-xs-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .Flex__offset-gt-xs-55,
  .Flex__flex-offset-gt-xs-55 {
    margin-left: 55%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-55,
  [dir='rtl'] .Flex__flex-offset-gt-xs-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .Flex__offset-gt-xs-60,
  .Flex__flex-offset-gt-xs-60 {
    margin-left: 60%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-60,
  [dir='rtl'] .Flex__flex-offset-gt-xs-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .Flex__offset-gt-xs-65,
  .Flex__flex-offset-gt-xs-65 {
    margin-left: 65%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-65,
  [dir='rtl'] .Flex__flex-offset-gt-xs-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .Flex__offset-gt-xs-70,
  .Flex__flex-offset-gt-xs-70 {
    margin-left: 70%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-70,
  [dir='rtl'] .Flex__flex-offset-gt-xs-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .Flex__offset-gt-xs-75,
  .Flex__flex-offset-gt-xs-75 {
    margin-left: 75%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-75,
  [dir='rtl'] .Flex__flex-offset-gt-xs-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .Flex__offset-gt-xs-80,
  .Flex__flex-offset-gt-xs-80 {
    margin-left: 80%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-80,
  [dir='rtl'] .Flex__flex-offset-gt-xs-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .Flex__offset-gt-xs-85,
  .Flex__flex-offset-gt-xs-85 {
    margin-left: 85%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-85,
  [dir='rtl'] .Flex__flex-offset-gt-xs-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .Flex__offset-gt-xs-90,
  .Flex__flex-offset-gt-xs-90 {
    margin-left: 90%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-90,
  [dir='rtl'] .Flex__flex-offset-gt-xs-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .Flex__offset-gt-xs-95,
  .Flex__flex-offset-gt-xs-95 {
    margin-left: 95%;
  }
  [dir='rtl'] .Flex__offset-gt-xs-95,
  [dir='rtl'] .Flex__flex-offset-gt-xs-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .Flex__offset-gt-xs-33,
  .Flex__flex-offset-gt-xs-33 {
    margin-left: calc(100% / 3);
  }
  .Flex__offset-gt-xs-66,
  .Flex__flex-offset-gt-xs-66 {
    margin-left: calc(200% / 3);
  }
  [dir='rtl'] .Flex__offset-gt-xs-66,
  [dir='rtl'] .Flex__flex-offset-gt-xs-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }
  .Flex__layout-align-gt-xs,
  .Flex__layout-align-gt-xs-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .Flex__layout-align-gt-xs-start,
  .Flex__layout-align-gt-xs-start-start,
  .Flex__layout-align-gt-xs-start-center,
  .Flex__layout-align-gt-xs-start-end,
  .Flex__layout-align-gt-xs-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .Flex__layout-align-gt-xs-center,
  .Flex__layout-align-gt-xs-center-start,
  .Flex__layout-align-gt-xs-center-center,
  .Flex__layout-align-gt-xs-center-end,
  .Flex__layout-align-gt-xs-center-stretch {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .Flex__layout-align-gt-xs-end,
  .Flex__layout-align-gt-xs-end-start,
  .Flex__layout-align-gt-xs-end-center,
  .Flex__layout-align-gt-xs-end-end,
  .Flex__layout-align-gt-xs-end-stretch {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .Flex__layout-align-gt-xs-space-around,
  .Flex__layout-align-gt-xs-space-around-center,
  .Flex__layout-align-gt-xs-space-around-start,
  .Flex__layout-align-gt-xs-space-around-end,
  .Flex__layout-align-gt-xs-space-around-stretch {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .Flex__layout-align-gt-xs-space-between,
  .Flex__layout-align-gt-xs-space-between-center,
  .Flex__layout-align-gt-xs-space-between-start,
  .Flex__layout-align-gt-xs-space-between-end,
  .Flex__layout-align-gt-xs-space-between-stretch {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .Flex__layout-align-gt-xs-start-start,
  .Flex__layout-align-gt-xs-center-start,
  .Flex__layout-align-gt-xs-end-start,
  .Flex__layout-align-gt-xs-space-between-start,
  .Flex__layout-align-gt-xs-space-around-start {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .Flex__layout-align-gt-xs-start-center,
  .Flex__layout-align-gt-xs-center-center,
  .Flex__layout-align-gt-xs-end-center,
  .Flex__layout-align-gt-xs-space-between-center,
  .Flex__layout-align-gt-xs-space-around-center {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    max-width: 100%;
  }
  .Flex__layout-align-gt-xs-start-center > *,
  .Flex__layout-align-gt-xs-center-center > *,
  .Flex__layout-align-gt-xs-end-center > *,
  .Flex__layout-align-gt-xs-space-between-center > *,
  .Flex__layout-align-gt-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-align-gt-xs-start-end,
  .Flex__layout-align-gt-xs-center-end,
  .Flex__layout-align-gt-xs-end-end,
  .Flex__layout-align-gt-xs-space-between-end,
  .Flex__layout-align-gt-xs-space-around-end {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .Flex__layout-align-gt-xs-start-stretch,
  .Flex__layout-align-gt-xs-center-stretch,
  .Flex__layout-align-gt-xs-end-stretch,
  .Flex__layout-align-gt-xs-space-between-stretch,
  .Flex__layout-align-gt-xs-space-around-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .Flex__flex-gt-xs {
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-grow {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-initial {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-auto {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-none {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-noshrink {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-nogrow {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .Flex__flex-gt-xs-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-xs-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-33,
  .Flex__layout-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-xs-66,
  .Flex__layout-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-33,
  .Flex__layout-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-xs-66,
  .Flex__layout-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-33,
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-66,
  .Flex__layout-gt-xs-row > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-row > .Flex__flex {
    min-width: 0;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-33,
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-66,
  .Flex__layout-gt-xs-column > .Flex__flex-gt-xs-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-xs-column > .Flex__flex {
    min-height: 0;
  }
  .Flex__layout-gt-xs,
  .Flex__layout-gt-xs-column,
  .Flex__layout-gt-xs-row {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
  }
  .Flex__layout-gt-xs-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .Flex__layout-gt-xs-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .Flex__hide:not(.Flex__show-gt-xs):not(.Flex__show-sm):not(.Flex__show),
  .Flex__hide-gt-xs:not(.Flex__show-gt-xs):not(.Flex__show-sm):not(.Flex__show) {
    display: none;
  }
  .Flex__hide-sm:not(.Flex__show-gt-xs):not(.Flex__show-sm):not(.Flex__show) {
    display: none;
  }
  .Flex__flex-order-sm--20 {
    -ms-flex-order: -20;
    order: -20;
  }
  .Flex__flex-order-sm--19 {
    -ms-flex-order: -19;
    order: -19;
  }
  .Flex__flex-order-sm--18 {
    -ms-flex-order: -18;
    order: -18;
  }
  .Flex__flex-order-sm--17 {
    -ms-flex-order: -17;
    order: -17;
  }
  .Flex__flex-order-sm--16 {
    -ms-flex-order: -16;
    order: -16;
  }
  .Flex__flex-order-sm--15 {
    -ms-flex-order: -15;
    order: -15;
  }
  .Flex__flex-order-sm--14 {
    -ms-flex-order: -14;
    order: -14;
  }
  .Flex__flex-order-sm--13 {
    -ms-flex-order: -13;
    order: -13;
  }
  .Flex__flex-order-sm--12 {
    -ms-flex-order: -12;
    order: -12;
  }
  .Flex__flex-order-sm--11 {
    -ms-flex-order: -11;
    order: -11;
  }
  .Flex__flex-order-sm--10 {
    -ms-flex-order: -10;
    order: -10;
  }
  .Flex__flex-order-sm--9 {
    -ms-flex-order: -9;
    order: -9;
  }
  .Flex__flex-order-sm--8 {
    -ms-flex-order: -8;
    order: -8;
  }
  .Flex__flex-order-sm--7 {
    -ms-flex-order: -7;
    order: -7;
  }
  .Flex__flex-order-sm--6 {
    -ms-flex-order: -6;
    order: -6;
  }
  .Flex__flex-order-sm--5 {
    -ms-flex-order: -5;
    order: -5;
  }
  .Flex__flex-order-sm--4 {
    -ms-flex-order: -4;
    order: -4;
  }
  .Flex__flex-order-sm--3 {
    -ms-flex-order: -3;
    order: -3;
  }
  .Flex__flex-order-sm--2 {
    -ms-flex-order: -2;
    order: -2;
  }
  .Flex__flex-order-sm--1 {
    -ms-flex-order: -1;
    order: -1;
  }
  .Flex__flex-order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .Flex__flex-order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .Flex__flex-order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .Flex__flex-order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .Flex__flex-order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .Flex__flex-order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .Flex__flex-order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .Flex__flex-order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .Flex__flex-order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .Flex__flex-order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .Flex__flex-order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .Flex__flex-order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .Flex__flex-order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .Flex__flex-order-sm-13 {
    -ms-flex-order: 13;
    order: 13;
  }
  .Flex__flex-order-sm-14 {
    -ms-flex-order: 14;
    order: 14;
  }
  .Flex__flex-order-sm-15 {
    -ms-flex-order: 15;
    order: 15;
  }
  .Flex__flex-order-sm-16 {
    -ms-flex-order: 16;
    order: 16;
  }
  .Flex__flex-order-sm-17 {
    -ms-flex-order: 17;
    order: 17;
  }
  .Flex__flex-order-sm-18 {
    -ms-flex-order: 18;
    order: 18;
  }
  .Flex__flex-order-sm-19 {
    -ms-flex-order: 19;
    order: 19;
  }
  .Flex__flex-order-sm-20 {
    -ms-flex-order: 20;
    order: 20;
  }
  .Flex__offset-sm-0,
  .Flex__flex-offset-sm-0 {
    margin-left: 0;
  }
  [dir='rtl'] .Flex__offset-sm-0,
  [dir='rtl'] .Flex__flex-offset-sm-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .Flex__offset-sm-5,
  .Flex__flex-offset-sm-5 {
    margin-left: 5%;
  }
  [dir='rtl'] .Flex__offset-sm-5,
  [dir='rtl'] .Flex__flex-offset-sm-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .Flex__offset-sm-10,
  .Flex__flex-offset-sm-10 {
    margin-left: 10%;
  }
  [dir='rtl'] .Flex__offset-sm-10,
  [dir='rtl'] .Flex__flex-offset-sm-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .Flex__offset-sm-15,
  .Flex__flex-offset-sm-15 {
    margin-left: 15%;
  }
  [dir='rtl'] .Flex__offset-sm-15,
  [dir='rtl'] .Flex__flex-offset-sm-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .Flex__offset-sm-20,
  .Flex__flex-offset-sm-20 {
    margin-left: 20%;
  }
  [dir='rtl'] .Flex__offset-sm-20,
  [dir='rtl'] .Flex__flex-offset-sm-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .Flex__offset-sm-25,
  .Flex__flex-offset-sm-25 {
    margin-left: 25%;
  }
  [dir='rtl'] .Flex__offset-sm-25,
  [dir='rtl'] .Flex__flex-offset-sm-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .Flex__offset-sm-30,
  .Flex__flex-offset-sm-30 {
    margin-left: 30%;
  }
  [dir='rtl'] .Flex__offset-sm-30,
  [dir='rtl'] .Flex__flex-offset-sm-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .Flex__offset-sm-35,
  .Flex__flex-offset-sm-35 {
    margin-left: 35%;
  }
  [dir='rtl'] .Flex__offset-sm-35,
  [dir='rtl'] .Flex__flex-offset-sm-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .Flex__offset-sm-40,
  .Flex__flex-offset-sm-40 {
    margin-left: 40%;
  }
  [dir='rtl'] .Flex__offset-sm-40,
  [dir='rtl'] .Flex__flex-offset-sm-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .Flex__offset-sm-45,
  .Flex__flex-offset-sm-45 {
    margin-left: 45%;
  }
  [dir='rtl'] .Flex__offset-sm-45,
  [dir='rtl'] .Flex__flex-offset-sm-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .Flex__offset-sm-50,
  .Flex__flex-offset-sm-50 {
    margin-left: 50%;
  }
  [dir='rtl'] .Flex__offset-sm-50,
  [dir='rtl'] .Flex__flex-offset-sm-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .Flex__offset-sm-55,
  .Flex__flex-offset-sm-55 {
    margin-left: 55%;
  }
  [dir='rtl'] .Flex__offset-sm-55,
  [dir='rtl'] .Flex__flex-offset-sm-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .Flex__offset-sm-60,
  .Flex__flex-offset-sm-60 {
    margin-left: 60%;
  }
  [dir='rtl'] .Flex__offset-sm-60,
  [dir='rtl'] .Flex__flex-offset-sm-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .Flex__offset-sm-65,
  .Flex__flex-offset-sm-65 {
    margin-left: 65%;
  }
  [dir='rtl'] .Flex__offset-sm-65,
  [dir='rtl'] .Flex__flex-offset-sm-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .Flex__offset-sm-70,
  .Flex__flex-offset-sm-70 {
    margin-left: 70%;
  }
  [dir='rtl'] .Flex__offset-sm-70,
  [dir='rtl'] .Flex__flex-offset-sm-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .Flex__offset-sm-75,
  .Flex__flex-offset-sm-75 {
    margin-left: 75%;
  }
  [dir='rtl'] .Flex__offset-sm-75,
  [dir='rtl'] .Flex__flex-offset-sm-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .Flex__offset-sm-80,
  .Flex__flex-offset-sm-80 {
    margin-left: 80%;
  }
  [dir='rtl'] .Flex__offset-sm-80,
  [dir='rtl'] .Flex__flex-offset-sm-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .Flex__offset-sm-85,
  .Flex__flex-offset-sm-85 {
    margin-left: 85%;
  }
  [dir='rtl'] .Flex__offset-sm-85,
  [dir='rtl'] .Flex__flex-offset-sm-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .Flex__offset-sm-90,
  .Flex__flex-offset-sm-90 {
    margin-left: 90%;
  }
  [dir='rtl'] .Flex__offset-sm-90,
  [dir='rtl'] .Flex__flex-offset-sm-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .Flex__offset-sm-95,
  .Flex__flex-offset-sm-95 {
    margin-left: 95%;
  }
  [dir='rtl'] .Flex__offset-sm-95,
  [dir='rtl'] .Flex__flex-offset-sm-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .Flex__offset-sm-33,
  .Flex__flex-offset-sm-33 {
    margin-left: calc(100% / 3);
  }
  .Flex__offset-sm-66,
  .Flex__flex-offset-sm-66 {
    margin-left: calc(200% / 3);
  }
  [dir='rtl'] .Flex__offset-sm-66,
  [dir='rtl'] .Flex__flex-offset-sm-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }
  .Flex__layout-align-sm,
  .Flex__layout-align-sm-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .Flex__layout-align-sm-start,
  .Flex__layout-align-sm-start-start,
  .Flex__layout-align-sm-start-center,
  .Flex__layout-align-sm-start-end,
  .Flex__layout-align-sm-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .Flex__layout-align-sm-center,
  .Flex__layout-align-sm-center-start,
  .Flex__layout-align-sm-center-center,
  .Flex__layout-align-sm-center-end,
  .Flex__layout-align-sm-center-stretch {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .Flex__layout-align-sm-end,
  .Flex__layout-align-sm-end-start,
  .Flex__layout-align-sm-end-center,
  .Flex__layout-align-sm-end-end,
  .Flex__layout-align-sm-end-stretch {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .Flex__layout-align-sm-space-around,
  .Flex__layout-align-sm-space-around-center,
  .Flex__layout-align-sm-space-around-start,
  .Flex__layout-align-sm-space-around-end,
  .Flex__layout-align-sm-space-around-stretch {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .Flex__layout-align-sm-space-between,
  .Flex__layout-align-sm-space-between-center,
  .Flex__layout-align-sm-space-between-start,
  .Flex__layout-align-sm-space-between-end,
  .Flex__layout-align-sm-space-between-stretch {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .Flex__layout-align-sm-start-start,
  .Flex__layout-align-sm-center-start,
  .Flex__layout-align-sm-end-start,
  .Flex__layout-align-sm-space-between-start,
  .Flex__layout-align-sm-space-around-start {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .Flex__layout-align-sm-start-center,
  .Flex__layout-align-sm-center-center,
  .Flex__layout-align-sm-end-center,
  .Flex__layout-align-sm-space-between-center,
  .Flex__layout-align-sm-space-around-center {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    max-width: 100%;
  }
  .Flex__layout-align-sm-start-center > *,
  .Flex__layout-align-sm-center-center > *,
  .Flex__layout-align-sm-end-center > *,
  .Flex__layout-align-sm-space-between-center > *,
  .Flex__layout-align-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-align-sm-start-end,
  .Flex__layout-align-sm-center-end,
  .Flex__layout-align-sm-end-end,
  .Flex__layout-align-sm-space-between-end,
  .Flex__layout-align-sm-space-around-end {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .Flex__layout-align-sm-start-stretch,
  .Flex__layout-align-sm-center-stretch,
  .Flex__layout-align-sm-end-stretch,
  .Flex__layout-align-sm-space-between-stretch,
  .Flex__layout-align-sm-space-around-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .Flex__flex-sm {
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }
  .Flex__flex-sm-grow {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-initial {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-sm-auto {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-sm-none {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-sm-noshrink {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-sm-nogrow {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-sm-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-column > .Flex__flex-sm-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .Flex__flex-sm-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__flex-sm-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-33,
  .Flex__layout-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-sm-66,
  .Flex__layout-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-33,
  .Flex__layout-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-sm-66,
  .Flex__layout-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-33,
  .Flex__layout-sm-row > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex-sm-66,
  .Flex__layout-sm-row > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-row > .Flex__flex {
    min-width: 0;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-33,
  .Flex__layout-sm-column > .Flex__flex-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex-sm-66,
  .Flex__layout-sm-column > .Flex__flex-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-sm-column > .Flex__flex {
    min-height: 0;
  }
  .Flex__layout-sm,
  .Flex__layout-sm-column,
  .Flex__layout-sm-row {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
  }
  .Flex__layout-sm-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .Flex__layout-sm-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 960px) {
  .Flex__flex-order-gt-sm--20 {
    -ms-flex-order: -20;
    order: -20;
  }
  .Flex__flex-order-gt-sm--19 {
    -ms-flex-order: -19;
    order: -19;
  }
  .Flex__flex-order-gt-sm--18 {
    -ms-flex-order: -18;
    order: -18;
  }
  .Flex__flex-order-gt-sm--17 {
    -ms-flex-order: -17;
    order: -17;
  }
  .Flex__flex-order-gt-sm--16 {
    -ms-flex-order: -16;
    order: -16;
  }
  .Flex__flex-order-gt-sm--15 {
    -ms-flex-order: -15;
    order: -15;
  }
  .Flex__flex-order-gt-sm--14 {
    -ms-flex-order: -14;
    order: -14;
  }
  .Flex__flex-order-gt-sm--13 {
    -ms-flex-order: -13;
    order: -13;
  }
  .Flex__flex-order-gt-sm--12 {
    -ms-flex-order: -12;
    order: -12;
  }
  .Flex__flex-order-gt-sm--11 {
    -ms-flex-order: -11;
    order: -11;
  }
  .Flex__flex-order-gt-sm--10 {
    -ms-flex-order: -10;
    order: -10;
  }
  .Flex__flex-order-gt-sm--9 {
    -ms-flex-order: -9;
    order: -9;
  }
  .Flex__flex-order-gt-sm--8 {
    -ms-flex-order: -8;
    order: -8;
  }
  .Flex__flex-order-gt-sm--7 {
    -ms-flex-order: -7;
    order: -7;
  }
  .Flex__flex-order-gt-sm--6 {
    -ms-flex-order: -6;
    order: -6;
  }
  .Flex__flex-order-gt-sm--5 {
    -ms-flex-order: -5;
    order: -5;
  }
  .Flex__flex-order-gt-sm--4 {
    -ms-flex-order: -4;
    order: -4;
  }
  .Flex__flex-order-gt-sm--3 {
    -ms-flex-order: -3;
    order: -3;
  }
  .Flex__flex-order-gt-sm--2 {
    -ms-flex-order: -2;
    order: -2;
  }
  .Flex__flex-order-gt-sm--1 {
    -ms-flex-order: -1;
    order: -1;
  }
  .Flex__flex-order-gt-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .Flex__flex-order-gt-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .Flex__flex-order-gt-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .Flex__flex-order-gt-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .Flex__flex-order-gt-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .Flex__flex-order-gt-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .Flex__flex-order-gt-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .Flex__flex-order-gt-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .Flex__flex-order-gt-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .Flex__flex-order-gt-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .Flex__flex-order-gt-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .Flex__flex-order-gt-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .Flex__flex-order-gt-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .Flex__flex-order-gt-sm-13 {
    -ms-flex-order: 13;
    order: 13;
  }
  .Flex__flex-order-gt-sm-14 {
    -ms-flex-order: 14;
    order: 14;
  }
  .Flex__flex-order-gt-sm-15 {
    -ms-flex-order: 15;
    order: 15;
  }
  .Flex__flex-order-gt-sm-16 {
    -ms-flex-order: 16;
    order: 16;
  }
  .Flex__flex-order-gt-sm-17 {
    -ms-flex-order: 17;
    order: 17;
  }
  .Flex__flex-order-gt-sm-18 {
    -ms-flex-order: 18;
    order: 18;
  }
  .Flex__flex-order-gt-sm-19 {
    -ms-flex-order: 19;
    order: 19;
  }
  .Flex__flex-order-gt-sm-20 {
    -ms-flex-order: 20;
    order: 20;
  }
  .Flex__offset-gt-sm-0,
  .Flex__flex-offset-gt-sm-0 {
    margin-left: 0;
  }
  [dir='rtl'] .Flex__offset-gt-sm-0,
  [dir='rtl'] .Flex__flex-offset-gt-sm-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .Flex__offset-gt-sm-5,
  .Flex__flex-offset-gt-sm-5 {
    margin-left: 5%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-5,
  [dir='rtl'] .Flex__flex-offset-gt-sm-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .Flex__offset-gt-sm-10,
  .Flex__flex-offset-gt-sm-10 {
    margin-left: 10%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-10,
  [dir='rtl'] .Flex__flex-offset-gt-sm-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .Flex__offset-gt-sm-15,
  .Flex__flex-offset-gt-sm-15 {
    margin-left: 15%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-15,
  [dir='rtl'] .Flex__flex-offset-gt-sm-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .Flex__offset-gt-sm-20,
  .Flex__flex-offset-gt-sm-20 {
    margin-left: 20%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-20,
  [dir='rtl'] .Flex__flex-offset-gt-sm-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .Flex__offset-gt-sm-25,
  .Flex__flex-offset-gt-sm-25 {
    margin-left: 25%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-25,
  [dir='rtl'] .Flex__flex-offset-gt-sm-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .Flex__offset-gt-sm-30,
  .Flex__flex-offset-gt-sm-30 {
    margin-left: 30%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-30,
  [dir='rtl'] .Flex__flex-offset-gt-sm-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .Flex__offset-gt-sm-35,
  .Flex__flex-offset-gt-sm-35 {
    margin-left: 35%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-35,
  [dir='rtl'] .Flex__flex-offset-gt-sm-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .Flex__offset-gt-sm-40,
  .Flex__flex-offset-gt-sm-40 {
    margin-left: 40%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-40,
  [dir='rtl'] .Flex__flex-offset-gt-sm-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .Flex__offset-gt-sm-45,
  .Flex__flex-offset-gt-sm-45 {
    margin-left: 45%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-45,
  [dir='rtl'] .Flex__flex-offset-gt-sm-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .Flex__offset-gt-sm-50,
  .Flex__flex-offset-gt-sm-50 {
    margin-left: 50%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-50,
  [dir='rtl'] .Flex__flex-offset-gt-sm-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .Flex__offset-gt-sm-55,
  .Flex__flex-offset-gt-sm-55 {
    margin-left: 55%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-55,
  [dir='rtl'] .Flex__flex-offset-gt-sm-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .Flex__offset-gt-sm-60,
  .Flex__flex-offset-gt-sm-60 {
    margin-left: 60%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-60,
  [dir='rtl'] .Flex__flex-offset-gt-sm-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .Flex__offset-gt-sm-65,
  .Flex__flex-offset-gt-sm-65 {
    margin-left: 65%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-65,
  [dir='rtl'] .Flex__flex-offset-gt-sm-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .Flex__offset-gt-sm-70,
  .Flex__flex-offset-gt-sm-70 {
    margin-left: 70%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-70,
  [dir='rtl'] .Flex__flex-offset-gt-sm-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .Flex__offset-gt-sm-75,
  .Flex__flex-offset-gt-sm-75 {
    margin-left: 75%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-75,
  [dir='rtl'] .Flex__flex-offset-gt-sm-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .Flex__offset-gt-sm-80,
  .Flex__flex-offset-gt-sm-80 {
    margin-left: 80%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-80,
  [dir='rtl'] .Flex__flex-offset-gt-sm-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .Flex__offset-gt-sm-85,
  .Flex__flex-offset-gt-sm-85 {
    margin-left: 85%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-85,
  [dir='rtl'] .Flex__flex-offset-gt-sm-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .Flex__offset-gt-sm-90,
  .Flex__flex-offset-gt-sm-90 {
    margin-left: 90%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-90,
  [dir='rtl'] .Flex__flex-offset-gt-sm-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .Flex__offset-gt-sm-95,
  .Flex__flex-offset-gt-sm-95 {
    margin-left: 95%;
  }
  [dir='rtl'] .Flex__offset-gt-sm-95,
  [dir='rtl'] .Flex__flex-offset-gt-sm-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .Flex__offset-gt-sm-33,
  .Flex__flex-offset-gt-sm-33 {
    margin-left: calc(100% / 3);
  }
  .Flex__offset-gt-sm-66,
  .Flex__flex-offset-gt-sm-66 {
    margin-left: calc(200% / 3);
  }
  [dir='rtl'] .Flex__offset-gt-sm-66,
  [dir='rtl'] .Flex__flex-offset-gt-sm-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }
  .Flex__layout-align-gt-sm,
  .Flex__layout-align-gt-sm-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .Flex__layout-align-gt-sm-start,
  .Flex__layout-align-gt-sm-start-start,
  .Flex__layout-align-gt-sm-start-center,
  .Flex__layout-align-gt-sm-start-end,
  .Flex__layout-align-gt-sm-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .Flex__layout-align-gt-sm-center,
  .Flex__layout-align-gt-sm-center-start,
  .Flex__layout-align-gt-sm-center-center,
  .Flex__layout-align-gt-sm-center-end,
  .Flex__layout-align-gt-sm-center-stretch {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .Flex__layout-align-gt-sm-end,
  .Flex__layout-align-gt-sm-end-start,
  .Flex__layout-align-gt-sm-end-center,
  .Flex__layout-align-gt-sm-end-end,
  .Flex__layout-align-gt-sm-end-stretch {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .Flex__layout-align-gt-sm-space-around,
  .Flex__layout-align-gt-sm-space-around-center,
  .Flex__layout-align-gt-sm-space-around-start,
  .Flex__layout-align-gt-sm-space-around-end,
  .Flex__layout-align-gt-sm-space-around-stretch {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .Flex__layout-align-gt-sm-space-between,
  .Flex__layout-align-gt-sm-space-between-center,
  .Flex__layout-align-gt-sm-space-between-start,
  .Flex__layout-align-gt-sm-space-between-end,
  .Flex__layout-align-gt-sm-space-between-stretch {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .Flex__layout-align-gt-sm-start-start,
  .Flex__layout-align-gt-sm-center-start,
  .Flex__layout-align-gt-sm-end-start,
  .Flex__layout-align-gt-sm-space-between-start,
  .Flex__layout-align-gt-sm-space-around-start {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .Flex__layout-align-gt-sm-start-center,
  .Flex__layout-align-gt-sm-center-center,
  .Flex__layout-align-gt-sm-end-center,
  .Flex__layout-align-gt-sm-space-between-center,
  .Flex__layout-align-gt-sm-space-around-center {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    max-width: 100%;
  }
  .Flex__layout-align-gt-sm-start-center > *,
  .Flex__layout-align-gt-sm-center-center > *,
  .Flex__layout-align-gt-sm-end-center > *,
  .Flex__layout-align-gt-sm-space-between-center > *,
  .Flex__layout-align-gt-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-align-gt-sm-start-end,
  .Flex__layout-align-gt-sm-center-end,
  .Flex__layout-align-gt-sm-end-end,
  .Flex__layout-align-gt-sm-space-between-end,
  .Flex__layout-align-gt-sm-space-around-end {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .Flex__layout-align-gt-sm-start-stretch,
  .Flex__layout-align-gt-sm-center-stretch,
  .Flex__layout-align-gt-sm-end-stretch,
  .Flex__layout-align-gt-sm-space-between-stretch,
  .Flex__layout-align-gt-sm-space-around-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .Flex__flex-gt-sm {
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-grow {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-initial {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-auto {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-none {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-noshrink {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-nogrow {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .Flex__flex-gt-sm-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-sm-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-33,
  .Flex__layout-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-sm-66,
  .Flex__layout-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-33,
  .Flex__layout-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-sm-66,
  .Flex__layout-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-33,
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-66,
  .Flex__layout-gt-sm-row > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-row > .Flex__flex {
    min-width: 0;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-33,
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-66,
  .Flex__layout-gt-sm-column > .Flex__flex-gt-sm-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-sm-column > .Flex__flex {
    min-height: 0;
  }
  .Flex__layout-gt-sm,
  .Flex__layout-gt-sm-column,
  .Flex__layout-gt-sm-row {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
  }
  .Flex__layout-gt-sm-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .Flex__layout-gt-sm-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .Flex__hide:not(.Flex__show-gt-xs):not(.Flex__show-gt-sm):not(.Flex__show-md):not(.Flex__show),
  .Flex__hide-gt-xs:not(.Flex__show-gt-xs):not(.Flex__show-gt-sm):not(.Flex__show-md):not(.Flex__show),
  .Flex__hide-gt-sm:not(.Flex__show-gt-xs):not(.Flex__show-gt-sm):not(.Flex__show-md):not(.Flex__show) {
    display: none;
  }
  .Flex__hide-md:not(.Flex__show-md):not(.Flex__show-gt-sm):not(.Flex__show-gt-xs):not(.Flex__show) {
    display: none;
  }
  .Flex__flex-order-md--20 {
    -ms-flex-order: -20;
    order: -20;
  }
  .Flex__flex-order-md--19 {
    -ms-flex-order: -19;
    order: -19;
  }
  .Flex__flex-order-md--18 {
    -ms-flex-order: -18;
    order: -18;
  }
  .Flex__flex-order-md--17 {
    -ms-flex-order: -17;
    order: -17;
  }
  .Flex__flex-order-md--16 {
    -ms-flex-order: -16;
    order: -16;
  }
  .Flex__flex-order-md--15 {
    -ms-flex-order: -15;
    order: -15;
  }
  .Flex__flex-order-md--14 {
    -ms-flex-order: -14;
    order: -14;
  }
  .Flex__flex-order-md--13 {
    -ms-flex-order: -13;
    order: -13;
  }
  .Flex__flex-order-md--12 {
    -ms-flex-order: -12;
    order: -12;
  }
  .Flex__flex-order-md--11 {
    -ms-flex-order: -11;
    order: -11;
  }
  .Flex__flex-order-md--10 {
    -ms-flex-order: -10;
    order: -10;
  }
  .Flex__flex-order-md--9 {
    -ms-flex-order: -9;
    order: -9;
  }
  .Flex__flex-order-md--8 {
    -ms-flex-order: -8;
    order: -8;
  }
  .Flex__flex-order-md--7 {
    -ms-flex-order: -7;
    order: -7;
  }
  .Flex__flex-order-md--6 {
    -ms-flex-order: -6;
    order: -6;
  }
  .Flex__flex-order-md--5 {
    -ms-flex-order: -5;
    order: -5;
  }
  .Flex__flex-order-md--4 {
    -ms-flex-order: -4;
    order: -4;
  }
  .Flex__flex-order-md--3 {
    -ms-flex-order: -3;
    order: -3;
  }
  .Flex__flex-order-md--2 {
    -ms-flex-order: -2;
    order: -2;
  }
  .Flex__flex-order-md--1 {
    -ms-flex-order: -1;
    order: -1;
  }
  .Flex__flex-order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .Flex__flex-order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .Flex__flex-order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .Flex__flex-order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .Flex__flex-order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .Flex__flex-order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .Flex__flex-order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .Flex__flex-order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .Flex__flex-order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .Flex__flex-order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .Flex__flex-order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .Flex__flex-order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .Flex__flex-order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .Flex__flex-order-md-13 {
    -ms-flex-order: 13;
    order: 13;
  }
  .Flex__flex-order-md-14 {
    -ms-flex-order: 14;
    order: 14;
  }
  .Flex__flex-order-md-15 {
    -ms-flex-order: 15;
    order: 15;
  }
  .Flex__flex-order-md-16 {
    -ms-flex-order: 16;
    order: 16;
  }
  .Flex__flex-order-md-17 {
    -ms-flex-order: 17;
    order: 17;
  }
  .Flex__flex-order-md-18 {
    -ms-flex-order: 18;
    order: 18;
  }
  .Flex__flex-order-md-19 {
    -ms-flex-order: 19;
    order: 19;
  }
  .Flex__flex-order-md-20 {
    -ms-flex-order: 20;
    order: 20;
  }
  .Flex__offset-md-0,
  .Flex__flex-offset-md-0 {
    margin-left: 0;
  }
  [dir='rtl'] .Flex__offset-md-0,
  [dir='rtl'] .Flex__flex-offset-md-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .Flex__offset-md-5,
  .Flex__flex-offset-md-5 {
    margin-left: 5%;
  }
  [dir='rtl'] .Flex__offset-md-5,
  [dir='rtl'] .Flex__flex-offset-md-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .Flex__offset-md-10,
  .Flex__flex-offset-md-10 {
    margin-left: 10%;
  }
  [dir='rtl'] .Flex__offset-md-10,
  [dir='rtl'] .Flex__flex-offset-md-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .Flex__offset-md-15,
  .Flex__flex-offset-md-15 {
    margin-left: 15%;
  }
  [dir='rtl'] .Flex__offset-md-15,
  [dir='rtl'] .Flex__flex-offset-md-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .Flex__offset-md-20,
  .Flex__flex-offset-md-20 {
    margin-left: 20%;
  }
  [dir='rtl'] .Flex__offset-md-20,
  [dir='rtl'] .Flex__flex-offset-md-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .Flex__offset-md-25,
  .Flex__flex-offset-md-25 {
    margin-left: 25%;
  }
  [dir='rtl'] .Flex__offset-md-25,
  [dir='rtl'] .Flex__flex-offset-md-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .Flex__offset-md-30,
  .Flex__flex-offset-md-30 {
    margin-left: 30%;
  }
  [dir='rtl'] .Flex__offset-md-30,
  [dir='rtl'] .Flex__flex-offset-md-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .Flex__offset-md-35,
  .Flex__flex-offset-md-35 {
    margin-left: 35%;
  }
  [dir='rtl'] .Flex__offset-md-35,
  [dir='rtl'] .Flex__flex-offset-md-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .Flex__offset-md-40,
  .Flex__flex-offset-md-40 {
    margin-left: 40%;
  }
  [dir='rtl'] .Flex__offset-md-40,
  [dir='rtl'] .Flex__flex-offset-md-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .Flex__offset-md-45,
  .Flex__flex-offset-md-45 {
    margin-left: 45%;
  }
  [dir='rtl'] .Flex__offset-md-45,
  [dir='rtl'] .Flex__flex-offset-md-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .Flex__offset-md-50,
  .Flex__flex-offset-md-50 {
    margin-left: 50%;
  }
  [dir='rtl'] .Flex__offset-md-50,
  [dir='rtl'] .Flex__flex-offset-md-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .Flex__offset-md-55,
  .Flex__flex-offset-md-55 {
    margin-left: 55%;
  }
  [dir='rtl'] .Flex__offset-md-55,
  [dir='rtl'] .Flex__flex-offset-md-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .Flex__offset-md-60,
  .Flex__flex-offset-md-60 {
    margin-left: 60%;
  }
  [dir='rtl'] .Flex__offset-md-60,
  [dir='rtl'] .Flex__flex-offset-md-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .Flex__offset-md-65,
  .Flex__flex-offset-md-65 {
    margin-left: 65%;
  }
  [dir='rtl'] .Flex__offset-md-65,
  [dir='rtl'] .Flex__flex-offset-md-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .Flex__offset-md-70,
  .Flex__flex-offset-md-70 {
    margin-left: 70%;
  }
  [dir='rtl'] .Flex__offset-md-70,
  [dir='rtl'] .Flex__flex-offset-md-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .Flex__offset-md-75,
  .Flex__flex-offset-md-75 {
    margin-left: 75%;
  }
  [dir='rtl'] .Flex__offset-md-75,
  [dir='rtl'] .Flex__flex-offset-md-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .Flex__offset-md-80,
  .Flex__flex-offset-md-80 {
    margin-left: 80%;
  }
  [dir='rtl'] .Flex__offset-md-80,
  [dir='rtl'] .Flex__flex-offset-md-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .Flex__offset-md-85,
  .Flex__flex-offset-md-85 {
    margin-left: 85%;
  }
  [dir='rtl'] .Flex__offset-md-85,
  [dir='rtl'] .Flex__flex-offset-md-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .Flex__offset-md-90,
  .Flex__flex-offset-md-90 {
    margin-left: 90%;
  }
  [dir='rtl'] .Flex__offset-md-90,
  [dir='rtl'] .Flex__flex-offset-md-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .Flex__offset-md-95,
  .Flex__flex-offset-md-95 {
    margin-left: 95%;
  }
  [dir='rtl'] .Flex__offset-md-95,
  [dir='rtl'] .Flex__flex-offset-md-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .Flex__offset-md-33,
  .Flex__flex-offset-md-33 {
    margin-left: calc(100% / 3);
  }
  .Flex__offset-md-66,
  .Flex__flex-offset-md-66 {
    margin-left: calc(200% / 3);
  }
  [dir='rtl'] .Flex__offset-md-66,
  [dir='rtl'] .Flex__flex-offset-md-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }
  .Flex__layout-align-md,
  .Flex__layout-align-md-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .Flex__layout-align-md-start,
  .Flex__layout-align-md-start-start,
  .Flex__layout-align-md-start-center,
  .Flex__layout-align-md-start-end,
  .Flex__layout-align-md-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .Flex__layout-align-md-center,
  .Flex__layout-align-md-center-start,
  .Flex__layout-align-md-center-center,
  .Flex__layout-align-md-center-end,
  .Flex__layout-align-md-center-stretch {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .Flex__layout-align-md-end,
  .Flex__layout-align-md-end-start,
  .Flex__layout-align-md-end-center,
  .Flex__layout-align-md-end-end,
  .Flex__layout-align-md-end-stretch {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .Flex__layout-align-md-space-around,
  .Flex__layout-align-md-space-around-center,
  .Flex__layout-align-md-space-around-start,
  .Flex__layout-align-md-space-around-end,
  .Flex__layout-align-md-space-around-stretch {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .Flex__layout-align-md-space-between,
  .Flex__layout-align-md-space-between-center,
  .Flex__layout-align-md-space-between-start,
  .Flex__layout-align-md-space-between-end,
  .Flex__layout-align-md-space-between-stretch {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .Flex__layout-align-md-start-start,
  .Flex__layout-align-md-center-start,
  .Flex__layout-align-md-end-start,
  .Flex__layout-align-md-space-between-start,
  .Flex__layout-align-md-space-around-start {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .Flex__layout-align-md-start-center,
  .Flex__layout-align-md-center-center,
  .Flex__layout-align-md-end-center,
  .Flex__layout-align-md-space-between-center,
  .Flex__layout-align-md-space-around-center {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    max-width: 100%;
  }
  .Flex__layout-align-md-start-center > *,
  .Flex__layout-align-md-center-center > *,
  .Flex__layout-align-md-end-center > *,
  .Flex__layout-align-md-space-between-center > *,
  .Flex__layout-align-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-align-md-start-end,
  .Flex__layout-align-md-center-end,
  .Flex__layout-align-md-end-end,
  .Flex__layout-align-md-space-between-end,
  .Flex__layout-align-md-space-around-end {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .Flex__layout-align-md-start-stretch,
  .Flex__layout-align-md-center-stretch,
  .Flex__layout-align-md-end-stretch,
  .Flex__layout-align-md-space-between-stretch,
  .Flex__layout-align-md-space-around-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .Flex__flex-md {
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }
  .Flex__flex-md-grow {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .Flex__flex-md-initial {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-md-auto {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-md-none {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-md-noshrink {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-md-nogrow {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-md-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-column > .Flex__flex-md-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-md-column > .Flex__flex-md-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .Flex__flex-md-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__flex-md-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__flex-md-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__flex-md-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__flex-md-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__flex-md-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__flex-md-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__flex-md-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__flex-md-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__flex-md-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__flex-md-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__flex-md-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__flex-md-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__flex-md-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__flex-md-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__flex-md-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__flex-md-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__flex-md-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__flex-md-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__flex-md-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-33,
  .Flex__layout-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-md-66,
  .Flex__layout-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-33,
  .Flex__layout-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-md-66,
  .Flex__layout-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-33,
  .Flex__layout-md-row > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex-md-66,
  .Flex__layout-md-row > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-md-row > .Flex__flex {
    min-width: 0;
  }
  .Flex__layout-md-column > .Flex__flex-md-33,
  .Flex__layout-md-column > .Flex__flex-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex-md-66,
  .Flex__layout-md-column > .Flex__flex-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-md-column > .Flex__flex {
    min-height: 0;
  }
  .Flex__layout-md,
  .Flex__layout-md-column,
  .Flex__layout-md-row {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
  }
  .Flex__layout-md-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .Flex__layout-md-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 1280px) {
  .Flex__flex-order-gt-md--20 {
    -ms-flex-order: -20;
    order: -20;
  }
  .Flex__flex-order-gt-md--19 {
    -ms-flex-order: -19;
    order: -19;
  }
  .Flex__flex-order-gt-md--18 {
    -ms-flex-order: -18;
    order: -18;
  }
  .Flex__flex-order-gt-md--17 {
    -ms-flex-order: -17;
    order: -17;
  }
  .Flex__flex-order-gt-md--16 {
    -ms-flex-order: -16;
    order: -16;
  }
  .Flex__flex-order-gt-md--15 {
    -ms-flex-order: -15;
    order: -15;
  }
  .Flex__flex-order-gt-md--14 {
    -ms-flex-order: -14;
    order: -14;
  }
  .Flex__flex-order-gt-md--13 {
    -ms-flex-order: -13;
    order: -13;
  }
  .Flex__flex-order-gt-md--12 {
    -ms-flex-order: -12;
    order: -12;
  }
  .Flex__flex-order-gt-md--11 {
    -ms-flex-order: -11;
    order: -11;
  }
  .Flex__flex-order-gt-md--10 {
    -ms-flex-order: -10;
    order: -10;
  }
  .Flex__flex-order-gt-md--9 {
    -ms-flex-order: -9;
    order: -9;
  }
  .Flex__flex-order-gt-md--8 {
    -ms-flex-order: -8;
    order: -8;
  }
  .Flex__flex-order-gt-md--7 {
    -ms-flex-order: -7;
    order: -7;
  }
  .Flex__flex-order-gt-md--6 {
    -ms-flex-order: -6;
    order: -6;
  }
  .Flex__flex-order-gt-md--5 {
    -ms-flex-order: -5;
    order: -5;
  }
  .Flex__flex-order-gt-md--4 {
    -ms-flex-order: -4;
    order: -4;
  }
  .Flex__flex-order-gt-md--3 {
    -ms-flex-order: -3;
    order: -3;
  }
  .Flex__flex-order-gt-md--2 {
    -ms-flex-order: -2;
    order: -2;
  }
  .Flex__flex-order-gt-md--1 {
    -ms-flex-order: -1;
    order: -1;
  }
  .Flex__flex-order-gt-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .Flex__flex-order-gt-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .Flex__flex-order-gt-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .Flex__flex-order-gt-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .Flex__flex-order-gt-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .Flex__flex-order-gt-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .Flex__flex-order-gt-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .Flex__flex-order-gt-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .Flex__flex-order-gt-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .Flex__flex-order-gt-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .Flex__flex-order-gt-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .Flex__flex-order-gt-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .Flex__flex-order-gt-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .Flex__flex-order-gt-md-13 {
    -ms-flex-order: 13;
    order: 13;
  }
  .Flex__flex-order-gt-md-14 {
    -ms-flex-order: 14;
    order: 14;
  }
  .Flex__flex-order-gt-md-15 {
    -ms-flex-order: 15;
    order: 15;
  }
  .Flex__flex-order-gt-md-16 {
    -ms-flex-order: 16;
    order: 16;
  }
  .Flex__flex-order-gt-md-17 {
    -ms-flex-order: 17;
    order: 17;
  }
  .Flex__flex-order-gt-md-18 {
    -ms-flex-order: 18;
    order: 18;
  }
  .Flex__flex-order-gt-md-19 {
    -ms-flex-order: 19;
    order: 19;
  }
  .Flex__flex-order-gt-md-20 {
    -ms-flex-order: 20;
    order: 20;
  }
  .Flex__offset-gt-md-0,
  .Flex__flex-offset-gt-md-0 {
    margin-left: 0;
  }
  [dir='rtl'] .Flex__offset-gt-md-0,
  [dir='rtl'] .Flex__flex-offset-gt-md-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .Flex__offset-gt-md-5,
  .Flex__flex-offset-gt-md-5 {
    margin-left: 5%;
  }
  [dir='rtl'] .Flex__offset-gt-md-5,
  [dir='rtl'] .Flex__flex-offset-gt-md-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .Flex__offset-gt-md-10,
  .Flex__flex-offset-gt-md-10 {
    margin-left: 10%;
  }
  [dir='rtl'] .Flex__offset-gt-md-10,
  [dir='rtl'] .Flex__flex-offset-gt-md-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .Flex__offset-gt-md-15,
  .Flex__flex-offset-gt-md-15 {
    margin-left: 15%;
  }
  [dir='rtl'] .Flex__offset-gt-md-15,
  [dir='rtl'] .Flex__flex-offset-gt-md-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .Flex__offset-gt-md-20,
  .Flex__flex-offset-gt-md-20 {
    margin-left: 20%;
  }
  [dir='rtl'] .Flex__offset-gt-md-20,
  [dir='rtl'] .Flex__flex-offset-gt-md-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .Flex__offset-gt-md-25,
  .Flex__flex-offset-gt-md-25 {
    margin-left: 25%;
  }
  [dir='rtl'] .Flex__offset-gt-md-25,
  [dir='rtl'] .Flex__flex-offset-gt-md-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .Flex__offset-gt-md-30,
  .Flex__flex-offset-gt-md-30 {
    margin-left: 30%;
  }
  [dir='rtl'] .Flex__offset-gt-md-30,
  [dir='rtl'] .Flex__flex-offset-gt-md-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .Flex__offset-gt-md-35,
  .Flex__flex-offset-gt-md-35 {
    margin-left: 35%;
  }
  [dir='rtl'] .Flex__offset-gt-md-35,
  [dir='rtl'] .Flex__flex-offset-gt-md-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .Flex__offset-gt-md-40,
  .Flex__flex-offset-gt-md-40 {
    margin-left: 40%;
  }
  [dir='rtl'] .Flex__offset-gt-md-40,
  [dir='rtl'] .Flex__flex-offset-gt-md-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .Flex__offset-gt-md-45,
  .Flex__flex-offset-gt-md-45 {
    margin-left: 45%;
  }
  [dir='rtl'] .Flex__offset-gt-md-45,
  [dir='rtl'] .Flex__flex-offset-gt-md-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .Flex__offset-gt-md-50,
  .Flex__flex-offset-gt-md-50 {
    margin-left: 50%;
  }
  [dir='rtl'] .Flex__offset-gt-md-50,
  [dir='rtl'] .Flex__flex-offset-gt-md-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .Flex__offset-gt-md-55,
  .Flex__flex-offset-gt-md-55 {
    margin-left: 55%;
  }
  [dir='rtl'] .Flex__offset-gt-md-55,
  [dir='rtl'] .Flex__flex-offset-gt-md-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .Flex__offset-gt-md-60,
  .Flex__flex-offset-gt-md-60 {
    margin-left: 60%;
  }
  [dir='rtl'] .Flex__offset-gt-md-60,
  [dir='rtl'] .Flex__flex-offset-gt-md-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .Flex__offset-gt-md-65,
  .Flex__flex-offset-gt-md-65 {
    margin-left: 65%;
  }
  [dir='rtl'] .Flex__offset-gt-md-65,
  [dir='rtl'] .Flex__flex-offset-gt-md-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .Flex__offset-gt-md-70,
  .Flex__flex-offset-gt-md-70 {
    margin-left: 70%;
  }
  [dir='rtl'] .Flex__offset-gt-md-70,
  [dir='rtl'] .Flex__flex-offset-gt-md-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .Flex__offset-gt-md-75,
  .Flex__flex-offset-gt-md-75 {
    margin-left: 75%;
  }
  [dir='rtl'] .Flex__offset-gt-md-75,
  [dir='rtl'] .Flex__flex-offset-gt-md-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .Flex__offset-gt-md-80,
  .Flex__flex-offset-gt-md-80 {
    margin-left: 80%;
  }
  [dir='rtl'] .Flex__offset-gt-md-80,
  [dir='rtl'] .Flex__flex-offset-gt-md-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .Flex__offset-gt-md-85,
  .Flex__flex-offset-gt-md-85 {
    margin-left: 85%;
  }
  [dir='rtl'] .Flex__offset-gt-md-85,
  [dir='rtl'] .Flex__flex-offset-gt-md-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .Flex__offset-gt-md-90,
  .Flex__flex-offset-gt-md-90 {
    margin-left: 90%;
  }
  [dir='rtl'] .Flex__offset-gt-md-90,
  [dir='rtl'] .Flex__flex-offset-gt-md-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .Flex__offset-gt-md-95,
  .Flex__flex-offset-gt-md-95 {
    margin-left: 95%;
  }
  [dir='rtl'] .Flex__offset-gt-md-95,
  [dir='rtl'] .Flex__flex-offset-gt-md-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .Flex__offset-gt-md-33,
  .Flex__flex-offset-gt-md-33 {
    margin-left: calc(100% / 3);
  }
  .Flex__offset-gt-md-66,
  .Flex__flex-offset-gt-md-66 {
    margin-left: calc(200% / 3);
  }
  [dir='rtl'] .Flex__offset-gt-md-66,
  [dir='rtl'] .Flex__flex-offset-gt-md-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }
  .Flex__layout-align-gt-md,
  .Flex__layout-align-gt-md-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .Flex__layout-align-gt-md-start,
  .Flex__layout-align-gt-md-start-start,
  .Flex__layout-align-gt-md-start-center,
  .Flex__layout-align-gt-md-start-end,
  .Flex__layout-align-gt-md-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .Flex__layout-align-gt-md-center,
  .Flex__layout-align-gt-md-center-start,
  .Flex__layout-align-gt-md-center-center,
  .Flex__layout-align-gt-md-center-end,
  .Flex__layout-align-gt-md-center-stretch {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .Flex__layout-align-gt-md-end,
  .Flex__layout-align-gt-md-end-start,
  .Flex__layout-align-gt-md-end-center,
  .Flex__layout-align-gt-md-end-end,
  .Flex__layout-align-gt-md-end-stretch {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .Flex__layout-align-gt-md-space-around,
  .Flex__layout-align-gt-md-space-around-center,
  .Flex__layout-align-gt-md-space-around-start,
  .Flex__layout-align-gt-md-space-around-end,
  .Flex__layout-align-gt-md-space-around-stretch {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .Flex__layout-align-gt-md-space-between,
  .Flex__layout-align-gt-md-space-between-center,
  .Flex__layout-align-gt-md-space-between-start,
  .Flex__layout-align-gt-md-space-between-end,
  .Flex__layout-align-gt-md-space-between-stretch {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .Flex__layout-align-gt-md-start-start,
  .Flex__layout-align-gt-md-center-start,
  .Flex__layout-align-gt-md-end-start,
  .Flex__layout-align-gt-md-space-between-start,
  .Flex__layout-align-gt-md-space-around-start {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .Flex__layout-align-gt-md-start-center,
  .Flex__layout-align-gt-md-center-center,
  .Flex__layout-align-gt-md-end-center,
  .Flex__layout-align-gt-md-space-between-center,
  .Flex__layout-align-gt-md-space-around-center {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    max-width: 100%;
  }
  .Flex__layout-align-gt-md-start-center > *,
  .Flex__layout-align-gt-md-center-center > *,
  .Flex__layout-align-gt-md-end-center > *,
  .Flex__layout-align-gt-md-space-between-center > *,
  .Flex__layout-align-gt-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-align-gt-md-start-end,
  .Flex__layout-align-gt-md-center-end,
  .Flex__layout-align-gt-md-end-end,
  .Flex__layout-align-gt-md-space-between-end,
  .Flex__layout-align-gt-md-space-around-end {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .Flex__layout-align-gt-md-start-stretch,
  .Flex__layout-align-gt-md-center-stretch,
  .Flex__layout-align-gt-md-end-stretch,
  .Flex__layout-align-gt-md-space-between-stretch,
  .Flex__layout-align-gt-md-space-around-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .Flex__flex-gt-md {
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-grow {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-initial {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-auto {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-none {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-noshrink {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-nogrow {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-column > .Flex__flex-gt-md-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .Flex__flex-gt-md-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-md-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-33,
  .Flex__layout-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-md-66,
  .Flex__layout-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-33,
  .Flex__layout-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-md-66,
  .Flex__layout-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-33,
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-66,
  .Flex__layout-gt-md-row > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-row > .Flex__flex {
    min-width: 0;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-33,
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-66,
  .Flex__layout-gt-md-column > .Flex__flex-gt-md-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-md-column > .Flex__flex {
    min-height: 0;
  }
  .Flex__layout-gt-md,
  .Flex__layout-gt-md-column,
  .Flex__layout-gt-md-row {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
  }
  .Flex__layout-gt-md-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .Flex__layout-gt-md-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .Flex__hide:not(.Flex__show-gt-xs):not(.Flex__show-gt-sm):not(.Flex__show-gt-md):not(.Flex__show-lg):not(.Flex__show),
  .Flex__hide-gt-xs:not(.Flex__show-gt-xs):not(.Flex__show-gt-sm):not(.Flex__show-gt-md):not(.Flex__show-lg):not(.Flex__show),
  .Flex__hide-gt-sm:not(.Flex__show-gt-xs):not(.Flex__show-gt-sm):not(.Flex__show-gt-md):not(.Flex__show-lg):not(.Flex__show),
  .Flex__hide-gt-md:not(.Flex__show-gt-xs):not(.Flex__show-gt-sm):not(.Flex__show-gt-md):not(.Flex__show-lg):not(.Flex__show) {
    display: none;
  }
  .Flex__hide-lg:not(.Flex__show-lg):not(.Flex__show-gt-md):not(.Flex__show-gt-sm):not(.Flex__show-gt-xs):not(.Flex__show) {
    display: none;
  }
  .Flex__flex-order-lg--20 {
    -ms-flex-order: -20;
    order: -20;
  }
  .Flex__flex-order-lg--19 {
    -ms-flex-order: -19;
    order: -19;
  }
  .Flex__flex-order-lg--18 {
    -ms-flex-order: -18;
    order: -18;
  }
  .Flex__flex-order-lg--17 {
    -ms-flex-order: -17;
    order: -17;
  }
  .Flex__flex-order-lg--16 {
    -ms-flex-order: -16;
    order: -16;
  }
  .Flex__flex-order-lg--15 {
    -ms-flex-order: -15;
    order: -15;
  }
  .Flex__flex-order-lg--14 {
    -ms-flex-order: -14;
    order: -14;
  }
  .Flex__flex-order-lg--13 {
    -ms-flex-order: -13;
    order: -13;
  }
  .Flex__flex-order-lg--12 {
    -ms-flex-order: -12;
    order: -12;
  }
  .Flex__flex-order-lg--11 {
    -ms-flex-order: -11;
    order: -11;
  }
  .Flex__flex-order-lg--10 {
    -ms-flex-order: -10;
    order: -10;
  }
  .Flex__flex-order-lg--9 {
    -ms-flex-order: -9;
    order: -9;
  }
  .Flex__flex-order-lg--8 {
    -ms-flex-order: -8;
    order: -8;
  }
  .Flex__flex-order-lg--7 {
    -ms-flex-order: -7;
    order: -7;
  }
  .Flex__flex-order-lg--6 {
    -ms-flex-order: -6;
    order: -6;
  }
  .Flex__flex-order-lg--5 {
    -ms-flex-order: -5;
    order: -5;
  }
  .Flex__flex-order-lg--4 {
    -ms-flex-order: -4;
    order: -4;
  }
  .Flex__flex-order-lg--3 {
    -ms-flex-order: -3;
    order: -3;
  }
  .Flex__flex-order-lg--2 {
    -ms-flex-order: -2;
    order: -2;
  }
  .Flex__flex-order-lg--1 {
    -ms-flex-order: -1;
    order: -1;
  }
  .Flex__flex-order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .Flex__flex-order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .Flex__flex-order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .Flex__flex-order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .Flex__flex-order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .Flex__flex-order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .Flex__flex-order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .Flex__flex-order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .Flex__flex-order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .Flex__flex-order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .Flex__flex-order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .Flex__flex-order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .Flex__flex-order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .Flex__flex-order-lg-13 {
    -ms-flex-order: 13;
    order: 13;
  }
  .Flex__flex-order-lg-14 {
    -ms-flex-order: 14;
    order: 14;
  }
  .Flex__flex-order-lg-15 {
    -ms-flex-order: 15;
    order: 15;
  }
  .Flex__flex-order-lg-16 {
    -ms-flex-order: 16;
    order: 16;
  }
  .Flex__flex-order-lg-17 {
    -ms-flex-order: 17;
    order: 17;
  }
  .Flex__flex-order-lg-18 {
    -ms-flex-order: 18;
    order: 18;
  }
  .Flex__flex-order-lg-19 {
    -ms-flex-order: 19;
    order: 19;
  }
  .Flex__flex-order-lg-20 {
    -ms-flex-order: 20;
    order: 20;
  }
  .Flex__offset-lg-0,
  .Flex__flex-offset-lg-0 {
    margin-left: 0;
  }
  [dir='rtl'] .Flex__offset-lg-0,
  [dir='rtl'] .Flex__flex-offset-lg-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .Flex__offset-lg-5,
  .Flex__flex-offset-lg-5 {
    margin-left: 5%;
  }
  [dir='rtl'] .Flex__offset-lg-5,
  [dir='rtl'] .Flex__flex-offset-lg-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .Flex__offset-lg-10,
  .Flex__flex-offset-lg-10 {
    margin-left: 10%;
  }
  [dir='rtl'] .Flex__offset-lg-10,
  [dir='rtl'] .Flex__flex-offset-lg-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .Flex__offset-lg-15,
  .Flex__flex-offset-lg-15 {
    margin-left: 15%;
  }
  [dir='rtl'] .Flex__offset-lg-15,
  [dir='rtl'] .Flex__flex-offset-lg-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .Flex__offset-lg-20,
  .Flex__flex-offset-lg-20 {
    margin-left: 20%;
  }
  [dir='rtl'] .Flex__offset-lg-20,
  [dir='rtl'] .Flex__flex-offset-lg-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .Flex__offset-lg-25,
  .Flex__flex-offset-lg-25 {
    margin-left: 25%;
  }
  [dir='rtl'] .Flex__offset-lg-25,
  [dir='rtl'] .Flex__flex-offset-lg-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .Flex__offset-lg-30,
  .Flex__flex-offset-lg-30 {
    margin-left: 30%;
  }
  [dir='rtl'] .Flex__offset-lg-30,
  [dir='rtl'] .Flex__flex-offset-lg-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .Flex__offset-lg-35,
  .Flex__flex-offset-lg-35 {
    margin-left: 35%;
  }
  [dir='rtl'] .Flex__offset-lg-35,
  [dir='rtl'] .Flex__flex-offset-lg-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .Flex__offset-lg-40,
  .Flex__flex-offset-lg-40 {
    margin-left: 40%;
  }
  [dir='rtl'] .Flex__offset-lg-40,
  [dir='rtl'] .Flex__flex-offset-lg-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .Flex__offset-lg-45,
  .Flex__flex-offset-lg-45 {
    margin-left: 45%;
  }
  [dir='rtl'] .Flex__offset-lg-45,
  [dir='rtl'] .Flex__flex-offset-lg-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .Flex__offset-lg-50,
  .Flex__flex-offset-lg-50 {
    margin-left: 50%;
  }
  [dir='rtl'] .Flex__offset-lg-50,
  [dir='rtl'] .Flex__flex-offset-lg-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .Flex__offset-lg-55,
  .Flex__flex-offset-lg-55 {
    margin-left: 55%;
  }
  [dir='rtl'] .Flex__offset-lg-55,
  [dir='rtl'] .Flex__flex-offset-lg-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .Flex__offset-lg-60,
  .Flex__flex-offset-lg-60 {
    margin-left: 60%;
  }
  [dir='rtl'] .Flex__offset-lg-60,
  [dir='rtl'] .Flex__flex-offset-lg-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .Flex__offset-lg-65,
  .Flex__flex-offset-lg-65 {
    margin-left: 65%;
  }
  [dir='rtl'] .Flex__offset-lg-65,
  [dir='rtl'] .Flex__flex-offset-lg-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .Flex__offset-lg-70,
  .Flex__flex-offset-lg-70 {
    margin-left: 70%;
  }
  [dir='rtl'] .Flex__offset-lg-70,
  [dir='rtl'] .Flex__flex-offset-lg-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .Flex__offset-lg-75,
  .Flex__flex-offset-lg-75 {
    margin-left: 75%;
  }
  [dir='rtl'] .Flex__offset-lg-75,
  [dir='rtl'] .Flex__flex-offset-lg-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .Flex__offset-lg-80,
  .Flex__flex-offset-lg-80 {
    margin-left: 80%;
  }
  [dir='rtl'] .Flex__offset-lg-80,
  [dir='rtl'] .Flex__flex-offset-lg-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .Flex__offset-lg-85,
  .Flex__flex-offset-lg-85 {
    margin-left: 85%;
  }
  [dir='rtl'] .Flex__offset-lg-85,
  [dir='rtl'] .Flex__flex-offset-lg-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .Flex__offset-lg-90,
  .Flex__flex-offset-lg-90 {
    margin-left: 90%;
  }
  [dir='rtl'] .Flex__offset-lg-90,
  [dir='rtl'] .Flex__flex-offset-lg-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .Flex__offset-lg-95,
  .Flex__flex-offset-lg-95 {
    margin-left: 95%;
  }
  [dir='rtl'] .Flex__offset-lg-95,
  [dir='rtl'] .Flex__flex-offset-lg-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .Flex__offset-lg-33,
  .Flex__flex-offset-lg-33 {
    margin-left: calc(100% / 3);
  }
  .Flex__offset-lg-66,
  .Flex__flex-offset-lg-66 {
    margin-left: calc(200% / 3);
  }
  [dir='rtl'] .Flex__offset-lg-66,
  [dir='rtl'] .Flex__flex-offset-lg-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }
  .Flex__layout-align-lg,
  .Flex__layout-align-lg-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .Flex__layout-align-lg-start,
  .Flex__layout-align-lg-start-start,
  .Flex__layout-align-lg-start-center,
  .Flex__layout-align-lg-start-end,
  .Flex__layout-align-lg-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .Flex__layout-align-lg-center,
  .Flex__layout-align-lg-center-start,
  .Flex__layout-align-lg-center-center,
  .Flex__layout-align-lg-center-end,
  .Flex__layout-align-lg-center-stretch {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .Flex__layout-align-lg-end,
  .Flex__layout-align-lg-end-start,
  .Flex__layout-align-lg-end-center,
  .Flex__layout-align-lg-end-end,
  .Flex__layout-align-lg-end-stretch {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .Flex__layout-align-lg-space-around,
  .Flex__layout-align-lg-space-around-center,
  .Flex__layout-align-lg-space-around-start,
  .Flex__layout-align-lg-space-around-end,
  .Flex__layout-align-lg-space-around-stretch {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .Flex__layout-align-lg-space-between,
  .Flex__layout-align-lg-space-between-center,
  .Flex__layout-align-lg-space-between-start,
  .Flex__layout-align-lg-space-between-end,
  .Flex__layout-align-lg-space-between-stretch {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .Flex__layout-align-lg-start-start,
  .Flex__layout-align-lg-center-start,
  .Flex__layout-align-lg-end-start,
  .Flex__layout-align-lg-space-between-start,
  .Flex__layout-align-lg-space-around-start {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .Flex__layout-align-lg-start-center,
  .Flex__layout-align-lg-center-center,
  .Flex__layout-align-lg-end-center,
  .Flex__layout-align-lg-space-between-center,
  .Flex__layout-align-lg-space-around-center {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    max-width: 100%;
  }
  .Flex__layout-align-lg-start-center > *,
  .Flex__layout-align-lg-center-center > *,
  .Flex__layout-align-lg-end-center > *,
  .Flex__layout-align-lg-space-between-center > *,
  .Flex__layout-align-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-align-lg-start-end,
  .Flex__layout-align-lg-center-end,
  .Flex__layout-align-lg-end-end,
  .Flex__layout-align-lg-space-between-end,
  .Flex__layout-align-lg-space-around-end {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .Flex__layout-align-lg-start-stretch,
  .Flex__layout-align-lg-center-stretch,
  .Flex__layout-align-lg-end-stretch,
  .Flex__layout-align-lg-space-between-stretch,
  .Flex__layout-align-lg-space-around-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .Flex__flex-lg {
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }
  .Flex__flex-lg-grow {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-initial {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-lg-auto {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-lg-none {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-lg-noshrink {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-lg-nogrow {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-lg-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-column > .Flex__flex-lg-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .Flex__flex-lg-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__flex-lg-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-33,
  .Flex__layout-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-lg-66,
  .Flex__layout-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-33,
  .Flex__layout-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-lg-66,
  .Flex__layout-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-33,
  .Flex__layout-lg-row > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex-lg-66,
  .Flex__layout-lg-row > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-row > .Flex__flex {
    min-width: 0;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-33,
  .Flex__layout-lg-column > .Flex__flex-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex-lg-66,
  .Flex__layout-lg-column > .Flex__flex-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-lg-column > .Flex__flex {
    min-height: 0;
  }
  .Flex__layout-lg,
  .Flex__layout-lg-column,
  .Flex__layout-lg-row {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
  }
  .Flex__layout-lg-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .Flex__layout-lg-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 1920px) {
  .Flex__flex-order-gt-lg--20 {
    -ms-flex-order: -20;
    order: -20;
  }
  .Flex__flex-order-gt-lg--19 {
    -ms-flex-order: -19;
    order: -19;
  }
  .Flex__flex-order-gt-lg--18 {
    -ms-flex-order: -18;
    order: -18;
  }
  .Flex__flex-order-gt-lg--17 {
    -ms-flex-order: -17;
    order: -17;
  }
  .Flex__flex-order-gt-lg--16 {
    -ms-flex-order: -16;
    order: -16;
  }
  .Flex__flex-order-gt-lg--15 {
    -ms-flex-order: -15;
    order: -15;
  }
  .Flex__flex-order-gt-lg--14 {
    -ms-flex-order: -14;
    order: -14;
  }
  .Flex__flex-order-gt-lg--13 {
    -ms-flex-order: -13;
    order: -13;
  }
  .Flex__flex-order-gt-lg--12 {
    -ms-flex-order: -12;
    order: -12;
  }
  .Flex__flex-order-gt-lg--11 {
    -ms-flex-order: -11;
    order: -11;
  }
  .Flex__flex-order-gt-lg--10 {
    -ms-flex-order: -10;
    order: -10;
  }
  .Flex__flex-order-gt-lg--9 {
    -ms-flex-order: -9;
    order: -9;
  }
  .Flex__flex-order-gt-lg--8 {
    -ms-flex-order: -8;
    order: -8;
  }
  .Flex__flex-order-gt-lg--7 {
    -ms-flex-order: -7;
    order: -7;
  }
  .Flex__flex-order-gt-lg--6 {
    -ms-flex-order: -6;
    order: -6;
  }
  .Flex__flex-order-gt-lg--5 {
    -ms-flex-order: -5;
    order: -5;
  }
  .Flex__flex-order-gt-lg--4 {
    -ms-flex-order: -4;
    order: -4;
  }
  .Flex__flex-order-gt-lg--3 {
    -ms-flex-order: -3;
    order: -3;
  }
  .Flex__flex-order-gt-lg--2 {
    -ms-flex-order: -2;
    order: -2;
  }
  .Flex__flex-order-gt-lg--1 {
    -ms-flex-order: -1;
    order: -1;
  }
  .Flex__flex-order-gt-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .Flex__flex-order-gt-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .Flex__flex-order-gt-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .Flex__flex-order-gt-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .Flex__flex-order-gt-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .Flex__flex-order-gt-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .Flex__flex-order-gt-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .Flex__flex-order-gt-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .Flex__flex-order-gt-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .Flex__flex-order-gt-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .Flex__flex-order-gt-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .Flex__flex-order-gt-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .Flex__flex-order-gt-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .Flex__flex-order-gt-lg-13 {
    -ms-flex-order: 13;
    order: 13;
  }
  .Flex__flex-order-gt-lg-14 {
    -ms-flex-order: 14;
    order: 14;
  }
  .Flex__flex-order-gt-lg-15 {
    -ms-flex-order: 15;
    order: 15;
  }
  .Flex__flex-order-gt-lg-16 {
    -ms-flex-order: 16;
    order: 16;
  }
  .Flex__flex-order-gt-lg-17 {
    -ms-flex-order: 17;
    order: 17;
  }
  .Flex__flex-order-gt-lg-18 {
    -ms-flex-order: 18;
    order: 18;
  }
  .Flex__flex-order-gt-lg-19 {
    -ms-flex-order: 19;
    order: 19;
  }
  .Flex__flex-order-gt-lg-20 {
    -ms-flex-order: 20;
    order: 20;
  }
  .Flex__offset-gt-lg-0,
  .Flex__flex-offset-gt-lg-0 {
    margin-left: 0;
  }
  [dir='rtl'] .Flex__offset-gt-lg-0,
  [dir='rtl'] .Flex__flex-offset-gt-lg-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .Flex__offset-gt-lg-5,
  .Flex__flex-offset-gt-lg-5 {
    margin-left: 5%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-5,
  [dir='rtl'] .Flex__flex-offset-gt-lg-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .Flex__offset-gt-lg-10,
  .Flex__flex-offset-gt-lg-10 {
    margin-left: 10%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-10,
  [dir='rtl'] .Flex__flex-offset-gt-lg-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .Flex__offset-gt-lg-15,
  .Flex__flex-offset-gt-lg-15 {
    margin-left: 15%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-15,
  [dir='rtl'] .Flex__flex-offset-gt-lg-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .Flex__offset-gt-lg-20,
  .Flex__flex-offset-gt-lg-20 {
    margin-left: 20%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-20,
  [dir='rtl'] .Flex__flex-offset-gt-lg-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .Flex__offset-gt-lg-25,
  .Flex__flex-offset-gt-lg-25 {
    margin-left: 25%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-25,
  [dir='rtl'] .Flex__flex-offset-gt-lg-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .Flex__offset-gt-lg-30,
  .Flex__flex-offset-gt-lg-30 {
    margin-left: 30%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-30,
  [dir='rtl'] .Flex__flex-offset-gt-lg-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .Flex__offset-gt-lg-35,
  .Flex__flex-offset-gt-lg-35 {
    margin-left: 35%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-35,
  [dir='rtl'] .Flex__flex-offset-gt-lg-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .Flex__offset-gt-lg-40,
  .Flex__flex-offset-gt-lg-40 {
    margin-left: 40%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-40,
  [dir='rtl'] .Flex__flex-offset-gt-lg-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .Flex__offset-gt-lg-45,
  .Flex__flex-offset-gt-lg-45 {
    margin-left: 45%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-45,
  [dir='rtl'] .Flex__flex-offset-gt-lg-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .Flex__offset-gt-lg-50,
  .Flex__flex-offset-gt-lg-50 {
    margin-left: 50%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-50,
  [dir='rtl'] .Flex__flex-offset-gt-lg-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .Flex__offset-gt-lg-55,
  .Flex__flex-offset-gt-lg-55 {
    margin-left: 55%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-55,
  [dir='rtl'] .Flex__flex-offset-gt-lg-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .Flex__offset-gt-lg-60,
  .Flex__flex-offset-gt-lg-60 {
    margin-left: 60%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-60,
  [dir='rtl'] .Flex__flex-offset-gt-lg-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .Flex__offset-gt-lg-65,
  .Flex__flex-offset-gt-lg-65 {
    margin-left: 65%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-65,
  [dir='rtl'] .Flex__flex-offset-gt-lg-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .Flex__offset-gt-lg-70,
  .Flex__flex-offset-gt-lg-70 {
    margin-left: 70%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-70,
  [dir='rtl'] .Flex__flex-offset-gt-lg-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .Flex__offset-gt-lg-75,
  .Flex__flex-offset-gt-lg-75 {
    margin-left: 75%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-75,
  [dir='rtl'] .Flex__flex-offset-gt-lg-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .Flex__offset-gt-lg-80,
  .Flex__flex-offset-gt-lg-80 {
    margin-left: 80%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-80,
  [dir='rtl'] .Flex__flex-offset-gt-lg-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .Flex__offset-gt-lg-85,
  .Flex__flex-offset-gt-lg-85 {
    margin-left: 85%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-85,
  [dir='rtl'] .Flex__flex-offset-gt-lg-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .Flex__offset-gt-lg-90,
  .Flex__flex-offset-gt-lg-90 {
    margin-left: 90%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-90,
  [dir='rtl'] .Flex__flex-offset-gt-lg-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .Flex__offset-gt-lg-95,
  .Flex__flex-offset-gt-lg-95 {
    margin-left: 95%;
  }
  [dir='rtl'] .Flex__offset-gt-lg-95,
  [dir='rtl'] .Flex__flex-offset-gt-lg-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .Flex__offset-gt-lg-33,
  .Flex__flex-offset-gt-lg-33 {
    margin-left: calc(100% / 3);
  }
  .Flex__offset-gt-lg-66,
  .Flex__flex-offset-gt-lg-66 {
    margin-left: calc(200% / 3);
  }
  [dir='rtl'] .Flex__offset-gt-lg-66,
  [dir='rtl'] .Flex__flex-offset-gt-lg-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }
  .Flex__layout-align-gt-lg,
  .Flex__layout-align-gt-lg-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .Flex__layout-align-gt-lg-start,
  .Flex__layout-align-gt-lg-start-start,
  .Flex__layout-align-gt-lg-start-center,
  .Flex__layout-align-gt-lg-start-end,
  .Flex__layout-align-gt-lg-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .Flex__layout-align-gt-lg-center,
  .Flex__layout-align-gt-lg-center-start,
  .Flex__layout-align-gt-lg-center-center,
  .Flex__layout-align-gt-lg-center-end,
  .Flex__layout-align-gt-lg-center-stretch {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .Flex__layout-align-gt-lg-end,
  .Flex__layout-align-gt-lg-end-start,
  .Flex__layout-align-gt-lg-end-center,
  .Flex__layout-align-gt-lg-end-end,
  .Flex__layout-align-gt-lg-end-stretch {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .Flex__layout-align-gt-lg-space-around,
  .Flex__layout-align-gt-lg-space-around-center,
  .Flex__layout-align-gt-lg-space-around-start,
  .Flex__layout-align-gt-lg-space-around-end,
  .Flex__layout-align-gt-lg-space-around-stretch {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .Flex__layout-align-gt-lg-space-between,
  .Flex__layout-align-gt-lg-space-between-center,
  .Flex__layout-align-gt-lg-space-between-start,
  .Flex__layout-align-gt-lg-space-between-end,
  .Flex__layout-align-gt-lg-space-between-stretch {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .Flex__layout-align-gt-lg-start-start,
  .Flex__layout-align-gt-lg-center-start,
  .Flex__layout-align-gt-lg-end-start,
  .Flex__layout-align-gt-lg-space-between-start,
  .Flex__layout-align-gt-lg-space-around-start {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .Flex__layout-align-gt-lg-start-center,
  .Flex__layout-align-gt-lg-center-center,
  .Flex__layout-align-gt-lg-end-center,
  .Flex__layout-align-gt-lg-space-between-center,
  .Flex__layout-align-gt-lg-space-around-center {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    max-width: 100%;
  }
  .Flex__layout-align-gt-lg-start-center > *,
  .Flex__layout-align-gt-lg-center-center > *,
  .Flex__layout-align-gt-lg-end-center > *,
  .Flex__layout-align-gt-lg-space-between-center > *,
  .Flex__layout-align-gt-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-align-gt-lg-start-end,
  .Flex__layout-align-gt-lg-center-end,
  .Flex__layout-align-gt-lg-end-end,
  .Flex__layout-align-gt-lg-space-between-end,
  .Flex__layout-align-gt-lg-space-around-end {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .Flex__layout-align-gt-lg-start-stretch,
  .Flex__layout-align-gt-lg-center-stretch,
  .Flex__layout-align-gt-lg-end-stretch,
  .Flex__layout-align-gt-lg-space-between-stretch,
  .Flex__layout-align-gt-lg-space-around-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .Flex__flex-gt-lg {
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-grow {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-initial {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-auto {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-none {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-noshrink {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-nogrow {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .Flex__flex-gt-lg-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__flex-gt-lg-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-33,
  .Flex__layout-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-gt-lg-66,
  .Flex__layout-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-33,
  .Flex__layout-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-gt-lg-66,
  .Flex__layout-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-33,
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-66,
  .Flex__layout-gt-lg-row > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-row > .Flex__flex {
    min-width: 0;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-33,
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-66,
  .Flex__layout-gt-lg-column > .Flex__flex-gt-lg-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-gt-lg-column > .Flex__flex {
    min-height: 0;
  }
  .Flex__layout-gt-lg,
  .Flex__layout-gt-lg-column,
  .Flex__layout-gt-lg-row {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
  }
  .Flex__layout-gt-lg-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .Flex__layout-gt-lg-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .Flex__flex-order-xl--20 {
    -ms-flex-order: -20;
    order: -20;
  }
  .Flex__flex-order-xl--19 {
    -ms-flex-order: -19;
    order: -19;
  }
  .Flex__flex-order-xl--18 {
    -ms-flex-order: -18;
    order: -18;
  }
  .Flex__flex-order-xl--17 {
    -ms-flex-order: -17;
    order: -17;
  }
  .Flex__flex-order-xl--16 {
    -ms-flex-order: -16;
    order: -16;
  }
  .Flex__flex-order-xl--15 {
    -ms-flex-order: -15;
    order: -15;
  }
  .Flex__flex-order-xl--14 {
    -ms-flex-order: -14;
    order: -14;
  }
  .Flex__flex-order-xl--13 {
    -ms-flex-order: -13;
    order: -13;
  }
  .Flex__flex-order-xl--12 {
    -ms-flex-order: -12;
    order: -12;
  }
  .Flex__flex-order-xl--11 {
    -ms-flex-order: -11;
    order: -11;
  }
  .Flex__flex-order-xl--10 {
    -ms-flex-order: -10;
    order: -10;
  }
  .Flex__flex-order-xl--9 {
    -ms-flex-order: -9;
    order: -9;
  }
  .Flex__flex-order-xl--8 {
    -ms-flex-order: -8;
    order: -8;
  }
  .Flex__flex-order-xl--7 {
    -ms-flex-order: -7;
    order: -7;
  }
  .Flex__flex-order-xl--6 {
    -ms-flex-order: -6;
    order: -6;
  }
  .Flex__flex-order-xl--5 {
    -ms-flex-order: -5;
    order: -5;
  }
  .Flex__flex-order-xl--4 {
    -ms-flex-order: -4;
    order: -4;
  }
  .Flex__flex-order-xl--3 {
    -ms-flex-order: -3;
    order: -3;
  }
  .Flex__flex-order-xl--2 {
    -ms-flex-order: -2;
    order: -2;
  }
  .Flex__flex-order-xl--1 {
    -ms-flex-order: -1;
    order: -1;
  }
  .Flex__flex-order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .Flex__flex-order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .Flex__flex-order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .Flex__flex-order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .Flex__flex-order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .Flex__flex-order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .Flex__flex-order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .Flex__flex-order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .Flex__flex-order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .Flex__flex-order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .Flex__flex-order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .Flex__flex-order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .Flex__flex-order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .Flex__flex-order-xl-13 {
    -ms-flex-order: 13;
    order: 13;
  }
  .Flex__flex-order-xl-14 {
    -ms-flex-order: 14;
    order: 14;
  }
  .Flex__flex-order-xl-15 {
    -ms-flex-order: 15;
    order: 15;
  }
  .Flex__flex-order-xl-16 {
    -ms-flex-order: 16;
    order: 16;
  }
  .Flex__flex-order-xl-17 {
    -ms-flex-order: 17;
    order: 17;
  }
  .Flex__flex-order-xl-18 {
    -ms-flex-order: 18;
    order: 18;
  }
  .Flex__flex-order-xl-19 {
    -ms-flex-order: 19;
    order: 19;
  }
  .Flex__flex-order-xl-20 {
    -ms-flex-order: 20;
    order: 20;
  }
  .Flex__offset-xl-0,
  .Flex__flex-offset-xl-0 {
    margin-left: 0;
  }
  [dir='rtl'] .Flex__offset-xl-0,
  [dir='rtl'] .Flex__flex-offset-xl-0 {
    margin-left: auto;
    margin-right: 0;
  }
  .Flex__offset-xl-5,
  .Flex__flex-offset-xl-5 {
    margin-left: 5%;
  }
  [dir='rtl'] .Flex__offset-xl-5,
  [dir='rtl'] .Flex__flex-offset-xl-5 {
    margin-left: auto;
    margin-right: 5%;
  }
  .Flex__offset-xl-10,
  .Flex__flex-offset-xl-10 {
    margin-left: 10%;
  }
  [dir='rtl'] .Flex__offset-xl-10,
  [dir='rtl'] .Flex__flex-offset-xl-10 {
    margin-left: auto;
    margin-right: 10%;
  }
  .Flex__offset-xl-15,
  .Flex__flex-offset-xl-15 {
    margin-left: 15%;
  }
  [dir='rtl'] .Flex__offset-xl-15,
  [dir='rtl'] .Flex__flex-offset-xl-15 {
    margin-left: auto;
    margin-right: 15%;
  }
  .Flex__offset-xl-20,
  .Flex__flex-offset-xl-20 {
    margin-left: 20%;
  }
  [dir='rtl'] .Flex__offset-xl-20,
  [dir='rtl'] .Flex__flex-offset-xl-20 {
    margin-left: auto;
    margin-right: 20%;
  }
  .Flex__offset-xl-25,
  .Flex__flex-offset-xl-25 {
    margin-left: 25%;
  }
  [dir='rtl'] .Flex__offset-xl-25,
  [dir='rtl'] .Flex__flex-offset-xl-25 {
    margin-left: auto;
    margin-right: 25%;
  }
  .Flex__offset-xl-30,
  .Flex__flex-offset-xl-30 {
    margin-left: 30%;
  }
  [dir='rtl'] .Flex__offset-xl-30,
  [dir='rtl'] .Flex__flex-offset-xl-30 {
    margin-left: auto;
    margin-right: 30%;
  }
  .Flex__offset-xl-35,
  .Flex__flex-offset-xl-35 {
    margin-left: 35%;
  }
  [dir='rtl'] .Flex__offset-xl-35,
  [dir='rtl'] .Flex__flex-offset-xl-35 {
    margin-left: auto;
    margin-right: 35%;
  }
  .Flex__offset-xl-40,
  .Flex__flex-offset-xl-40 {
    margin-left: 40%;
  }
  [dir='rtl'] .Flex__offset-xl-40,
  [dir='rtl'] .Flex__flex-offset-xl-40 {
    margin-left: auto;
    margin-right: 40%;
  }
  .Flex__offset-xl-45,
  .Flex__flex-offset-xl-45 {
    margin-left: 45%;
  }
  [dir='rtl'] .Flex__offset-xl-45,
  [dir='rtl'] .Flex__flex-offset-xl-45 {
    margin-left: auto;
    margin-right: 45%;
  }
  .Flex__offset-xl-50,
  .Flex__flex-offset-xl-50 {
    margin-left: 50%;
  }
  [dir='rtl'] .Flex__offset-xl-50,
  [dir='rtl'] .Flex__flex-offset-xl-50 {
    margin-left: auto;
    margin-right: 50%;
  }
  .Flex__offset-xl-55,
  .Flex__flex-offset-xl-55 {
    margin-left: 55%;
  }
  [dir='rtl'] .Flex__offset-xl-55,
  [dir='rtl'] .Flex__flex-offset-xl-55 {
    margin-left: auto;
    margin-right: 55%;
  }
  .Flex__offset-xl-60,
  .Flex__flex-offset-xl-60 {
    margin-left: 60%;
  }
  [dir='rtl'] .Flex__offset-xl-60,
  [dir='rtl'] .Flex__flex-offset-xl-60 {
    margin-left: auto;
    margin-right: 60%;
  }
  .Flex__offset-xl-65,
  .Flex__flex-offset-xl-65 {
    margin-left: 65%;
  }
  [dir='rtl'] .Flex__offset-xl-65,
  [dir='rtl'] .Flex__flex-offset-xl-65 {
    margin-left: auto;
    margin-right: 65%;
  }
  .Flex__offset-xl-70,
  .Flex__flex-offset-xl-70 {
    margin-left: 70%;
  }
  [dir='rtl'] .Flex__offset-xl-70,
  [dir='rtl'] .Flex__flex-offset-xl-70 {
    margin-left: auto;
    margin-right: 70%;
  }
  .Flex__offset-xl-75,
  .Flex__flex-offset-xl-75 {
    margin-left: 75%;
  }
  [dir='rtl'] .Flex__offset-xl-75,
  [dir='rtl'] .Flex__flex-offset-xl-75 {
    margin-left: auto;
    margin-right: 75%;
  }
  .Flex__offset-xl-80,
  .Flex__flex-offset-xl-80 {
    margin-left: 80%;
  }
  [dir='rtl'] .Flex__offset-xl-80,
  [dir='rtl'] .Flex__flex-offset-xl-80 {
    margin-left: auto;
    margin-right: 80%;
  }
  .Flex__offset-xl-85,
  .Flex__flex-offset-xl-85 {
    margin-left: 85%;
  }
  [dir='rtl'] .Flex__offset-xl-85,
  [dir='rtl'] .Flex__flex-offset-xl-85 {
    margin-left: auto;
    margin-right: 85%;
  }
  .Flex__offset-xl-90,
  .Flex__flex-offset-xl-90 {
    margin-left: 90%;
  }
  [dir='rtl'] .Flex__offset-xl-90,
  [dir='rtl'] .Flex__flex-offset-xl-90 {
    margin-left: auto;
    margin-right: 90%;
  }
  .Flex__offset-xl-95,
  .Flex__flex-offset-xl-95 {
    margin-left: 95%;
  }
  [dir='rtl'] .Flex__offset-xl-95,
  [dir='rtl'] .Flex__flex-offset-xl-95 {
    margin-left: auto;
    margin-right: 95%;
  }
  .Flex__offset-xl-33,
  .Flex__flex-offset-xl-33 {
    margin-left: calc(100% / 3);
  }
  .Flex__offset-xl-66,
  .Flex__flex-offset-xl-66 {
    margin-left: calc(200% / 3);
  }
  [dir='rtl'] .Flex__offset-xl-66,
  [dir='rtl'] .Flex__flex-offset-xl-66 {
    margin-left: auto;
    margin-right: calc(200% / 3);
  }
  .Flex__layout-align-xl,
  .Flex__layout-align-xl-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .Flex__layout-align-xl-start,
  .Flex__layout-align-xl-start-start,
  .Flex__layout-align-xl-start-center,
  .Flex__layout-align-xl-start-end,
  .Flex__layout-align-xl-start-stretch {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .Flex__layout-align-xl-center,
  .Flex__layout-align-xl-center-start,
  .Flex__layout-align-xl-center-center,
  .Flex__layout-align-xl-center-end,
  .Flex__layout-align-xl-center-stretch {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .Flex__layout-align-xl-end,
  .Flex__layout-align-xl-end-start,
  .Flex__layout-align-xl-end-center,
  .Flex__layout-align-xl-end-end,
  .Flex__layout-align-xl-end-stretch {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .Flex__layout-align-xl-space-around,
  .Flex__layout-align-xl-space-around-center,
  .Flex__layout-align-xl-space-around-start,
  .Flex__layout-align-xl-space-around-end,
  .Flex__layout-align-xl-space-around-stretch {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .Flex__layout-align-xl-space-between,
  .Flex__layout-align-xl-space-between-center,
  .Flex__layout-align-xl-space-between-start,
  .Flex__layout-align-xl-space-between-end,
  .Flex__layout-align-xl-space-between-stretch {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .Flex__layout-align-xl-start-start,
  .Flex__layout-align-xl-center-start,
  .Flex__layout-align-xl-end-start,
  .Flex__layout-align-xl-space-between-start,
  .Flex__layout-align-xl-space-around-start {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .Flex__layout-align-xl-start-center,
  .Flex__layout-align-xl-center-center,
  .Flex__layout-align-xl-end-center,
  .Flex__layout-align-xl-space-between-center,
  .Flex__layout-align-xl-space-around-center {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    max-width: 100%;
  }
  .Flex__layout-align-xl-start-center > *,
  .Flex__layout-align-xl-center-center > *,
  .Flex__layout-align-xl-end-center > *,
  .Flex__layout-align-xl-space-between-center > *,
  .Flex__layout-align-xl-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-align-xl-start-end,
  .Flex__layout-align-xl-center-end,
  .Flex__layout-align-xl-end-end,
  .Flex__layout-align-xl-space-between-end,
  .Flex__layout-align-xl-space-around-end {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .Flex__layout-align-xl-start-stretch,
  .Flex__layout-align-xl-center-stretch,
  .Flex__layout-align-xl-end-stretch,
  .Flex__layout-align-xl-space-between-stretch,
  .Flex__layout-align-xl-space-around-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .Flex__flex-xl {
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
  }
  .Flex__flex-xl-grow {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-initial {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-xl-auto {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-xl-none {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-xl-noshrink {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }
  .Flex__flex-xl-nogrow {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .Flex__flex-xl-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-column > .Flex__flex-xl-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-0 {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0;
  }
  .Flex__flex-xl-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-5 {
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-10 {
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-15 {
    -ms-flex: 1 1 15%;
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-20 {
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-25 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-30 {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-35 {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-40 {
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-45 {
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-50 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-55 {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-60 {
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-65 {
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-70 {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-75 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-80 {
    -ms-flex: 1 1 80%;
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-85 {
    -ms-flex: 1 1 85%;
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-90 {
    -ms-flex: 1 1 90%;
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-95 {
    -ms-flex: 1 1 95%;
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box;
  }
  .Flex__flex-xl-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-33,
  .Flex__layout-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-row > .Flex__flex-xl-66,
  .Flex__layout-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-33,
  .Flex__layout-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-column > .Flex__flex-xl-66,
  .Flex__layout-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-100 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-33,
  .Flex__layout-xl-row > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex-xl-66,
  .Flex__layout-xl-row > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-row > .Flex__flex {
    min-width: 0;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-33,
  .Flex__layout-xl-column > .Flex__flex-xl-33 {
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex-xl-66,
  .Flex__layout-xl-column > .Flex__flex-xl-66 {
    -ms-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box;
  }
  .Flex__layout-xl-column > .Flex__flex {
    min-height: 0;
  }
  .Flex__layout-xl,
  .Flex__layout-xl-column,
  .Flex__layout-xl-row {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
  }
  .Flex__layout-xl-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .Flex__layout-xl-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .Flex__hide:not(.Flex__show-gt-xs):not(.Flex__show-gt-sm):not(.Flex__show-gt-md):not(.Flex__show-gt-lg):not(.Flex__show-xl):not(.Flex__show),
  .Flex__hide-gt-xs:not(.Flex__show-gt-xs):not(.Flex__show-gt-sm):not(.Flex__show-gt-md):not(.Flex__show-gt-lg):not(.Flex__show-xl):not(.Flex__show),
  .Flex__hide-gt-sm:not(.Flex__show-gt-xs):not(.Flex__show-gt-sm):not(.Flex__show-gt-md):not(.Flex__show-gt-lg):not(.Flex__show-xl):not(.Flex__show),
  .Flex__hide-gt-md:not(.Flex__show-gt-xs):not(.Flex__show-gt-sm):not(.Flex__show-gt-md):not(.Flex__show-gt-lg):not(.Flex__show-xl):not(.Flex__show),
  .Flex__hide-gt-lg:not(.Flex__show-gt-xs):not(.Flex__show-gt-sm):not(.Flex__show-gt-md):not(.Flex__show-gt-lg):not(.Flex__show-xl):not(.Flex__show) {
    display: none;
  }
  .Flex__hide-xl:not(.Flex__show-xl):not(.Flex__show-gt-lg):not(.Flex__show-gt-md):not(.Flex__show-gt-sm):not(.Flex__show-gt-xs):not(.Flex__show) {
    display: none;
  }
}

@media print {
  .Flex__hide-print:not(.Flex__show-print):not(.Flex__show) {
    display: none !important;
  }
}

.Flex__divider {
  width: 0.7375rem;
  height: 0.7375rem;
}
.Grid__root {
  padding-right: 0.7375rem;
  padding-left: 0.7375rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  width: 100%;
}
