%mobileFilterBtn {
  position: fixed;
  bottom: 0px;
  border: 1px solid $lightGrayFour;
  background-color: #fff;
  z-index: 100; // for tablets, users can drag the contents of the graph and we dont want it to be above the buttons
}

%edit-card-link {
  text-decoration: none;
  color: #2278cf;
}

.isPhone.isPortrait {
  .rt-thead .rt-tr {
    padding-right: 0 !important;
  }
  .mobileThumbnail {
    .rt-tbody {
      overflow-y: hidden;
    }
  }
  .rt-tbody {
    overflow-y: scroll;
  }
}

.printable-table-container {
  display: none;
}

.mobileFilterSlide,
.mobileMoreSlide,
.mobileQuickLinkSlide {
  .mobileMoreCloseBtn {
    padding: 33px 0 !important;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    left: 0;
    @extend %mobileFilterBtn;
  }
  .mobileCardViewDetailExpand,
  .mobileDashboardViewMoreExpand {
    padding: 5px 8px 5px 0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    border-bottom: 1px solid #d3d3d3 !important;

    .align-icon {
      color: #777;
    }
  }
  .mobileSaveDefaultContainer {
    justify-content: flex-start !important; // override styles on an ID
  }
  .mobileSaveAsDefaultCheckbox svg {
    font-size: 36px;
  }
}

.mobileQuickLinkSaveBtn,
.mobileFilterBtnLeft {
  @extend %mobileFilterBtn;
  left: 0px;
  width: 50%;
}

.mobileQuickLinkCloseBtn,
.mobileFilterBtnRight {
  @extend %mobileFilterBtn;
  right: 0px;
  width: 50%;
  align-items: center;
}

.mobileFilterSlide {
  .mobileNoFilterMessage {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#mobile-filter-close-btn,
#mobile-filter-apply-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 68px;
  padding: 33px 0 !important;
}

#mobile-btn-container {
  height: 68px;
}

.mobileMoreSlide {
  .itemTwo {
    background-color: inherit;
  }

  .mobileMoreListItem {
    justify-content: space-between;
    padding: 16px 0 16px 16px;
    p {
      margin-left: 8px;
    }
    border-bottom: 1px solid #d3d3d3;

    .mobile-favorite-icon-button {
      min-width: 56px;

      > button {
        padding-left: 2px;
      }
    }
  }
  .expansionContent {
    justify-content: space-between;
  }

  .edit-card-link {
    @extend %edit-card-link;
  }
}

.isMobile {
  .edit-card-link {
    @extend %edit-card-link;
  }

  .expandLabel {
    font-weight: bold;
  }

  .section {
    padding: 10px;
  }

  .mobileViewDetailSection {
    margin-bottom: 15px;
    margin-left: 10px;
  }

  .mobileViewDetailDashName {
    margin-top: 25px;
  }

  .react-grid-layout {
    margin-bottom: 100px;
  }
}

#gf-root .isMobile {
  .notThumbnailContainer {
    top: 25px;
  }

  :-webkit-full-screen {
    width: 100%;
    background-color: #fff;
  }

  /* Firefox syntax */
  :-moz-full-screen {
    width: 100%;
    background-color: #fff;
  }

  /* IE/Edge syntax */
  :-ms-fullscreen {
    width: 100%;
    background-color: #fff;
  }

  /* Standard syntax */
  :fullscreen {
    width: 100%;
    background-color: #fff;
  }

  .chart-container {
    margin: initial !important;
  }
  #cards,
  #dashboards {
    margin-top: 20px;

    .total-records {
      margin-left: 20px;
    }
    .search-dashboard,
    .search-card {
      padding: 0 0 0 20px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      > label {
        left: inherit;
      }
      > div {
        width: 100%;
      }
    }
  }

  &.isPhone {
    .isMobileCard {
      overflow-y: hidden;
    }
  }

  .mobileCardHeightHook {
    overflow: scroll;

    &.barVizType,
    &.lineVizType,
    &.pivotVizType,
    &.tableVizType,
    &.tree_tableVizType,
    &.big_numberVizType {
      overflow: hidden;
    }
  }

  .mobileHeader {
    display: inline-block;
    margin: 10px 10px;
  }

  .mobileTimePeriodContainerHook {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 5px;
  }

  .viewDashboard {
    overflow-y: scroll;
    overflow-x: hidden;

    .mobileFilterBtnLeft,
    .mobileFilterBtnRight {
      display: flex;
      justify-content: center;
      height: auto;
      padding: 33px 0 !important;
      line-height: 1.6;

      > svg {
        margin-right: 5px;
      }
    }

    .mobileFilterBtnRight {
      @extend %mobileFilterBtn;
      right: 0px;
      width: 50%;
      z-index: 99;
    }
    .mobileFilterBtnLeft {
      @extend %mobileFilterBtn;
      left: 0px;
      width: 50%;
      z-index: 99;
    }
  }

  .noMessageMobileHook {
    padding: 12px;
  }

  .homePagePreviewWrapper {
    overflow: hidden;
  }

  .mobileFullScreenBtnContainer,
  .cardViewerMobileFullScreenContainer {
    display: flex;
    justify-content: flex-end;
  }

  .gf-card-page,
  .homePage {
    .cardHeader {
      padding-left: 10px;
    }
    .mobileLineChartContainerHook {
      width: 94% !important;
    }
    .cardPreviewMobileHook {
      padding-left: 0px;
      padding-right: 0px;
    }
    .homePagePreviewWrapper {
      padding: 0px;
    }
    .mobileFilterBtnLeft,
    .mobileFilterBtnRight {
      display: flex;
      justify-content: center;
      padding: 33px 0 !important;
      line-height: 1.6;
      > svg {
        margin-right: 5px;
      }
    }
    .fullScreenTriggerBtn {
      padding-bottom: 0;
    }
    .table-container {
      margin: 0px 2px 0px 2px !important;
    }
    .ReactTable.react-table {
      border: 1px solid $lightGrayThree !important;
    }
    .rt-thead {
      .rt-th {
        border-right: 1px solid $lightGrayThree;
      }
    }

    .rt-tfoot {
      .rt-td {
        border-right: none;
      }
    }
    .rt-td {
      border-right: 1px solid $lightGrayThree;
    }
    .rt-tbody {
      .rt-tr {
        border-top: 1px solid $lightGrayThree;
      }
    }
  }
}

.mobileTimePeriodContainerHook {
  padding-left: 0px;
}

.mobileTimePeriodTriggerBtn {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

@media (orientation: landscape) {
  #gf-root {
    .isMobile.isLandscape {
      .mobileCardHeightHook {
        overflow-x: hidden;
      }
    }

    .isPhone.isLandscape {
      .mobileQuickLinkCloseBtn,
      .mobileFilterBtnRight,
      .mobileFilterBtnLeft {
        display: none !important;
      }
    }

    .isMobileCard {
      overflow-y: hidden;
    }
  }
}
