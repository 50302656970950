.select-option {
  .margin-bottom-10 {
    margin-bottom: 10px;
  }
}
.focus-outlined {
  &:focus {
    outline: #222 dashed 1px;
  }
}
.click-cursor {
  &:hover {
    cursor: pointer;
  }
}
