@import '../../../styles/helpers/variables';

.greefieldChipRoot {
  color: $darkGray;
  display: inline-flex;
  font-size: 14px;
  font-family: Roboto;
  border-radius: 1px;
  width: 100%;
  height: 32px;

  .greenfieldChipLabel {
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    line-height: 2.5;
  }
  .metric-chip-label {
    width: 65%;
    max-width: 65%;
  }

  .dimension-chip-label {
    width: 50%;
    max-width: 50%;
  }

  .rightSide {
    margin: -2px;
  }

  .disable-sort-opt-btn {
    pointer-events: none;
    opacity: 0.5;
  }

  .icon-avatar {
    width: 32px;
    height: 32px;
  }

  .label-margin {
    margin: 0;
  }

  .greenfieldChipBtn {
    padding: 0;
    width: 35px;
    height: 35px;

    .delete-icon {
      font-size: 16px;
    }
  }
}

.card-dashoard-dataset-chip {
  background-color: #ffeed0 !important;
  border-color: #f5cb81 !important;
}

.chip-business {
  background: $darkBlue !important;
  color: $white !important;
  margin-top: 0.2rem;
}
.chip-owners {
  background: $darkPink !important;
  color: $white !important;
  margin-top: 0.2rem;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.chip-site-home {
  background: $darkGreen !important;
  color: $white;
  margin-top: 0.2rem;
}

.downshift-dropdown-suggestion {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  width: fit-content;
}

.owner-list-item {
  display: inline-flex;
  align-items: center;
}

.owner-avatar {
  width: 25px !important;
  height: 25px !important;
}

.position-relative {
  position: relative;
}
