@media print {
  #gf-root {
    margin: 0 !important;
    height: stretch !important;

    .layoutContainer {
      margin: 0 !important;
      .chart-container {
        div {
          position: initial !important;
          max-width: 100% !important;
        }
        canvas {
          max-width: 100% !important;
          max-height: 100% !important;
          object-fit: contain !important;
          overflow: hidden !important;
        }
      }
    }
  }
  .greenFieldNoPrint,
  .greenFieldDashboardNoPrint {
    display: none !important;
  }
  .thumbnail-table-container.printable-table-container,
  #gf-root .page-cards .cardviewer-table-container {
    margin: 0;

    .table-container.printable-table-container {
      display: block !important;
      height: 100%;
      position: static !important;
    }
  }

  .thumbnail-table-container .react-table-container {
    overflow: hidden;
  }
  html {
    width: 11in !important;
    zoom: 66.62%;
  }

  .viewDashboardTabs > .swipeableViewsContent {
    height: auto;
  }

  .layoutContainer {
    height: auto;

    > div {
      margin: 0;

      > div {
        padding: 8px;
      }
    }

    .printable-dashboard {
      display: block;
    }
  }

  .gf-card-page {
    .click-more {
      display: none;
    }

    .icon-container {
      visibility: hidden;

      #selected-time-period {
        visibility: visible;
      }
    }

    .table-container {
      height: auto !important;
    }
  }
  .print-dashboard-button {
    display: none !important;
  }
}

// because commas dont work with media queries, why would they?

.printable-dashboard {
  width: 11in !important;
  margin: 0 !important;

  &.landscape {
    width: 16in !important;
  }

  .greenFieldNoPrint {
    display: none !important;
  }

  .thumbnail-table-container.printable-table-container,
  #gf-root .page-cards .table-container.printable-table-container {
    display: block !important;
    height: 100%;
  }
  .thumbnail-table-container .react-table-container {
    overflow: hidden;
  }

  .viewDashboardTabs > .swipeableViewsContent {
    height: auto;
  }

  .layoutContainer {
    margin-top: 0;
    height: auto;

    > div {
      margin: 0;

      > div {
        padding: 8px;
      }
    }

    .printable-dashboard {
      display: block;
    }
  }

  .gf-card-page {
    .click-more {
      display: none;
    }

    .icon-container {
      visibility: hidden;

      #selected-time-period {
        visibility: visible;
      }
    }

    .table-container {
      height: auto !important;
    }
  }
}
