@import '../../../styles/helpers/variables';

.root-calendar {
  display: flex;
  flex-direction: column;
  .mobileHide {
    display: none;
  }

  .calendar-active {
    color: #86afe5;
  }
}

.textField {
  flex-basis: 70px;
}

.flex-width {
  display: flex;
  justify-content: space-around;
}

.dayPickerWrapper {
  z-index: 2;
  &::before {
    content: '';
    position: absolute;
    top: -0.4688rem;

    height: 0.8438rem;
    border: 1px solid transparent;
    border-right-color: #e4e4e4;
    border-top-color: #e4e4e4;
    background-color: $white;
    z-index: 1;
    transform: rotate(-45deg);
  }
}

.timeWrapper {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  .flex-column {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }
}

.textField-css {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  width: 50px;
}

.button-top {
  top: 10px;
}
