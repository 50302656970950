@import '../../../styles/helpers/variables.scss';
@import '../../../styles/common.scss';

#gf-root {
  // Card Listing

  .layoutContainer {
    display: flex;
    flex-direction: column;

    .layout-flex-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  #cards,
  #dashboards,
  #datasets,
  #sites,
  #asset {
    padding: 20px;
    margin-bottom: 40px;
    position: relative;
    top: 0;
    left: 0;
    flex-grow: 1;

    .site-card-container {
      height: 435px;
      max-height: 435px;
      position: initial !important;
    }

    #table-container {
      position: absolute;
      top: 85px;
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      width: 100%;

      > .table-list-card {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        > .table-list-cardContent {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          overflow: hidden;

          > #gfTableId {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow: hidden;

            > .ReactTable {
              height: auto !important;
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              overflow: hidden;

              > .rt-table {
                overflow: hidden;

                > .rt-thead {
                  flex: none;
                  min-width: inherit !important;
                }

                > .rt-tbody {
                  min-width: inherit !important;
                  display: flex;
                  flex-direction: column;
                  flex-grow: 1;
                  overflow: auto !important;
                }
              }
            }
          }
        }
      }
    }

    .table-list-card {
      padding-bottom: 0;

      > div {
        padding: 0;
      }
    }

    .card-list-search {
      display: flex;
      align-items: center;
    }

    .search-card {
      width: 400px;
      background: $white;
    }

    .edit-card-link {
      text-decoration: none;
      color: $textBlue;
    }

    .disabled-notification {
      pointer-events: none;
    }

    .edit-card-link:focus {
      outline: #222222 dashed 1px;
      outline-offset: 1px;
    }

    .card-list-item {
      word-break: break-word;
      white-space: pre-wrap;
    }

    .cardExpandCard {
      margin: 10px;
      width: 120px;
      height: 127px;
      text-align: center;
    }

    .cardRowExpand {
      padding: 5px;
      background-color: rgba(204, 204, 204, 0.156862745098039);
    }

    .cardExpandCardContents {
      padding: 0;
      background: inherit;
      background-color: rgba(255, 255, 255, 1);
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(204, 204, 204, 1);
      border-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-family: 'Roboto-Regular', 'Roboto';
      color: rgba(33, 33, 33, 0.866666666666667);
    }

    .expandDescription {
      margin-left: 60px;
      margin-right: 20px;
      overflow-wrap: break-word;
    }

    .expandCardData {
      font-size: 28px;
      font-weight: 400;
    }

    .expandCardLabel {
      font-size: 15px;
      font-weight: 400;
      height: 86px;
      padding-top: 20px;
      background-color: $secondaryGreen;
      color: $black;
    }

    .expandLabel {
      padding-bottom: 3px;
      padding-top: 20px;
      font-weight: 400;
      color: $mauve;

      > span {
        color: $black;
      }
    }

    .expandValue {
      color: $darkGray;
    }

    .expandCardBackgroundColor {
      background-color: $lightGrayFive;
    }

    .cards-delete-button {
      margin: 20px;
    }

    .expandDivBtn {
      padding-top: 20px;
    }

    .chip-business {
      background: $darkBlue;
      color: $white;
      margin-top: 0.2rem;
    }

    .chip-owners {
      background: $darkPink;
      color: $white;
      margin-top: 0.2rem;
    }

    .view-details-button {
      text-decoration: none;
      word-break: keep-all;
      white-space: pre-wrap;
      font-size: 1rem;
    }

    .my-content {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
    }

    .select-rows {
      font-size: 1rem;
      background: $white;
    }
  }
}
