@import '../../styles/helpers/variables';
@import '../../styles/common.scss';

.sideNav {
  width: 300px;
  background-color: #333 !important;

  .drawer-container {
    overflow-y: hidden;
    display: flex;
    flex-flow: column;
  }

  .listHeader {
    background-color: #117b73;
    padding: 10px;
    text-align: center;
    display: inline;

    a {
      &:focus {
        outline: #222 dashed 1px;
        outline-offset: 3px;
        text-decoration: none;
      }
    }
    img {
      max-height: 60px;
      margin: 0 auto;
      transform: rotateY(180deg);
      opacity: 0;
      transition: all 2s;

      &.mounted {
        transform: rotateY(0deg);
        opacity: 1;
      }
    }

    & + div button {
      color: $white;
    }

    & + div > div > div:first-child {
      display: flex;
      align-items: center;
    }
  }

  .sideNavListItemText {
    margin-left: 15px;

    > span {
      color: $white;
      font-size: 14px;
    }
  }
  .sideNavHead {
    > span {
      font-size: 16px;
      padding: 6px;
    }
  }
  .sideNavListItem {
    color: $white;
    min-width: 207px;
    padding: 2px 16px 2px 16px;

    h3 {
      color: $white;
    }

    > h3 {
      font-size: 13px;
    }
  }
  .sideNavList {
    padding-top: 0;
    overflow-y: auto;
    a {
      text-decoration: none;
      color: $white;

      &:focus {
        outline: $lightGrayOne dashed 1px;
        outline-offset: 2px;
        text-decoration: underline;
      }
    }
  }

  .align-about {
    top: 60%;
    border-top: grey solid 1px;
  }

  .sideNavMblBtnCenter {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    left: 0;
    @extend %mobileFilterBtn;
    padding: 0px !important;
    border: 0;
    button {
      margin: 0;
      border-radius: inherit !important;
      color: #fff;
      background-color: #666;
      padding: 39px 0;
    }
  }
}

.sideNav-mobile {
  width: 100%;
  overflow-y: hidden !important;

  #quicklinks-next-btn,
  #quicklinks-previous-btn {
    padding: 0;
    color: #fff;
  }
}

.greenfieldLogoutEasterEggMessage {
  padding: 30px;
}

.aboutGreenfieldDialog {
  .dialogTitle {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: flex-end;
  }
  .dialogContent {
    img {
      height: 150px;
      width: 150px;
    }
    .dialogIcon {
      height: 150px;
      width: 150px;
    }
    .dialogContentText {
      padding-left: 15px;
    }
    .gridContainer {
      direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
