@import '../../../styles/helpers/variables.scss';

.cardPreview {
  height: 100%;
  color: $black;
  text-decoration: none;
  overflow: hidden;
  position: relative;

  .notificationCardPreviewIcon {
    margin-left: 10px;
  }
  .hiddenTitleNotificationIcon {
    justify-content: center;
    width: 100%;
  }

  .cardPreviewPrintBreak {
    padding: 8px 8px 0px 8px;
    height: inherit;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .cardPreviewErrorCont {
    padding: 8px 8px 0px 8px;
    height: inherit;
    overflow: auto;
  }

  .cardPreviewPrintBreakSparkline {
    display: flex;
    flex-direction: column;
    height: inherit;
    padding: 8px 8px 0px 8px;
    overflow: auto;
  }

  .page-cards {
    height: 210px;
  }

  .chart-height {
    height: 100%;
  }

  .scroll-auto {
    overflow: auto;
  }

  .no-data-message {
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
  }

  .cardHeader {
    height: 35px;
    font-size: 12px;
    margin-top: 2px;

    &.is-hidden {
      visibility: hidden;
    }
  }
  .cardHeaderTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
  }
  .cardHeaderText {
    display: inline-flex;
    align-items: flex-end;
    position: relative;
    font-size: 16px;
  }
  .cardCount {
    text-align: center;
    margin: 10px auto;
  }
  .cardPreviewChip {
    margin: auto 5px;
    height: 25px;
  }
  .cardHeaderStarIcon {
    color: $favorite;
    position: relative;
    top: 4px;
  }
  .cardFailMessage {
    text-align: center;
  }
  .error-description {
    margin-bottom: 10px;
    font-weight: 400;
    line-height: 1.5;
    font-size: 14px;
  }
  .notFoundIcon {
    color: $deepRed;
    font-size: 50px;
  }
  .page-cards .table-container {
    height: 100%;
    max-height: 100%;
    margin: 0 !important;
  }
  .react-table-container {
    max-height: 200px;
  }
  .table-header-fixed {
    display: inline;
  }
  .padding-top {
    padding-top: 6px;
  }
  .align-icon {
    position: absolute;
    top: 0;
    right: 0;
  }

  .expand-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    &:focus {
      outline: #222 dashed 1px;
      outline-offset: 1px;
      text-decoration: none;
    }
  }

  .edit-icon {
    position: absolute;
    right: 30px;
    z-index: 1;
  }

  .open-icon {
    color: gray;
    width: 20px;
    height: 20px;
  }

  .footer-section {
    bottom: 0;
    position: absolute;
    width: 100%;
  }

  .thumbnail-footer {
    border-top: 1px solid $lightGrayFour;
    padding: 5px 8px 0;

    .time-period-label {
      display: flex;
      svg {
        color: $darkGreen;
        font-size: 14px;
        margin: 0 5px 5px 0;
      }
      p {
        font-size: 12px;
        line-height: 1.4;
      }
    }

    .filter-icon {
      svg {
        font-size: 14px;
        margin: 0 5px 5px 0;
      }
    }
  }
}

.homePageLeftSide {
  .homePagePreviewWrapper {
    min-height: 323px;
    .page-cards {
      .table-container {
        height: 100%;
      }
    }
  }
}
